<!--
 * @Descripttion: *
 * @Author: wjj
 * @LastEditors: *
 * @LastEditTime: 2024-10-14 19:31:57
-->
<template>
  <div class="index">
    <h4 class="content-title">
          <span>个人信息</span>
        </h4>
        <a-form :form="form"  :label-col="{ span: 3 }"  :wrapper-col="{ span: 9 }">
          <a-form-item
           
            label="用户名称"
          >
            <a-input
              disabled
              v-decorator="[
                'username',
                { 
                  rules: [{ required: true }],
                  initialValue: userInfo.usernameStr,
                 },
              ]"
            />
          </a-form-item>
          <a-form-item
            label="手机号码"
          >
            <a-input
              disabled
              v-decorator="[
                'tel',
                { 
                  rules: [{ required: true }],
                  initialValue: userInfo.username,
                 },
              ]"
            />
          </a-form-item>
          <a-form-item label="角色">
            <a-input
              disabled
              v-decorator="[
                'role_name',
                { 
                  rules: [{ required: true }],
                  initialValue: userInfo.role_name,
                 },
              ]"
            />
          </a-form-item>
          <a-form-item
            label="关联店铺"
          >
            <a-input
              disabled
              v-decorator="[
                'shop_name',
                { 
                  rules: [{ required: true  }],
                  initialValue: userInfo.shop_name
                 },
              ]"
            />
          </a-form-item>
          <a-form-item :wrapper-col="{ span: 9, offset: 3 }" style="margin-top: 30px;">
            <a-button type="primary"  @click="showModal">
              重置密码
            </a-button>
          </a-form-item>
        </a-form> 

        <a-modal 
          v-model="visible" 
          title="请设置新密码" 
          @ok="setPassword"
          okText="确定"
          cancelText="取消"
        >
           <a-form :form="editForm"  :label-col="{ span: 5 }"  :wrapper-col="{ span: 18 }">
              <a-form-item label="用户名称" >
                <a-input
                  disabled
                  v-decorator="[
                    'username',
                    { 
                      rules: [{ required: true }],
                      initialValue: userInfo.usernameStr,
                    },
                  ]"
                />
              </a-form-item>
              <a-form-item label="密码"  >
                <a-input-password 
                  type="password"
                  placeholder="输入6-14位字母、数字组合的密码"
                  :min-length=1
                  :max-length=14
                  v-decorator="[
                    'password',
                    { 
                      rules: [
                        {validator: validatePassword },
                        {required: true ,message: '请输入6-14位字母、数字组合的密码'}
                      ],
                      initialValue: '',

                    },
                  ]"
                />
              </a-form-item>
          </a-form> 
        </a-modal>
  </div>
</template>


<script>
import { userApi } from '@/api/user'
import { message } from 'ant-design-vue';

export default {
  name: "Userinfo",
  data() {
    return {
      form: this.$form.createForm(this, { name: 'dynamic_rule' }),
      editForm: this.$form.createForm(this, { name: 'editForm' }),
      userInfo: {},
      password: '',
      visible: false,
    };
  },
  components: {

  },
  mounted() {
    this.userInfo = {
      usernameStr: this.$cookie.get('usernameStr'),
      username: this.$cookie.get('username'),// 手机号
      role_name: this.$cookie.get('role_name'),
      shop_name: this.$cookie.get('shop_name'),
      creator_id: this.$cookie.get('creator_id'),
    }
  
  },

  methods: {
    //密码校验规则
     validatePassword(rule, value, callback) {
      // console.log('validatePassword', value)
      const regex = /^.*(?=.*[0-9])(?=.*[a-zA-Z]).{6,14}$/;
      console.log(regex.test(value))
        if(value!=='' && !regex.test(value)) {
            callback('请输入6-14位字母、数字组合的密码')
        } else {
          callback()
        }
    },

    
 
    showModal() {
      this.visible = true;
    },
    // 重置密码
    setPassword(e) {
        e.preventDefault();
          const { creator_id} = this.userInfo ;
          this.editForm.validateFields((err, values) => {
            const params = {
              creator_id,
              password: this.editForm.getFieldValue('password')
            }
            // console.log('params', params)
            if (!err) {
              userApi.changepwd(params)
                .then(res => {
                  if(res.status == 200) {
                    message.success('密码修改成功！')
                    this.visible = false;
                  }
                })
              
            }
          });
      
    },

    

  },
};
</script>

<style lang="less" scoped>
@import "./../../../assets/less/reset.less";
@import "./../../../assets/less/config.less";

#components-layout-demo-side .logo {
  height: 32px;
  background: rgba(255, 255, 255, 0.2);
  margin: 16px;
}

.index {
  .content-title {
    display: flex;
    align-items: center;
    // line-height:  60px;
    font-weight: 400;
    padding: 0 20px;
    line-height: 60px;
    border-bottom: 1px solid #e8e8e8;
  }
  .content-title::before {
      display: inline-block;
      content: '';
      width: 4px;
      height: 20px;
      margin-right: 8px;
      margin-bottom: -2px;
    background: @themeColor;
  }
  :global(.a-layout-submenu) {
    display: block !important;
  }
  :global(.ant-form) {
    padding: 30px 20px !important;
  }
  .ant-form .ant-form-item .ant-form-item-label {
    text-align: left !important;
  }
  .ant-form .ant-input {
    // background: rgb(236, 236, 236);
    // border: 0;
    height: 36px;
    padding: 0 8px;
  }
  

}
</style>
