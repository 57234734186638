import request from "@/utils/request.js";
import Vue from 'vue'

function channelproductlist(data){
    return request({
        url:  "/channel/channelproductlist",
        method: 'post',
        data
      })
  }
  function sendproductchannel(data){
    return request({
        url:  "/channel/sendproductchannel",
        method: 'post',
        data
      })
  }
  function listrefund(data){
    return request({
        url:  "/refund/listrefund",
        method: 'post',
        data
      })
  }
  function addrefund(data){
    return request({
        url:  "/refund/addrefund",
        method: 'post',
        data
      })
  }
  function listlive(data){
    return request({
        url:  "/live/listlive",
        method: 'post',
        data
      })
  }
  function editlive(data){
    return request({
        url:  "/live/editlive",
        method: 'post',
        data
      })
  }
  function addlive(data){
    return request({
        url:  "/live/addlive",
        method: 'post',
        data
      })
  }
  function listrefundselect(){
    return request({
        url:  "/refund/listrefundselect",
        method: 'get'
      })
  }

  // 单条采购单数据提交
  function editpurchase(data){
    return request({
        url:  "/purchase/editpurchase",
        method: 'post',
        data
      })
  }

  // 删除单条采购单数据
  function deletepurchase(data){
    return request({
        url:  "/purchase/deletepurchase",
        method: 'post',
        data
      })
  }  

  // 采购单详情商品添加
  function addpurchasedetail(data){
    return request({
        url:  "/purchasedetail/addpurchasedetail",
        method: 'post',
        data
      })
  }  

  

  function listpurchasedetail(data){
    return request({
        url:  "/purchasedetail/listpurchasedetailproduct",
        method: 'post',
        data
      })
  }
  function deletepurchasedetail(data){
    return request({
        url:  "/purchasedetail/deletepurchasedetail",
        method: 'post',
        data
      })
  }

  function editpurchasedetail(data){
    return request({
        url:  "/purchasedetail/editpurchasedetail",
        method: 'post',
        data
      })
  }

  // 退货单编辑接口
  function editrefund(data){
    return request({
        url:  "/refund/editrefund",
        method: 'post',
        data
      })
  }

  // 退货单删除接口 
  function deleterefund(data){
    return request({
        url:  "/refund/deleterefund",
        method: 'post',
        data
      })
  }

  // 退货单状态设置接口
  function setrefund(data){
    return request({
        url:  "/refund/setrefund",
        method: 'post',
        data
      })
  }

  // 选择可以退货的商品接口
  function searchrefundproduct(data){
    return request({
        url:  "/refund/searchrefundproduct",
        method: 'post',
        data
      })
  }

  // 退货单详情添加接口
  function addrefunddetail(data){
    return request({
        url:  "/refunddetail/addrefunddetail",
        method: 'post',
        data
      })
  }
  function listpurchase(data){
    return request({
        url:  "/purchase/listpurchase",
        method: 'post',
        data
      })
  }
  function listrefunddetail(data){
    return request({
        url:  "/refunddetail/listrefunddetail",
        method: 'post',
        data
      })
  }
  function deleterefunddetail(data){
    return request({
        url:  "/refunddetail/deleterefunddetail",
        method: 'post',
        data
      })
  }
  function filminglist(data){
    return request({
        url:  "/channel/filminglist",
        method: 'post',
        data
      })
  }
  function setfilmingstatus(data){
    return request({
        url:  "/channel/setfilmingstatus",
        method: 'post',
        data
      })
  }
  function downloadfilm(data){
    return request({
        url:  "/channel/downloadfilm",
        method: 'post',
        data,
        responseType :'blob'
      })
  }
  function delchannelproduct(data){
    return request({
        url:  "/channel/delchannelproduct",
        method: 'post',
        data
      })
  }
  function deletefilmingproduct(data){
    return request({
        url:  "/channel/deletefilmingproduct",
        method: 'post',
        data
      })
  }
  function channelproducttofilming(data){
    return request({
        url:  "/channel/channelproducttofilming",
        method: 'post',
        data
      })
  }
  function resortfilming(data){
    return request({
        url:  "/channel/resortfilming",
        method: 'post',
        data
      })
  }
  function filmingdetailslist(data){
    return request({
        url:  "/channel/filmingdetailslist",
        method: 'post',
        data
      })
  }
  function setdoustatus(data){
    return request({
        url:  "/channel/setdoustatus",
        method: 'post',
        data
      })
  }
  function editfilmproductstatus(data){
    return request({
        url:  "/channel/editfilmproductstatus",
        method: 'post',
        data
      })
  }
  function createfilming(data){
    return request({
        url:  "/channel/createfilming",
        method: 'post',
        data
      })
  }

  export const orderApi = {
    createfilming,
    editfilmproductstatus,
    setdoustatus,
    filmingdetailslist,
    resortfilming,
    channelproducttofilming,
    deletefilmingproduct,
    delchannelproduct,
    downloadfilm,
    setfilmingstatus,
    filminglist,
    deleterefunddetail,
    listrefunddetail,
    listrefund,
    listpurchase,
    editpurchase,
    addrefunddetail,
    editpurchasedetail,
    deletepurchasedetail,
    listpurchasedetail,
    listrefundselect,
    editrefund,
    deleterefund,
    setrefund,
    searchrefundproduct,
    listlive,
    editlive,
    addlive,
    addrefund,
    channelproductlist,
    sendproductchannel,
    deletepurchase,
    addpurchasedetail
}