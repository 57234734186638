
<template>
  <div class="layout">
    <a-layout id="components-layout-demo-side" style=" padding: 0;margin: 0;height: 100vh ">
      <!-- 左侧：主菜单 -->
      <a-layout-sider :style="{ overflow: 'auto', height: '100vh', }" v-model="collapsed" collapsible>
        <MenuList :menuList="menuList"  />
        <!-- <MenuList :menuList="menuList" @setSubmenu="setSubmenu" /> -->
      </a-layout-sider>
      <!-- 右侧：内容 -->
      <a-layout style="min-height: 100vh ">
        <!-- 公共头 -->
        <Header />
        <!-- 内容 -->
        <a-layout-content :style="{ margin: '24px 16px 0', overflow: 'initial' }">
  
          <!-- 主要内容 -->
          <div :style="{ padding: '24px', minHeight:'100%', background: '#fff' }">
            <router-view></router-view>
          </div>
        </a-layout-content>
      </a-layout>
    </a-layout>
  </div>
</template>

<script>
import MenuList from '@/components/MenuList.vue'
import Submenu from '@/components/Submenu.vue'
import Header from '@/components/Header.vue'

export default {
  name: 'Layout',
  data() {
    return {
      collapsed: false,
      // 菜单
      menuList: [

        {
          id: 3,
          name: '商品',
          logo: 'dropbox',
          isActive: true,
          submenuTitle: '商品',
          path: '/goods',
          submenu: [
                {
                  key: '3-1-1',
                  title: '商品库',
                  parent: '商品中心',
                  isActive: true,
                  path: '/goods/list',
                },
                {
                  key: '3-2-1',
                  title: '批量上传',
                  parent: '商品工具',
                  path: '/goods/tool',
                },
          ],
        },


        {
          id: 10,
          name: '采购管理',
          logo: 'dropbox',
          submenuTitle: '采购管理',
          path: '/purchase',
          submenu: [
                {
                  key: '10-1-1',
                  title: '采购单列表',
                  parent: '采购单管理',
                  path: '/purchase/purchaseList',
                },
                {
                  key: '10-1-2',
                  title: '退货单列表',
                  parent: '采购单管理',
                  path: '/purchase/returnableList',
                },
                {
                  key: '14-1-1',
                  title: '结算单列表',
                  parent: '结算单管理',
                  path: '/purchase/invoicesList',
                },
              
          ],
        },
       /* {
          id: 11,
          name: '供货单',
          logo: 'dropbox',
          submenuTitle: '供货单',
          path: '/supplyOrder',
          submenu: [
                {
                  key: '11-1-1',
                  title: '供货管理',
                  parent: '供货单管理',
                  path: '/supplyOrder/supplyOrderList',
                },
                {
                  key: '11-1-2',
                  title: '退货单列表',
                  parent: '供货单管理',
                  path: '/supplyOrder/supplyreturnableList',
                },
             
          ],
        },*/
        {
          id: 12,
          name: '直播间',
          logo: 'dropbox',
          submenuTitle: '直播间',
          path: '/liveRoom',
          submenu: [
          {
                key: '5-1-1',
                title: '选品车管理',
                parent: '直播间管理',
                isActive: false,
                path: '/retail/retailGoods',
              },
                {
                  key: '12-1-1',
                  title: '直播间列表',
                  parent: '直播间管理',
                  path: '/liveRoom/liveRoomList',
                },
                {
                key: '5-1-2',
                title: ' 直播计划列表 ',
                parent: '直播间管理',
                isActive: false,
                path: '/retail/martList',
              },
              
              

             
          ],
        },
        {
          id: 13,
          name: '用户',
          logo: 'dropbox',
          submenuTitle: '用户',
          path: '/userManage',
          submenu: [
                {
                  key: '13-1-1',
                  role: [1,2],
                  title: '用户列表',
                  parent: '用户管理',
                  path: '/userManage/userManageList',
                },
                {
                  key: '13-1-2',
                  title: '委托方列表',
                  parent: '用户管理',
                  path: '/userManage/clientManageList',
                },
                // {
                //   key: '13-1-2',
                //   title: '对接人列表',
                //   parent: '用户管理',
                //   path: '/userManage/receiverManageList',
                // },
                {
                  key: '13-1-3',
                  role: [1], //  超级管理员=1、店铺管理员=2、店员=3
                  title: '店铺列表',
                  parent: '用户管理',
                  path: '/userManage/shopList',
                },
                {
                  key: '13-1-4',
                  role: [1],
                  title: '打印机列表',
                  parent: '用户管理',
                  path: '/userManage/printList',
                },
          ],
        },
        {
          id: 9,
          name: '个人',
          logo: 'user',
          isActive: false,
          submenuTitle: '个人',
          path: '/user/userinfo',
          submenu: [
              {
                key: '9-1-4',
                title: '个人信息',
                isActive: false,
                path: '/user/userinfo',
              }
]
        }
      ],
      
      
    }
  },
  components: {
    MenuList,
    Submenu,
    Header
  },
  mounted() {
    // this.setSubmenu();
  },
  methods: {
  },
}
</script>

<style lang="less" scoped>
@import "./../../assets/less/reset.less";
@import "./../../assets/less/config.less";

#components-layout-demo-side .logo {
  height: 32px;
  background: rgba(255, 255, 255, 0.2);
  margin: 16px;
}

.layout {
  :global(.ant-layout-sider) {}

  .a-layout-submenu {
    display: block !important;
    flex: none !important;
    border-right: 1px solid #e8e8e8;
    background: #fff;
  }

  :global(.a-layout-submenu) {
    display: block !important;
  }

  .nav {
    display: flex;
    align-items: center;
  }

  :global(.ant-layout-content .ant-breadcrumb > span:last-child) {
    color: @themeColor;
  }

  :global(.ant-menu-item-group-title) {
    font-weight: 600;
    color: #000 !important;
  }
}
</style>