
<template>
    <div class="comon-list operDiarList">
        <h2>商品操作日记页</h2>

      <div style=" margin-top: 30px">
        <a-table 
          :columns="columns" 
          :scroll={x:700,y:500} 
          :data-source="data" 
          :pagination="pagination" 
          :loading="loading"
          @change="onChangTable" 
        >
        </a-table>
        <a-button type="primary"  @click="goBackList" class="footerBtn" > 返回列表 </a-button>
      </div>
      <a-modal title="图片预览" class="preview" :visible="previewVisible" :footer="null" @cancel="handleCancel">
        <img
          v-for="(item, index) in previewImages"
          :key="index"
          :src="item"
          style="width:30%"
          @click="showImg(index)"
        />
      </a-modal>
    </div>
  </template>
  
  
  
  <script>
    import {
      h
    } from "vue";
    
    import { goodsApi } from "@/api/good"

    import {
      message
    } from "ant-design-vue";
  
    export default {
      name: "martList",
      data() {
        return {
          previewVisible: false,
          previewImages: [],
          data: [],
          columns:[
          {
                title: "序号",
                dataIndex: "index",
                width: '70px',
                customRender: (text, record, index) => `${index + 1}`,

            },
              {
              title: "操作时间",
              dataIndex: "create_time",
              // width: '150px',
              scopedSlots: {
                  customRender: "create_time",
              },
              },
              {
              title: "操作事项",
              dataIndex: "items",
              // width: '150px',
              scopedSlots: {
                  customRender: "items",
              },
              customRender: (value,record)=>{
                      let arr = JSON.parse(value)
                      let text =arr.join(',')
                      return h('div',{},text)
                  },
              },
              {
              title: "操作内容",
              dataIndex: "compare",
              // width: '90px',
              scopedSlots: {
                customRender: "compare",
              },
              customRender: (value,record)=>{
                console.log(value,'value--')
                      // let data = JSON.parse(value)
                      let data = value
                console.log(data,'data--')

                      let arr = []
                      

                      Object.keys(data).map(key=>{
                        let oneLine = ''
                        if(key == '商品图片'){
                          oneLine = []
                          console.log(data[key].old, data[key].new ,'data[key].old--')
                          let oldObj = JSON.parse(data[key].old)
                          let newObj = JSON.parse(data[key].new)
                          oneLine.push(
                              h(
                                "span",
                                 key + ':'
                              )
                            )
                            oneLine.push(
                              h(
                                "img",
                               { style: {
                                  width: "20px",
                                  height: "20px",
                                  marginLeft: "5px",
                                },
                                attrs: {
                                  src: oldObj[0].url,
                                },
                                on: {
                                  click: () => this.picPreview(oldObj)
                                }
                              }
                              )
                            )
                          oneLine.push(
                              h(
                                "span",
                                 " ->"
                              )
                            )
                            oneLine.push(
                              h(
                                "img",
                               { style: {
                                  width: "20px",
                                  height: "20px",
                                  marginLeft: "5px",
                                },
                                attrs: {
                                  src: newObj[0].url,
                                },
                                on: {
                                  click: () => this.picPreview(newObj)
                                }
                              }
                              )
                            )
                          arr.push(
                          h(
                            "div",{style:{
                        marginBottom:'5px'
                      }},oneLine
                          ))
                        }else{
                         oneLine = `${key}:【${data[key].old}】->【${data[key].new}】`

                          arr.push(
                          h(
                            "div",{style:{
                              marginBottom:'5px'

                      }},oneLine
                          )
                        )

                        }
                        
                      })
                      
                      return h('div',{style:{
                        textAlign:'left'
                      }},arr)
                  },
              },
              {
              title: "操作人",
              dataIndex: "creator_name",
              // width: '90px',
              scopedSlots: {
                customRender: "creator_name",
                  
              },
            
              },
             
          ],
          loading: false,
          pagination: {
            total: 0,
            current: 1,
            pageSize:50
          },
        };
      },
      
      beforeCreate() {
      },
      mounted() {
          this.search()
      },
      methods: {
        //点击图片预览
        showImg(index) {
          this.$hevueImgPreview({
            multiple: true,
            nowImgIndex: index,
            imgList: this.previewImages
          })
        },
        //图片预览
        picPreview(imgs) {
          if (imgs) {
            imgs.map(img=>{
              this.previewImages.push(img.url)
            })
            this.previewVisible = true;
          }
        },
        //关闭预览
        handleCancel() {
          this.previewImages = []
          this.previewVisible = false;
        },
          //搜索
        search() {
            let params = {
              page: this.pagination.current,
              pageSize:this.pagination.pageSize,
              pid: this.$route.query.pid
              
            };
            this.getTable(params);
        },
           //获取表格数据
        getTable(data) {
          this.loading = true;
          goodsApi.getproductlog(data)
            .then((res) => {
              console.log(res, "resres");
              if (res.data.status == 200 && res.data.data.list.length > 0) {
                this.data = []
                this.pagination.total = +res.data.data.total;
                this.data = res.data.data.list
              } else {
                this.data = [];
                this.pagination.total = 0;
              }
            })
            .finally((res) => {
              this.loading = false;
            });
        },
        //翻页
        onChangTable(page) {
          this.pagination.current = page.current;
          this.pagination.pageSize = page.pageSize;
          this.search()
        },
          //返回
            goBackList(){
            this.$router.push({path:'/goods/list',query: {date:new Date().getTime()}})
            },
      },
    };
  </script>
  
  <style lang="less" scoped>
    @import "./../../../assets/less/config.less";
    .footerBtn{
    width: 160px;
    height: 52px;
    margin-top: 30px;
}
   
  </style>