
<template>
    <div class="comon-list">
      <div class="comon-check-form">
        <a-form style="padding: 20px" class="searchArea" :form="form" layout="inline" :label-col="{ span: 7 }"
          :wrapper-col="{ span: 17 }">
          <a-form-item label="商品名称" style="width: 235px">
            <a-input v-decorator="['title']" placeholder="请输入" />
          </a-form-item>
          <a-form-item label="状态" style="width: 235px">
            <a-select allowClear showSearch v-decorator="['cate']" placeholder="请选择或输入搜索">
              <a-select-option v-for="item in cateList" :key="item.id" :value="item.name">{{ item.name }}</a-select-option>
            </a-select>
          </a-form-item>
          <a-form-item label="直播间" style="width: 235px">
            <a-select allowClear v-decorator="['new_type', { initialValue: '0' }]" placeholder="请选择">
              <a-select-option v-for="item in typeList" :key="item.value" :value="item.value">{{ item.name
              }}</a-select-option>
            </a-select>
          </a-form-item>
  
          <a-form-item>
            <a-button type="primary" @click="searchGoods"> 搜索 </a-button>
          </a-form-item>
          <a-form-item>
            <a-button class="resetBtn" @click="resetSearch"> 重置 </a-button>
          </a-form-item>
        </a-form>
      </div>
  
      <div style="height: 500px;  margin-top: 30px">
        <a-table :columns="columns" :scroll={x:700,y:500} :data-source=" data " :pagination=" pagination "
          :loading=" loading " @change=" onChangTable ">
          <template slot="operation" slot-scope="text, record">
            <a-popconfirm title="确定要删除该商品" ok-text="删除" cancel-text="取消" @confirm="onEdit(record.pid,'delete')">
                <a href="javascript:;" class="operate" >删除</a>
    
            </a-popconfirm>
            
            <a href="javascript:;" class="operate" @click=" () => onEdit(record.pid,'check') ">查看</a>
          </template>
        </a-table>
      </div>
  
  
    </div>
  </template>
    
    
    
  <script>
  import {
    h
  } from "vue";
  
  import {orderApi} from "@/api/order"  
  import {
    message
  } from "ant-design-vue";
  
  export default {
    name: "GoodsList",
    data() {
      return {
        data: [],
        columns: [{
          title: "编号",
          dataIndex: "cate_name",
          width: '150px',
          fixed: 'left',
  
          scopedSlots: {
            customRender: "cate_name",
          },
        },
        {
          title: "商品名称",
          dataIndex: "title",
          width: '150px',
          fixed: 'left',
          scopedSlots: {
            customRender: "title",
          },
        },
        {
          title: "sku",
          dataIndex: "sku",
          width: '150px',
  
  
          scopedSlots: {
            customRender: "sku",
          },
        },
  
        {
          title: "采购价",
          dataIndex: "in_price",
          width: '90px',
          scopedSlots: {
            customRender: "in_price",
          },
          customRender: (value, record) => {
            return parseInt(value)
          }
        },
        {
          title: "销售价",
          dataIndex: "mid_price",
          width: '90px',
          scopedSlots: {
            customRender: "mid_price",
          },
          customRender: (value, record) => {
            return parseInt(value)
          }
        },
        {
          title: "采购时间",
          dataIndex: "cate_name",
          width: '120px',
          scopedSlots: {
            customRender: "cate_name",
          },
        },
        {
          title: "直播间时间",
          dataIndex: "cate_name",
          width: '120px',
          scopedSlots: {
            customRender: "cate_name",
          },
        },
        {
          title: "备注信息",
          dataIndex: "from_username_name",
          width: '90px',
          scopedSlots: {
            customRender: "from_username_name",
          },
        },
        {
          title: "供货方",
          dataIndex: "to_username_name",
          width: '90px',
          scopedSlots: {
            customRender: "to_username_name",
          },
        },
        {
          title: "状态",
          dataIndex: "create_time",
          width: '200px',
          scopedSlots: {
            customRender: "create_time",
          },
        },
  
        {
          title: "操作",
          dataIndex: "operation",
          width: '150px',
          fixed: 'right',
          scopedSlots: {
            customRender: "operation",
          },
        },
        ],
        selectedRowKeys: [],
        loading: false,
        pagination: {
          total: 0,
          current: 1,
          pageSize: 50,
        },
        typeList: [
          {
            name: '全部',
            value: '0'
          },
          {
            name: '生意货',
            value: '1'
          },
          {
            name: '臻品',
            value: '2'
          },
        ],
        cateList: [],
        fromUsernameList: [],
        toUsernameList: [],
        goodStatus: 1,
        previewVisible: false,
        previewImage: "",
        moduleVisible:false,
        returnModuleVisible:false,
        modalType:'check'
      };
    },
  
    beforeCreate() {
      if (this.$cookie.get('username')) {
        this.form = this.$form.createForm(this, {
          name: "validate_other",
        });
      } else {
        message.warning('请登录')
        this.$router.push({ path: '/login' })
      }
    },
    mounted() {
      this.getCateList();
      this.searchGoods();
      message.config({
        top: `100px`,
        duration: 8,
      });
    },
    computed: {
      hasSelected() {
        return this.selectedRowKeys.length > 0;
      },
    },
    methods: {
  
      //批量发布到渠道
      goRetail() {
        let data = { 
          pids: this.selectedRowKeys.join(',')

         }
        orderApi.sendproductchannel(data)
          .then(res => {
            if (res.data.status == 200) {
              message.success('批量发布成功')
              this.searchGoods()
              this.selectedRowKeys = []
            }
          })
  
  
      },
      //批量删除/批量上架、下架
      //批量上架、下架
      batchChange(type) {
        let data = {
          pids: this.selectedRowKeys.join(','),
          type: type == 1 ? 1 : this.goodStatus == 1 ? 2 : 3,
          creator_id: this.$cookie.get('userid'),
          shop_id:this.$cookie.get('userid')

        }
        let str = type == 1 ? '删除' : this.goodStatus == 1 ? '下架' : '上架'
        axios
          .post(publicConst.proxyPath + "/product/oprateproductmulti", data, {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          })
          .then(res => {
            console.log(res, 'rrrrrrrrrrrr')
            if (res.data.status == 200) {
              message.success(`批量${str}成功`)
              this.searchGoods()
              this.selectedRowKeys = []
            }
          })
  
      },
  
      //重置搜索条件
      resetSearch() {
        this.form.resetFields();
        this.pagination.current = 1
        this.searchGoods();
      },
      //图片预览
      picPreview(src) {
        if (src) {
          this.previewImage = src
          this.previewVisible = true;
        }
      },
      //关闭预览
      handleCancel() {
        this.previewImage = ''
        this.previewVisible = false;
      },
      //分类-行制-委托方列表
      getCateList() {
        axios
          .get(publicConst.proxyPath + "/product/getcatelist", {
            headers: {
              "Content-Type": "multipart/form-data"
            },
          })
          .then((res) => {
            this.cateList = res.data.data;
          });
        axios.get(publicConst.proxyPath + '/product/gettofromlist?uid='+this.$cookie.get('userid'), {
          headers: { 'Content-Type': 'multipart/form-data' }
        }).then(res => {
          this.fromUsernameList = res.data.data.clientsList
          this.toUsernameList = res.data.data.receiverList
        })
      },
      //获取表格数据
      getTable(data) {
        this.loading = true;
        axios
          .post(publicConst.proxyPath + "/product/getproductlist", data, {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          })
          .then((res) => {
            console.log(res, "resres");
            if (res.data.status == 200 && res.data.data.list.length > 0) {
              this.data = []
              this.selectedRowKeys = []
              this.pagination.total = +res.data.data.total;
  
              res.data.data.list.map(good => {
                this.data.push({ ...good, key: good.pid })
                //  if(good.on_status == 1){
                //   this.selectedRowKeys.push(good.pid)
                //  }
  
              })
            } else {
              this.data = [];
              this.pagination.total = 0;
            }
          })
          .finally((res) => {
            this.loading = false;
          });
      },
      //翻页
      onChangTable(page) {
        this.pagination.current = page.current;
        this.searchGoods()
      },
      changStatus() {
        this.resetSearch()
      },
      changefromUsername(value) {
        let pid = this.fromUsernameList.filter(item => item.name == value)[0].pid
        let hasTo = this.toUsernameList.filter(item => item.id == pid)[0]
        let name = ''
        if (hasTo) {
          name = hasTo.name
        }
        this.form.setFieldsValue({ to_username: name })
      },
      //搜索
      searchGoods() {
        this.form.validateFields((err, values) => {
          let params = {
            page: this.pagination.current,
            pageSize: 50,
            ...values,
            cate: values.cate ? this.cateList.filter(item => item.name == values.cate)[0].id : '',
            to_username: values.to_username ? this.toUsernameList.filter(item => item.name == values.to_username)[0].id : '',
            from_username: values.from_username ? this.fromUsernameList.filter(item => item.name == values.from_username)[0].id : '',
            onstatus: this.goodStatus,
            creator_id: this.$cookie.get('userid'),
            shop_id:this.$cookie.get('userid')

          };
          this.getTable(params);
        });
      },
      //删除
      onDelete(pid) {
        axios
          .post(
            publicConst.proxyPath + "/product/delproduct", {
            pid,
          }, {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
          )
          .then((res) => {
            if (res.data.status == 200) {
              message.success(res.data.message);
              this.pagination.current = 1;
              this.searchGoods();
            }
          });
      },
      //编辑
      onEdit(pid,type) {
        switch(type){
          case 'delete':
            
  
            break;
            case 'check':
            this.$router.push({ path: '/goods/publish' })
            break;
            default:
            break;
  
        }
        
       
      },
      
  
      handleChange(value, key, column) {
        const newData = [...this.data];
        const target = newData.find((item) => key === item.key);
        if (target) {
          target[column] = value;
          this.data = newData;
        }
      },
  
      onSelectChange(selectedRowKeys) {
        console.log("selectedRowKeys changed: ", selectedRowKeys);
        this.selectedRowKeys = selectedRowKeys;
      },
    },
  };
  </script>
    
  <style lang="less" scoped></style>