<!--
 * @Descripttion: *
 * @Author: wjj
 * @LastEditors: *
 * @LastEditTime: 2023-01-13 23:55:57
-->
<template>
  <div>
    goodsType
  </div>
</template>

<script>
export default {
  name: 'GoodsType'
}
</script>