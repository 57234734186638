import { render, staticRenderFns } from "./LiveRoomList.vue?vue&type=template&id=510be199&scoped=true"
import script from "./LiveRoomList.vue?vue&type=script&lang=js"
export * from "./LiveRoomList.vue?vue&type=script&lang=js"
import style0 from "./LiveRoomList.vue?vue&type=style&index=0&id=510be199&prod&lang=less&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "510be199",
  null
  
)

export default component.exports