
<template>
    <div class="comon-list goods-list">
      <div class="comon-check-form">
        <a-form style="padding: 20px" class="searchArea" :form="form" layout="inline" :label-col="{ span: 7 }"
          :wrapper-col="{ span: 17 }">
          <a-form-item label="商品名称" style="width: 235px">
            <a-input v-decorator="['title']" placeholder="请输入" />
          </a-form-item>
          <a-form-item label="内部编码" style="width: 235px">
            <a-input v-decorator="['sku']" placeholder="请输入" />
          </a-form-item>
          <a-form-item label="商品分类" style="width: 235px">
            <a-cascader v-decorator="[
              'cateArr',
              {
                initialValue: '',
  
              },
            ]" placeholder="请选择或输入搜索" allowClear showSearch
              :field-names="{ label: 'name', value: 'code', children: 'items' }" :options="cateList" />
            <!-- <a-select allowClear showSearch v-decorator="['cate']" placeholder="请选择或输入搜索" >
              <a-select-option v-for="item in cateList" :key="item.id" :value="item.name">{{ item.name }}</a-select-option>
            </a-select> -->
          </a-form-item>
          <a-form-item label="商品逻辑"  style="width: 235px">
        <a-select
          v-decorator="[
            'new_type'
          ]"
          placeholder="请选择"
          allowClear
        >
          
           <a-select-option  v-for="item in typeList" :key="item.value" :value="item.value" >{{item.name}} </a-select-option> 
         
        </a-select>
      </a-form-item>
  
          <a-form-item>
            <a-button type="primary" @click="searchGoods"> 搜索 </a-button>
          </a-form-item>
          <a-form-item>
            <a-button class="resetBtn" @click="resetSearch"> 重置 </a-button>
          </a-form-item>
        </a-form>
      </div>
      <!-- <a-radio-group @change="changStatus" button-style="solid" v-model="goodStatus" :style="{ marginTop: '20px' }">
        <a-radio-button :value="1">
          上架
        </a-radio-button>
        <a-radio-button :value="2">
          下架
        </a-radio-button>
      </a-radio-group> -->
      <div style="margin: 16px 0">
        <span>
          <template v-if="hasSelected">
            {{ `已经选中 ${selectedRowKeys.length} 件` }}
          </template>
        </span>
        <!-- <a-button style="margin-left: 8px" @click="() => batchChange(2)">
          批量{{ goodStatus == 1 ? '下架' : '上架' }}
        </a-button> -->
        <a-button style="margin-left: 8px"  @click="goRetail">
          批量添加
        </a-button>
        <!-- <a-button style="margin-left: 8px" @click="() => batchChange(1)">
          批量删除
        </a-button>
        <a-button style="margin-left: 8px" @click="exportAll">
          批量导出
        </a-button> -->
  
        <!-- <a-select class="sendToretail" allowClear showSearch placeholder="批量发布到渠道" >
            <a-select-option v-for="item in cateList" :key="item.id" :value="item.name">{{ item.name }}</a-select-option>
          </a-select> -->
  
      </div>
      <div style="height: 500px;  margin-top: 30px">
        <a-table :row-selection="{ selectedRowKeys: selectedRowKeys, onChange: onSelectChange }" :columns="columns"
          :scroll={x:700,y:500} :data-source="data" :pagination="pagination" :loading="loading" @change="onChangTable">
          <!-- :row-selection="{
            selectedRowKeys: selectedRowKeys,
            onChange: onSelectChange,
          }" -->
          <template slot="operation" slot-scope="text, record">
            <div><a href="javascript:;" class="operate" @click="() => onEdit(record.pid)">编辑</a></div>
            
            <div><a-popconfirm v-if="data.length" :title="record.on_status == 1 ? '确定下架此商品吗？' : '确定上架此商品吗'
              " ok-text="确定" cancel-text="取消" @confirm="() => onEditStatus(record.pid)">
              <a href="javascript:;" :class="record.on_status == 1 ? 'operate' : 'operate'">{{
                record.on_status == 1 ? "下架" : "上架"
              }}</a>
            </a-popconfirm></div>
            <div><a-popconfirm v-if="data.length" title="确定删除此商品吗？" ok-text="确定" cancel-text="取消"
              @confirm="() => onDelete(record.pid)">
              <a href="javascript:;" class="operate grey">删除</a>
            </a-popconfirm></div>
           
          </template>
        </a-table>
      </div>

      <div class="footer">
          <a-button type="primary" @click="handleBack" class=" footerBtn"> 返回结算单详情</a-button>
      </div>
  
      <a-modal class="preview" :visible="previewVisible" :footer="null" @cancel="handleCancel">
        <img alt="example" style="width: 100%" :src="previewImage" />
      </a-modal>
    </div>
  </template>
  
  
  
  <script>
  import {
    h
  } from "vue";
  
  
  import { goodsApi } from "@/api/good";
  import {
    message, Modal
  } from "ant-design-vue";
  
  export default {
    name: "GoodsList",
    data() {
      return {
        data: [],
        columns: [
          {
            title: "内部编码",
            dataIndex: "sku",
            width: '120px',
            // fixed: 'left',
  
            scopedSlots: {
              customRender: "sku",
            },
            customRender:(value,record)=>{
              if(record.cid != 0){
                return <div style="color:#1890ff">{value}</div>
              }else{
                return value
              }
            }
          },
          {
            title: "商品名称",
            dataIndex: "name",
            width: '150px',
            scopedSlots: {
              customRender: "name",
            },
          },
          {
            title: "商品照片",
            dataIndex: "img",
            width: '140px',
            scopedSlots: {
              customRender: "img",
            },
            customRender: (value, record) => {
  
  
              if (value && value.includes('[')) {
                let obj = JSON.parse(value);
                const array = [];
  
                obj.map((img, index) => {
                  if (img && index === 0) {
                    array.push(
                      h(
                        "img", {
                        style: {
                          width: "70px",
                          height: "70px",
                          marginLeft: "5px",
                        },
                        attrs: {
                          src: img.url,
                        },
                        on: {
                          click: () => this.picPreview(img.url)
                        }
                      },
                        () => item
                      )
                    );
  
                  }
  
                });
                return h("div", array);
  
              } else {
                return '--'
              }
            },
          },
          {
            title: "售价",
            dataIndex: "sale_price",
            width: '90px',
            scopedSlots: {
              customRender: "sale_price",
            },
            customRender: (value, record) => {
              return parseInt(value)
            }
          },
          {
                  title: "原价",
                  dataIndex: "retail_price",
                  width: '90px',
                  scopedSlots: {
                      customRender: "retail_price",
                  },
              },
          {
            title: "商品分类",
            dataIndex: "cate_name",
            width: '90px',
            scopedSlots: {
              customRender: "cate_name",
            },
          },
          {
                title: "排品逻辑",
                dataIndex: "new_type",
                scopedSlots: {
                    customRender: "new_type",
                },
            width: '90px',

                customRender: (value, record) => {
                    let text = this.typeList.filter(item => item.value == value)[0].name
                    return text
                }
            },
          {
            title: "尺寸",
            dataIndex: "standards",
            width: '140px',
            scopedSlots: {
              customRender: "standards",
  
            },
            customRender: (value, record) => {
              let obj = JSON.parse(value);
              let lenArr = []
              Object.keys(obj).map(key => {
                lenArr.push(obj[key].length)
              })
              let maxLen = lenArr.sort((a, b) => b - a)[0] || 0
              if (maxLen > 0) {
                const array = [];
                for (let i = 0; i < maxLen; i++) {
                  let str = []
                  str.push(
                    h("div",
                      {
                        style: {
                          textAlign: 'left'
                        }
                      },
                      '规格:' + `${obj.standarsCaliber[i] +'*'|| ''}${obj.standarsWidth[i] || ''}${'*'+obj.standarsThickness[i] || ''}`)
                  )
                  if(obj.standarsDiameter[i]){
                    str.push(
                    h("div",
                      {
                        style: {
                          textAlign: 'left'
                        }
                      },
                      '直径:' + `${obj.standarsDiameter[i] }`)
                  )
  
                  }
                 
                  if(obj.standarsDiameter[i]){
                    str.push(
                    h("div",
                      {
                        style: {
                          textAlign: 'left'
                        }
                      },
                      '重量:' + `${obj.standarsWeight[i] || ''}`)
                  )
  
                  }
                  
                  // '名：' + `${obj.standarsName[i] || '-'}`+
                    //  '规格:' + `${obj.standarsCaliber[i] || '-'}*${obj.standarsWidth[i] || '-'}*${obj.standarsThickness[i] || '-'}`
                    // + ',直径:' + `${obj.standarsDiameter[i] || '-'}`
                    // + ',重量:' + `${obj.standarsWeight[i] || '-'}`
                  array.push(
                    h("div",
                      {
                        style: {
                          textAlign: 'left'
                        }
                      },
                      str)
                  )
                }
                return h('div', array)
  
              } else {
                return '--'
              }
  
            },
          },
         
          {
            title: "提交时间",
            dataIndex: "update_date",
            width: '200px',
            scopedSlots: {
              customRender: "update_date",
            },
          },
  
        //   {
        //     title: "操作",
        //     dataIndex: "operation",
        //     width: '120px',
        //     // fixed: 'right',
        //     scopedSlots: {
        //       customRender: "operation",
        //     },
        //   },
        ],
        selectedRowKeys: [],
        loading: false,
        pagination: {
          total: 0,
          current: 1,
          pageSize: 50,
        },
        typeList:[
      // {
      //   name:'未选择',
      //   value:'0'
      // },
      {
        name:'福利款',
        value:'1'
      },
      {
        name:'承接款',
        value:'2'
      },
      {
        name:'利润款',
        value:'3'
      },
      {
        name:'精品款',
        value:'4'
      },
      {
        name:'挂链款',
        value:'5'
      },
    ],
        cateList: [],
        // fromUsernameList: [],
        // toUsernameList: [],
        previewVisible: false,
        previewImage: "",
        settlement_id: this.$route.query.settlement_id,
        supplier_id: this.$route.query.supplier_id,
      };
    },
  
  
    beforeCreate() {
      console.log(this.$cookie.get('username'), 'this.$cookie')
      if (this.$cookie.get('username')) {
        this.form = this.$form.createForm(this, {
          name: "validate_other",
        });
      } else {
        message.warning('请登录')
        this.$router.push({ path: '/login' })
      }
    },
    mounted() {
      this.getCateList();
      this.searchGoods();
      message.config({
        top: `100px`,
        duration: 8,
      });
    },
    computed: {
      hasSelected() {
        return this.selectedRowKeys.length > 0;
      },
    },
    methods: {
      
      handleBack() {
            this.$router.push({ path: '/purchase/invoicesDetal', query: { id: this.settlement_id,supplier_id:this.supplier_id }  })

        },
    
  
      //批量添加到结算单
      goRetail() {
        let data = {
          settlement_id:this.settlement_id,
          supplier_id:this.supplier_id,
          pids: this.selectedRowKeys.join(',')
        }
        goodsApi.addsettlementdetails(data)
          .then(res => {
            if (res.data.status == 200) {
              message.success('批量添加成功')
              this.searchGoods()
              this.selectedRowKeys = []
            }
          })
  
  
      },
      
  
      //重置搜索条件
      resetSearch() {
        this.form.resetFields();
        this.pagination.current = 1
        this.searchGoods();
      },
      //图片预览
      picPreview(src) {
        if (src) {
          this.previewImage = src
          this.previewVisible = true;
        }
      },
      //关闭预览
      handleCancel() {
        this.previewImage = ''
        this.previewVisible = false;
      },
      //分类-行制-委托方列表
      getCateList() {
        goodsApi.getcatelistnew()
          .then((res) => {
            this.cateList = res.data.data;
          });
        // axios.get(publicConst.proxyPath + '/product/gettofromlist?uid=' + this.$cookie.get('userid'), {
        //   headers: { 'Content-Type': 'multipart/form-data' }
        // }).then(res => {
        //   this.fromUsernameList = res.data.data.clientsList
        //   this.toUsernameList = res.data.data.receiverList
        // })
      },
      //获取表格数据
      getTable(data) {
        this.loading = true;
        goodsApi.purchaseproductlist(data)
          .then((res) => {
            console.log(res, "resres");
            if (res.data.status == 200 && res.data.data.list.length > 0) {
              this.data = []
              this.selectedRowKeys = []
              this.pagination.total = +res.data.data.total;
  
              res.data.data.list.map(good => {
                this.data.push({ ...good, key: good.pid })
                //  if(good.on_status == 1){
                //   this.selectedRowKeys.push(good.pid)
                //  }
  
              })
            } else {
              this.data = [];
              this.pagination.total = 0;
            }
          })
          .finally((res) => {
            this.loading = false;
          });
      },
      //翻页
      onChangTable(page) {
        this.pagination.current = page.current;
        this.searchGoods()
      },
      changStatus() {
        this.resetSearch()
      },
      //搜索
      searchGoods() {
        this.form.validateFields((err, values) => {
          let params = {
            page: this.pagination.current,
            pageSize: 50,
            ...values,
            cate_id: values.cateArr ? values.cateArr[values.cateArr.length - 1] : '',
            supplier_id:this.supplier_id
          };
          this.getTable(params);
        });
      },
      //删除
      onDelete(pid) {
        let params ={pid}
       goodsApi.delproduct(params).then((res) => {
            if (res.data.status == 200) {
              message.success(res.data.message);
              this.pagination.current = 1;
              this.searchGoods();
            }
          });
      },
      //操作日记页
      operDiar(pid){
        this.$router.push({
          path: "/goods/operDiar",
          query: {
            pid: pid,
          },
        });
      },
      //编辑
      onEdit(pid) {
        this.$router.push({
          path: "/goods/publish",
          query: {
            pid: pid,
          },
        });
      },
      //上架、下架
      onEditStatus(pid) {
        let data = {pid}
        goodsApi.getproduct(data).then(res => {
          if (res.data.status == 200) {
            let editData = res.data.data
            let params = {
              ...editData,
              on_status: editData.on_status == 1 ? 2 : 1,
  
            }
            goodsApi.editproduct(params).then(res => {
              if (res.data.status == 200) {
                message.success(`${editData.on_status == 1 ? '下架' : '上架'}成功`)
                this.searchGoods();
  
              }
  
            })
          }
        });
  
      },
  
      handleChange(value, key, column) {
        const newData = [...this.data];
        const target = newData.find((item) => key === item.key);
        if (target) {
          target[column] = value;
          this.data = newData;
        }
      },
  
      onSelectChange(selectedRowKeys) {
        console.log("selectedRowKeys changed: ", selectedRowKeys);
        this.selectedRowKeys = selectedRowKeys;
      },
    },
  };
  </script>
  
  <style lang="less" scoped>
  </style>