
<template>
  <div class="comon-list">
    <h2 style="margin-bottom: 20px;">采购单详情</h2>
    <div class="comon-publish " v-if="purchase_status*1 === 0">
      <a-button type="primary" @click="addPurchaseGood">  添加商品  </a-button>
    </div>
    <div class="comon-check-form">
      <a-form style="padding: 20px" class="searchArea" :form="form" layout="inline" :label-col="{ span: 7 }"
        :wrapper-col="{ span: 17 }">
        <a-form-item label="商品名称" style="width: 338px">
          <a-input allowClear v-decorator="['search']" placeholder="根据名称、平台或内部编码搜索" />
        </a-form-item>
        <a-form-item label="状态" style="width: 235px">
          <a-select allowClear showSearch v-decorator="['status']" placeholder="请选择或输入搜索">
            <a-select-option v-for="item in stateList" :key="item.id" :value="item.id">{{ item.name }}</a-select-option>
          </a-select>
        </a-form-item>

        <a-form-item>
          <a-button type="primary" @click="searchGoods"> 搜索 </a-button>
        </a-form-item>
        <a-form-item>
          <a-button class="resetBtn" @click="resetSearch"> 重置 </a-button>
        </a-form-item>
      </a-form>
    </div>

    <div style=" position: relative; margin-top: 30px">
      <a-table :columns="columns" :scroll={x:700,y:500} :data-source=" data " :pagination=" pagination "
        :loading=" loading " @change=" onChangTable ">

        <template slot="operation" slot-scope="text, record">
          <a href="javascript:;"  v-if="purchaseData.purchase_status != 1" class="operate" @click=" () => onEdit(record,'edit') ">编辑</a>
          <a href="javascript:;" class="operate" v-if="record.is_completed != 1" @click=" () => onEdit(record,'check') ">同步</a>
          <a-popconfirm v-if="purchaseData.purchase_status != 1" title="确定要删除该商品" ok-text="删除" cancel-text="取消" @confirm="onEdit(record,'delete')">
                <a href="javascript:;" class="operate" >删除</a>
            </a-popconfirm>
            <span  v-if="purchaseData.purchase_status == 1 && record.is_completed == 1">无操作</span>
        </template>
      </a-table>
      <div class="footer">
          <a-button type="primary" @click="handleBack" class=" footerBtn"> 返回</a-button>
      </div>
    </div>
    <a-modal class="preview" :visible="previewVisible" :footer="null" @cancel="handleCancel">
      <img alt="example" style="width: 100%" :src="previewImage" />
    </a-modal>
    
    <a-modal 
      title="编辑商品信息" 
      :visible=" moduleVisible " 
      okText="确定" 
      cancelText="取消" 
      @ok="handleOk('edit')"  
      @cancel="moduleVisible = false"
    >
      <a-form style="padding: 20px" class="searchArea" :form=" editform " :label-col=" { span: 5 } "
        :wrapper-col=" { span: 15 } ">
        <a-form-item label="商品名称" >
          <a-input  v-decorator=" ['purchase_price',{initialValue: activeItem.name }] " disabled />
        </a-form-item>
         <a-form-item label="平台编码" >
          <a-input  v-decorator=" ['purchase_price',{initialValue: activeItem.code }] " disabled />
        </a-form-item>
        <a-form-item label="拿货价" >
          <a-input  v-decorator=" ['purchase_price',{initialValue: activeItem.purchase_price }] " placeholder="请输入" />
        </a-form-item>
        <a-form-item label="备注">
          <a-textarea 
                v-decorator="['remark', { initialValue: activeItem.remark }]"
                placeholder="请输入"    
                style="width: 320px;" 
                :rows="4" :max-length=150 
              />
        </a-form-item>
      </a-form>
    </a-modal>
    
    <a-modal 
    title="退货操作" 
    :visible=" returnModuleVisible "
    okText="确定" cancelText="取消" @ok="handleOk('return')"  @cancel="returnModuleVisible = false">
      <a-form style="padding: 20px" class="searchArea" :form="returnform" :label-col=" { span: 6 } "
        :wrapper-col=" { span: 13 } ">
        <a-form-item label="商品名称" style="width: 500px">
          <a-input  disabled v-decorator=" ['product_name',{initialValue: activeItem.product_name }] "  />
        </a-form-item>
        <a-form-item label="商品SKU" style="width: 500px">
          <a-input disabled  v-decorator=" ['sku',{initialValue: activeItem.sku }] "  />
        </a-form-item>
        <a-form-item label="选择退货单" style="width: 500px">
          <a-select v-decorator=" ['refund_id'] " allowClear placeholder="请选择">
            <a-select-option v-for=" item  in  returnList " :key=" item.refund_id " :value=" item.refund_id ">{{ item.name
              }}</a-select-option>
          </a-select>
        </a-form-item>
      </a-form>


    </a-modal>

  </div>
</template>
  
  
  
<script>
import {orderApi} from "@/api/order"
import {
  message
} from "ant-design-vue";

import { h } from "vue";

export default {
  name: "GoodsList",
  data() {
    return {
      purchase_status: this.$route.query.purchase_status, // 商品处理状态 0-未提交， 1-已提交
      data: [],
      columns: [{
        title: "编号",
            width: '70px',
            fixed: 'left',
            dataIndex: "detail_id",
            key: 'detail_id',
            scopedSlots: {
              customRender: "detail_id",
            },
            
      },
      {
        title: "商品名称",
        dataIndex: "name",
        key: 'name',
        width: '200px',
        fixed: 'left',
        scopedSlots: {
          customRender: "name",
        },
        customRender: (value, record) => {
          // is_deleted是否同步商品: 1-是，0-否
          if( record.is_completed*1 === 1) {
            return (
              <span>
                { value }
                <a-tag color="green">已同步</a-tag>
              </span>
            )
          }else {
            return (
               <span>
                { value }
                <a-tag color="green">未同步</a-tag>
                </span>
            )
          }
         
        },
        
      },
      {
          title: "商品照片",
          dataIndex: "img",
          key: 'img',
          width: '140px',
          scopedSlots: {
            customRender: "img",
          },
          customRender: (value, record) => {
            if (value && value.includes('[')) {
              let obj = JSON.parse(value);
              const array = [];
    
             obj.map((img, index) => {
                if (img && index === 0) {
                  array.push(
                    h(
                      "img", {
                      style: {
                        width: "70px",
                        height: "70px",
                        marginLeft: "5px",
                      },
                      attrs: {
                        src: img.url,
                      },
                      on: {
                        click: () => this.picPreview(img.url)
                      }
                    },
                      () => item
                    )
                  );

                }

              });

              return h("div", array);

            } else {
              return '--'
            }
          },
      },
      {
        title: "平台编码",
        dataIndex: "code",
        key: 'code',
        width: '150px',
        scopedSlots: {
          customRender: "code",
        },
      },

      {
        title: "拿货价",
        dataIndex: "purchase_price",
        key: 'purchase_price',
        width: '90px',
        scopedSlots: {
          customRender: "purchase_price",
        },
        customRender: (value, record) => {
          return parseInt(value)
        }
      },
      {
        title: "采购时间",
        dataIndex: "purchase_time",
        key: 'purchase_time',
        width: '120px',
        scopedSlots: {
          customRender: "purchase_time",
        },
      },
      {
        title: "备注信息",
        dataIndex: "remark",
        key: 'remark',
        width: '90px',
        scopedSlots: {
          customRender: "remark",
        },
      },
      {
            title: "状态",
            dataIndex: "status",
            key: 'status',
            width: '200px',
            scopedSlots: {
              customRender: "status",
            },
            customRender: (value,record)=>{
              if(value == 0){
                return '未售出'
              }
              if(value == 1){
                return '已售罄'
              }
              if(value == 2){
                return '已退还'
              }
              return '--'
            }
          },

      {
        title: "操作",
        dataIndex: "operation",
        key: 'operation',
        width: '150px',
        fixed: 'right',
        scopedSlots: {
          customRender: "operation",
        },
      },
      ],
      selectedRowKeys: [],
      loading: false,
      previewVisible: false,
      previewImage: "",
      pagination: {
        total: 0,
        current: 1,
        perPage: 50,
      },
      liveRoomList: [],
      stateList: [
        {name:'未售出',id:'0'},
        {name:'已售罄',id:'1'},
        {name:'已退还',id:'2'},
      ],
      returnList: [],
      moduleVisible:false,
      returnModuleVisible:false,
      purchaseData:{},
      activeItem:{},
      editform: this.$form.createForm(this, { name: 'editform' }),
      returnform: this.$form.createForm(this, { name: "returnform"})
    };
    
  },

  beforeCreate() {
    if (this.$cookie.get('username')) {
      this.form = this.$form.createForm(this, {
        name: "validate_other",
      });
    } else {
      message.warning('请登录')
      this.$router.push({ path: '/login' })
    }
  },
  mounted() {
    this.purchaseData =  {
      ...this.$route.query
    }
    // console.log('this.purchaseData', this.purchaseData)
    this.getCateList();
    this.searchGoods();
    message.config({
      top: `100px`,
      duration: 8,
    });
  },
  computed: {
  },
  methods: {
     //图片预览
    picPreview(src) {
      if (src) {
        this.previewImage = src
        this.previewVisible = true;
      }
    },
    //关闭预览
    handleCancel() {
      this.previewImage = ''
      this.previewVisible = false;
    },
    // 添加商品
    addPurchaseGood() {
      const { purchase_id, supplier_name, supplier_shop_id } = this.$route.query;
      this.$router.push({ 
        path: '/Purchase/PurchaseGoods' ,
        query: {
          purchase_id,
          supplier_shop_id,
          supplier_name
        }
      })
    },

    handleBack() {
           this.$router.back()
        },
    getCateList() {
        orderApi.listlive()
          .then((res) => {
            this.liveRoomList = res.data.data.data;
          });
         orderApi.listrefundselect().then(res=>{
            this.returnList = res.data.data
          })
      },
    //重置搜索条件
    resetSearch() {
      this.form.resetFields();
      this.pagination.current = 1
      this.searchGoods();
    },
    //获取表格数据
    getTable(data) {
      this.loading = true;
     orderApi.listpurchasedetail(data)
        .then((res) => {
          // console.log(res, "resres");
          if (res.data.status == 200 && res.data.data.list.length > 0) {
            this.data = []
            this.pagination.total = +res.data.data.total;
            res.data.data.list.map(good => {
              this.data.push({ ...good, key: good.product_id })

            })
          } else {
            this.data = [];
            this.pagination.total = 0;
          }
        })
        .finally((res) => {
          this.loading = false;
        });
    },
    //翻页
    onChangTable(page) {
      this.pagination.current = page.current;
      this.searchGoods()
    },
    //搜索
    searchGoods() {
      // console.log(this.purchaseData,'this.purchaseData')
      this.form.validateFields((err, values) => {
        let params = {
          page: this.pagination.current,
          perPage: 50,
          ...values,
          ...this.purchaseData,
          creator_id: this.$cookie.get('userid'),
          shop_id:this.$cookie.get('userid')


        };
        this.getTable(params);
      });
    },
    //操作
    onEdit(item,type) {
      this.activeItem = item
      switch(type){
        case 'edit': // 编辑
          this.moduleVisible = true
          this.editform = this.$form.createForm(this, {
            name: "editform",
          });
          break;
          case 'check'://同步
          const { product_id, detail_id } = item;
          this.$router.push({ path: '/goods/publish' ,query: {
              pid: product_id,
              detail_id,
              type: 1,
            },})
          break;
          case 'delete'://删除
          let data = {
          detail_id:this.activeItem.detail_id

        };
        orderApi.deletepurchasedetail(data)
        .then((res) => {
          if(res.data.status == 200){
            message.success(`删除成功`)
            this.searchGoods();
          }else{
            message.error(res.data.message)
          }
        })
          break;
          case 'return':
          this.returnModuleVisible = true
          this.returnform = this.$form.createForm(this, {
           name: "returnform",})
           
           
          break;
          default:
          break;
      }
    },
    handleOk(type){
      if(type == 'edit'){//编辑
        this.editform.validateFields((err, values) => {
        let data = {
          ...values,
          detail_id:this.activeItem.detail_id,

        };
        orderApi.editpurchasedetail(data)
        .then((res) => {
          console.log(res,'edit')
          if(res.data.status == 200){
            message.success(`编辑成功`)
            this.searchGoods();
          }else{
            message.error(res.data.message)
          }
          this.moduleVisible = false
        })

      })

      }
      if(type == 'return'){//退货
        this.returnform.validateFields((err, values) => {
        let data = {
          ...values,
          product_id:this.activeItem.product_id,
          purchase_price:this.activeItem.purchase_price,
          supplier_id:this.activeItem.supplier_id,
          remark:this.activeItem.remark,
        };
        orderApi.addrefunddetail(data)
        .then((res) => {
          console.log(res,'returnform')
          if(res.data.status == 200){
            message.success(`退货成功`)
            this.searchGoods();
          }else{
            message.error(res.data.message)
          }
          this.returnModuleVisible = false

        })

      })

      }
    }
    
  },
};
</script>
  
<style lang="less" scoped>
 .footer {
  position: absolute;
      bottom: 15px;
      text-align: left;

        button {
            margin-right: 16px;
        }
    }</style>