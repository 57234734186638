<template>
    <div class=" comon-list martDetail">
        <h2 style="margin-bottom: 20px;">结算单详情</h2>
        <div class="comon-publish " >
            <a-button type="primary" @click="addGoods">  添加商品  </a-button>
            </div>
        <div class="comon-check-form ">
            <a-form style="padding: 20px" class="searchArea" :form="form" layout="inline" :label-col="{ span: 5 }"
                :wrapper-col="{ span: 17 }">
                <a-form-item label="商品名称" style="width: 500px">
                    <a-input v-decorator="['title']" placeholder="请输入商品名称、平台编码CODE、内部编码SKU" />
                </a-form-item>
                <a-form-item>
                    <a-button type="primary" @click="searchMart"> 搜索 </a-button>
                </a-form-item>
                <a-form-item>
                    <a-button class="resetBtn" @click="resetSearch"> 重置 </a-button>
                </a-form-item>
            </a-form>
        </div>
            <div style="maxHeight: 750px; margin-top: 30px">
                <a-table id="mytable" :pagination="false" :columns="goodsColumns" :scroll="{ x: 700, y: 500 }"
                    :data-source="goodsData"  :loading="loading" @change="onChangTable">
                    <template slot="operation" slot-scope="text, record">
                        <a  href="javascript:;" class="operate " @click="onEdit(record, 'look')">查看</a>
                        <a  href="javascript:;" v-if="status == 0" class="operate " @click="() => onEdit(record, 'edit')">修改价格</a>
                        <a-popconfirm v-if="status == 0" title="确定删除此商品吗？" ok-text="确定"
                            cancel-text="取消" @confirm="() => onEdit(record, 'delete')">
                            <a href="javascript:;" class="operate">删除</a>
                        </a-popconfirm>
                    </template>
                </a-table>
                <div class="footer">
                    <a-button @click="handleBack" class=" footerBtn"> 返回</a-button>
                    <!-- <a-button  type="primary" @click="handleAdd" class=" footerBtn"> 继续添加</a-button> -->
                </div>
            </div>
            <a-modal title="更改价格" :visible="moduleVisible" okText="确定" cancelText="取消" @ok="handleSubmit"
                @cancel="moduleVisible = false">
                <a-form style="padding: 20px" class="searchArea" :form="editform" :label-col="{ span: 10 }"
                    :wrapper-col="{ span: 14 }">
                    <a-form-item label="名称" style="width: 360px">
                    <a-input disabled v-decorator="['name', { initialValue: activeItem.name }]" placeholder="请输入" />
                    </a-form-item>
                    <a-form-item label="价格" style="width: 360px">
                    <a-input  v-decorator="['sellte_price', { initialValue: activeItem.sellte_price }]" placeholder="请输入" />
                    </a-form-item>
                   
                </a-form>
                </a-modal>
                <a-modal class="preview" :visible="previewVisible" :footer="null" @cancel="handleCancel">
        <img alt="example" style="width: 100%" :src="previewImage" />
      </a-modal>
    </div>
</template>



<script>
import {
    h
} from "vue";
import { goodsApi } from "@/api/good";
import {
    message
} from "ant-design-vue";
export default {
    name: "martDetail",
    data() {
        return {
            previewImage: '',
            moduleVisible:false,
            activeItem:{},
            typeList: [

                {
                    name: '福利款',
                    value: '1'
                },
                {
                    name: '承接款',
                    value: '2'
                },
                {
                    name: '利润款',
                    value: '3'
                },
                {
                    name: '精品款',
                    value: '4'
                },
                {
                    name: '挂链款',
                    value: '5'
                },
            ],
            goodsData: [],
            goodsColumns: [
                {
                title: "序号",
                dataIndex: "id",
                key: 'id',
                width: '70px',
                scopedSlots: {
                    customRender: "id",
                },
            },
            {
                title: "商品名称",
                dataIndex: "name",
                key: 'name',
                width: '150px',
                scopedSlots: {
                    customRender: "name",
                },
            },
            {
                title: "图片",
                dataIndex: "img",
                key: 'img',
                width: '90px',
                scopedSlots: {
                    customRender: "img",
                },
                customRender: (value, record) => {
                    if (value && value.includes('[')) {
                    let obj = JSON.parse(value);
                    const array = [];

                    obj.map((img, index) => {
                        if (img && index === 0) {
                        array.push(
                            h(
                            "img", {
                            style: {
                                width: "70px",
                                height: "70px",
                                marginLeft: "5px",
                            },
                            attrs: {
                                src: img.url,
                            },
                            on: {
                                click: () => this.picPreview(img.url)
                            }
                            },
                            () => item
                            )
                        );

                        }

                    });
                    return h("div", array);

                    } else {
                    return '--'
                    }
                },
            },
             {
                title: "平台编码",
                dataIndex: "code",
                key: 'code',
                width: '150px',
                scopedSlots: {
                    customRender: "code",
                },
            },
            {
                title: "代发价",
                dataIndex: "in_price",
                key: 'sale_price',
                width: '150px',
                scopedSlots: {
                    customRender: 'in_price'
                },
            },
            {
                title: "结算价",
                dataIndex: "sellte_price",
                key: 'sellte_price',
                width: '150px',
                scopedSlots: {
                    customRender: "sellte_price",
                },
            },
            {
                title: "商品状态",
                dataIndex: "on_status",
                key: 'on_status',
                width: '100px',
                scopedSlots: {
                customRender: "on_status",
                },
                customRender: (value, record) => {
                // 1已上架、2已下架 、3代销中、4已售出
                if (value == 1) {
                    return '已上架'
                }
                if (value == 2) {
                    return '已下架'
                }
                 if (value == 3) {
                    return '代销中'
                }
                 if (value == 4) {
                    return '已售出'
                }
                return '--'
                }
            },
           {
                title: "备注信息",
                dataIndex: "remark",
                key: 'remark',
                width: '150px',
                scopedSlots: {
                    customRender: "remark",
                },
            },
            {
                title: "操作",
                dataIndex: "operation",
                key: 'operation',
                width: '200px',
                fixed: 'right',
                scopedSlots: {
                    customRender: "operation",
                },
            },
            ],
            isLoading: false,
            loading: false,
            page: 1,
            pagination: {
                total: 0,
                current: 1,
                pageSize: 50
            },
            sourceObj: '',// 源目标数据序号
            sourceIndex: '',
            targetIndex: '',
            targetObj: '',// 目标数据序号
            previewVisible: false,
            status: 0, // 结算单状态
        };
    },

     beforeCreate() {

        if (this.$cookie.get('username')) {
            this.form = this.$form.createForm(this, {  name: "validate_other"  });
            this.editform = this.$form.createForm(this, { name: "validate_edit" });
        } else {
            message.warning('请登录')
            this.$router.push({ path: '/login' })
        }
    },
    mounted() {
        this.status= this.$router.query;
        this.searchMart()
        // this.scroll()
    },
    methods: {
        // 添加商品
        addGoods() {
            const { supplier_name, supplier_id, shop_id, id } = this.$route.query;
            this.$router.push({
                path: '/purchase/invoicesGoods',
                query: {
                    supplier_name,
                    supplier_id, // 委托方id
                    shop_id, // 店铺id
                    settlement_id: id, // 结算单id
                }
            })
        },

         //图片预览
      picPreview(src) {
        if (src) {
          this.previewImage = src
          this.previewVisible = true;
        }
      },
       //关闭预览
       handleCancel() {
        this.previewImage = ''
        this.previewVisible = false;
      },
        //重置搜索条件
        resetSearch() {
            this.form.resetFields();
            this.searchMart();
        },
        //搜索
        searchMart() {
            const { creator_id, id} = this.$route.query;
            const title = this.form.getFieldValue('title');
            let params = {
                    title,
                    creator_id,
                    settlement_id: id,
                    page: this.pagination.current,
                    pageSize: this.pagination.pageSize,
            }
      
            this.getTable(params);
         
        },
        //获取表格数据
        getTable(data) {
            this.loading = true;
            goodsApi.listsettlementdetail(data)
                .then((res) => {
                    console.log(res, "resres");
                    const list = res.data.data.list || res.data.data.data
                    if (res.data.status == 200 ) {
                        this.isLoading = true
                        this.goodsData = []
                        this.pagination.total = +res.data.data.total;
                        this.goodsData = list.map((good, index) => { 
                                return { ...good, sorts: index, key: good.id } 
                            })
                    } else {
                        message.warning('暂无更多数据信息！')
                       
                    }
                })
                .finally((res) => {
                    this.loading = false;
                });
        },
        //翻页
        onChangTable(page) {
            this.pagination.current = page.current;
            this.pagination.pageSize = page.pageSize;
            this.searchMart()
        },
        handleBack() {
            this.$router.push({ path: '/purchase/invoicesList' })

        },
        handleAdd() {
            const { supplier_id, id} = this.$route.query;
            this.$router.push({ 
                path: '/purchase/goodsSold', 
                query: { 
                    settlement_id: id,
                    supplier_id
                } 
            })

        },
        handleSubmit() {
            this.editform.validateFields((err, values) => {
                console.log(err,values,'err---')
                let data = {
                id:   this.activeItem.id ,
                sellte_price:values.sellte_price,

            }
            goodsApi.editsettlementdetail(data).then(res => {
                if (res.data.status == 200) {
                    message.success('编辑成功')
                } else {
                    message.error(res.data.message)
                }
            }).finally(() => {
                this.moduleVisible = false
                this.activeItem = {}
                    this.searchMart()
                })
            })
           
        },
        
        //删除/编辑价格
        onEdit(item, type) {
            console.log(item,'edit----')
            this.activeItem = item
            let params = {
                id:item.id,
             }
             let msg = ''
            switch(type){
                case 'look': // 查看
                const { pid } = item;
                console.log('type', type,item)
                    this.$router.push({
                        path: '/goods/publish',
                        query: {
                            pid,
                            type: 2,
                        }
                    })
                 break;
                case 'delete':
                    msg = '删除成功'
                    goodsApi.deletesettlementdetail(params).then(res => {
                        if (res.data.status == 200) {
                            message.success(msg)
                        } else {
                            message.error(res.data.message)
                        }

                    }).finally(() => {
                        this.searchMart()
                    })
                    break;
                    case 'edit':
                      this.moduleVisible = true
                    break;    
            }
           

        },
    },
};
</script>

<style lang="less" scoped>

.martDetail {
    padding: 20px;
    overflow: hidden;

    .comon-check-form {
        margin-bottom: 20px;
    }
    .operate{
        &.disa{
            cursor: not-allowed;
            color: #999;

        }

    }
    

    .footer {
        width: 100%;
        text-align: center;
        margin-top: 16px;

        button {
            margin-right: 16px;
        }
    }
}
</style>