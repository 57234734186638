
<template>
  <div class="comon-list goods-list">

    <div class="comon-check-form goods-check-form">
      <a-form style="padding: 20px" class="searchArea" :form="form" layout="inline" :label-col="{ span: 7 }"
        :wrapper-col="{ span: 17 }">
        <a-form-item label="商品名称" style="width: 235px">
          <a-input v-decorator="['title']" placeholder="请输入" />
        </a-form-item>
        <a-form-item label="商品分类" style="width: 235px">
          <a-cascader v-decorator="[
            'cateArr',
            {
              initialValue: '',

            },
          ]" placeholder="请选择或输入搜索" allowClear showSearch
            :field-names="{ label: 'name', value: 'code', children: 'items' }" :options="cateList" />
        </a-form-item>
        <a-form-item label="内部编码" style="width: 235px">
          <a-input v-decorator="['sku']" placeholder="请输入" />
        </a-form-item>
        <a-form-item label="排品逻辑" style="width: 235px">
          <a-select v-decorator="[
            'new_type',
            {
              initialValue: '',

            },
          ]" placeholder="请选择">
            <a-select-option v-for="item in typeList" :key="item.value" :value="item.value">{{ item.name }}
            </a-select-option>
          </a-select>
        </a-form-item>
        <a-form-item>
          <a-button type="primary" @click="searchGoods"> 搜索 </a-button>
        </a-form-item>
        <a-form-item>
          <a-button class="resetBtn" @click="resetSearch"> 重置 </a-button>
        </a-form-item>
      </a-form>
    </div>
    <div style="margin: 16px 0">
      <a-button :disabled="!hasSelected" @click="() => onEditStatus()" style="marginRight: 16px">
        批量推送到
      </a-button>
      <a-button type="primary" :disabled="!hasSelected" :loading="reloadLoading" @click="reload">
        全部清空
      </a-button>
    </div>
    <div style="height: 500px;  margin-top: 30px">
      <a-table
        :row-selection="{ selectedRowKeys: selectedRowKeys, onChange: onSelectChange, getCheckboxProps: getCheckboxProps }"
        :columns="columns" :scroll={x:700,y:500} :data-source="data" :pagination="pagination" :loading="loading"
        @change="onChangTable">
        <template slot="operation" slot-scope="text, record">
          <a href="javascript:;" @click="() => onEditStatus(record)" :class="record.status == 2 ? 'operate' : 'operate'">{{
  record.status == 2 ? "退出场次" : "推进场次"
}}</a>
          <a-popconfirm v-if="data.length" title="确定删除此商品吗？" ok-text="确定" cancel-text="取消"
            @confirm="() => onDelete(record.pid)">
            <a href="javascript:;" class="operate grey">删除</a>
          </a-popconfirm>
        </template>
      </a-table>
    </div>

    <a-modal class="preview" :visible="previewVisible" :footer="null" @cancel="handleCancel">
      <img alt="example" style="width: 100%" :src="previewImage" />
    </a-modal>

    <a-modal class="preview" title="选择拍场推进" :visible="martModal" okText="确定" cancelText="取消" @ok="handleOkMart"
      @cancel="handleCancelMart">

      <span>拍场：</span>
      <span>
        <a-select allowClear showSearch :value="martId" @change="handleChangeMart" placeholder="请选择或输入搜索"
          style="width:calc(100% - 60px) ;">
          <a-select-option v-for="item in martList" :key="item.id" :value="`${item.id}--${item.place}`">{{ item.place
}}-{{ item.s_time }}</a-select-option>
        </a-select>
      </span>

    </a-modal>

  </div>
</template>



<script>
import {
  h
} from "vue";


import { goodsApi } from "@/api/good";
import { orderApi } from "@/api/order"
import {
  message
} from "ant-design-vue";

export default {
  name: "GoodsList",
  data() {
    return {
      data: [],
      typeList: [
        {
          name: '未选择',
          value: '0'
        },
        {
          name: '福利款',
          value: '1'
        },
        {
          name: '承接款',
          value: '2'
        },
        {
          name: '利润款',
          value: '3'
        },
        {
          name: '精品款',
          value: '4'
        },
        {
          name: '挂链款',
          value: '5'
        },
      ],
      columns: [{
        title: "内部编码",
        dataIndex: "sku",
        width: '150px',
        fixed: 'left',

        scopedSlots: {
          customRender: "sku",
        },
      },
      {
        title: "商品名称",
        dataIndex: "name",
        width: '150px',
        scopedSlots: {
          customRender: "name",
        },
      },
      {
        title: "商品照片",
        dataIndex: "img",
        width: '140px',
        scopedSlots: {
          customRender: "img",
        },
        customRender: (value, record) => {
          if (value && value.includes('[')) {
            let obj = JSON.parse(value);
            const array = [];

            obj.map((img, index) => {
              if (img && index === 0) {
                array.push(
                  h(
                    "img", {
                    style: {
                      width: "70px",
                      height: "70px",
                      marginLeft: "5px",
                    },
                    attrs: {
                      src: img.url,
                    },
                    on: {
                      click: () => this.picPreview(img.url)
                    }
                  },
                    () => item
                  )
                );

              }

            });

            return h("div", array);

          } else {
            return '--'
          }

        },
      },
      {
        title: "尺寸规格",
        dataIndex: "standards",
        width: '150px',
        scopedSlots: {
          customRender: "standards",
        },
        customRender: (value, record) => {
          let obj = JSON.parse(value);
          let lenArr = []
          Object.keys(obj).map(key => {
            lenArr.push(obj[key].length)
          })
          let maxLen = lenArr.sort((a, b) => b - a)[0] || 0
          if (maxLen > 0) {
            const array = [];
            for (let i = 0; i < maxLen; i++) {
              let str = []
              str.push(
                h("div",
                  {
                    style: {
                      textAlign: 'left'
                    }
                  },
                  '规格:' + `${obj.standarsCaliber[i] + '*' || ''}${obj.standarsWidth[i] || ''}${'*' + obj.standarsThickness[i] || ''}`)
              )
              if (obj.standarsDiameter[i]) {
                str.push(
                  h("div",
                    {
                      style: {
                        textAlign: 'left'
                      }
                    },
                    '直径:' + `${obj.standarsDiameter[i]}`)
                )

              }

              if (obj.standarsDiameter[i]) {
                str.push(
                  h("div",
                    {
                      style: {
                        textAlign: 'left'
                      }
                    },
                    '重量:' + `${obj.standarsWeight[i] || ''}`)
                )

              }

              // '名：' + `${obj.standarsName[i] || '-'}`+
              //  '规格:' + `${obj.standarsCaliber[i] || '-'}*${obj.standarsWidth[i] || '-'}*${obj.standarsThickness[i] || '-'}`
              // + ',直径:' + `${obj.standarsDiameter[i] || '-'}`
              // + ',重量:' + `${obj.standarsWeight[i] || '-'}`
              array.push(
                h("div",
                  {
                    style: {
                      textAlign: 'left'
                    }
                  },
                  str)
              )
            }
            return h('div', array)

          } else {
            return '--'
          }

        },
      },
      {
        title: "排品逻辑",
        dataIndex: "new_type",
        width: '150px',
        scopedSlots: {
          customRender: "new_type",
        },
        customRender: (value, record) => {
          let text = this.typeList.filter(item => item.value == value)[0].name
          return text
        }
      },
      {
        title: "售价",
        dataIndex: "sale_price",
        width: '150px',
        scopedSlots: {
          customRender: "sale_price",
        },
      },
      {
        title: "原价",
        dataIndex: "retail_price",
        width: '90px',
        scopedSlots: {
          customRender: "retail_price",
        },
      },
      // {
      //   title: "商品分类",
      //   dataIndex: "cate_name",
      //   width: '120px',
      //   scopedSlots: {
      //     customRender: "cate_name",
      //   },
      // },

      {
        title: "入货盘时间",
        dataIndex: "create_time",
        width: '200px',
        scopedSlots: {
          customRender: "create_time",
        },
      },
      //  {
      //   title: "最近场次",
      //   dataIndex: "create_time",
      //   width: '200px',
      //   scopedSlots: {
      //     customRender: "create_time",
      //   },
      // },
      //  {
      //   title: "拍卖地点",
      //   dataIndex: "place",
      //   width: '200px',
      //   scopedSlots: {
      //     customRender: "place",
      //   },
      // },

      {
        title: "操作",
        dataIndex: "operation",
        width: '150px',
        fixed: 'right',
        scopedSlots: {
          customRender: "operation",
        },
      },
      ],
      selectedRowKeys: [],
      reloadLoading: false,
      loading: false,
      pagination: {
        total: 0,
        current: 1,
        pageSize: 50
      },
      cateList: [],
      fromUsernameList: [],
      toUsernameList: [],
      goodStatus: 1,
      previewVisible: false,
      previewImage: "",

      martModal: false,
      martList: [],
      martId: '',
      martPid: '',

    };
  },

  beforeCreate() {
    //    const that =this
    // function creatForm (){
    //     this.form = this.$form.createForm(this, {
    //         name: "validate_other",
    //     });
    // }
    // debugger
    // checkLogin(creatForm)
    if (this.$cookie.get('username')) {
      this.form = this.$form.createForm(this, {
        name: "validate_other",
      });
    } else {
      message.warning('请登录')
      this.$router.push({ path: '/login' })
    }
  },
  mounted() {
    this.getCateList();
    this.searchGoods();
    message.config({
      top: `100px`,
      duration: 8,
    });

  },
  computed: {
    hasSelected() {
      return this.selectedRowKeys.length > 0;
    },
  },
  methods: {

    // 清空选中
    reload() {
      this.reloadLoading = true;
      setTimeout(() => {
        this.reloadLoading = false;
        this.selectedRowKeys = [];
      }, 1000);
    },

    //重置搜索条件
    resetSearch() {
      this.form.resetFields();
      this.pagination.current = 1
      this.searchGoods();
    },
    //图片预览
    picPreview(src) {
      if (src) {
        this.previewImage = src
        this.previewVisible = true;
      }
    },
    //关闭预览
    handleCancel() {
      this.previewImage = ''
      this.previewVisible = false;
    },
    //列表
    getCateList() {
      goodsApi.getcatelistnew()
        .then((res) => {
          this.cateList = res.data.data;
        });

      let data = {
        page: 1,
        pageSize: 50,

      }

      orderApi.filminglist(data)
        .then((res) => {
          console.log(res, "resres");
          if (res.data.status == 200 && res.data.data.list.length > 0) {
            this.martList = res.data.data.list
          } else {
            message.error('获取拍场列表失败')
          }
        })



    },
    //获取表格数据
    getTable(data) {
      this.loading = true;
      orderApi.channelproductlist(data)
        .then((res) => {
          console.log(res, "resres");
          if (res.data.status == 200 && res.data.data.list.length > 0) {
            this.data = []
            this.selectedRowKeys = []
            this.pagination.total = +res.data.data.total;

            res.data.data.list.map(good => {
              this.data.push({ ...good, key: good.pid })
              if (good.on_status == 1) {
                this.selectedRowKeys.push(good.pid)
              }

            })
          } else {
            this.data = [];
            this.pagination.total = 0;
          }
        })
        .finally((res) => {
          this.loading = false;
        });
    },
    //翻页
    onChangTable(page) {
      this.pagination.current = page.current;
      this.searchGoods()
    },
    changStatus() {
      this.resetSearch()
    },
    changefromUsername(value) {
      let pid = this.fromUsernameList.filter(item => item.name == value)[0].pid
      let hasTo = this.toUsernameList.filter(item => item.id == pid)[0]
      let name = ''
      if (hasTo) {
        name = hasTo.name
      }
      this.form.setFieldsValue({ to_username: name })
    },
    //搜索
    searchGoods() {
      this.form.validateFields((err, values) => {
        let params = {
          page: this.pagination.current,
          ...values,
          cate_id: values.cateArr ? values.cateArr[values.cateArr.length - 1] : '',
          creator_id: this.$cookie.get('userid'),
          shop_id: this.$cookie.get('userid')

        };
        this.getTable(params);
      });
    },
    //删除
    onDelete(pid) {
      let data = { pid }
      orderApi.delchannelproduct(data)
        .then((res) => {
          if (res.data.status == 200) {
            message.success(res.data.message);
            this.pagination.current = 1;
            this.searchGoods();
          }
        });
    },
    //编辑
    onEdit(pid) {
      this.$router.push({
        path: "/goods/publish",
        query: {
          pid: pid,
        },
      });
    },
    //推进、退出
    onEditStatus(record) {
      if (record && record.status == 2) {//退出
        let data = {
          fid: record.fid,
          pids: record.pid

        }
        orderApi.deletefilmingproduct(data).then(res => {
          if (res.data.status == 200) {
            message.success('退出拍场成功')
            this.martModal = false
            this.searchGoods()
          } else {
            message.error(res.data.message)
          }
        });

      } else {//推进
        this.martModal = true
        if (record) {
          this.martPid = record.pid

        } else {
          this.martPid = this.selectedRowKeys.join(',')
        }
      }
    },

    handleChangeMart(v) {
      this.martId = v

    },
    handleOkMart() {
      let data = {
        fid: this.martId.split('--')[0],
        pids: this.martPid

      }
      orderApi.channelproducttofilming(data).then(res => {
        if (res.data.status == 200) {
          message.success('推进拍场成功')
          this.martModal = false
          this.searchGoods()
        } else {
          message.error(res.data.message)
        }
      });
    },
    handleCancelMart() {
      this.martModal = false
      this.martId = ''
      this.martPid = ''
    },

    handleChange(value, key, column) {
      const newData = [...this.data];
      const target = newData.find((item) => key === item.key);
      if (target) {
        target[column] = value;
        this.data = newData;
      }
    },

    getCheckboxProps: record => ({
      props: {
        disabled: record.status == 2,
        name: record.name,
      },
    }),

    onSelectChange(selectedRowKeys) {
      console.log("selectedRowKeys changed: ", selectedRowKeys);
      this.selectedRowKeys = selectedRowKeys;
    },
  },
};
</script>

<style lang="less" scoped>
@import "./../../../assets/less/reset.less";
@import "./../../../assets/less/config.less";

.sendToretail {
  width: 200px;
  margin-right: 20px;
}
</style>