import { render, staticRenderFns } from "./UserManageList.vue?vue&type=template&id=88494c56&scoped=true"
import script from "./UserManageList.vue?vue&type=script&lang=js"
export * from "./UserManageList.vue?vue&type=script&lang=js"
import style0 from "./UserManageList.vue?vue&type=style&index=0&id=88494c56&prod&lang=less&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "88494c56",
  null
  
)

export default component.exports