

const proxyPath = 'https://aa.dippnav.com'
// const proxyPath = 'http://cc.dippnav.com'
// const proxyPath = 'http://wenwan.dippnav.com'

const publicConst ={
    proxyPath
}

export default publicConst