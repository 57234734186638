var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"publish"},[_c('a-form',{attrs:{"id":"validate-other","form":_vm.form}},[_c('a-form-item',_vm._b({attrs:{"label":"用户名称"}},'a-form-item',_vm.formItemInputLayout,false),[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
        'username',
        {
          initialValue: _vm.editData.username,
          rules: [{ required: true, message: '请输入' }]
        },
      ]),expression:"[\n        'username',\n        {\n          initialValue: editData.username,\n          rules: [{ required: true, message: '请输入' }]\n        },\n      ]"}],attrs:{"placeholder":"请输入"}})],1),_c('a-form-item',_vm._b({attrs:{"label":"手机号码","has-feedback":""}},'a-form-item',_vm.formItemInputLayout,false),[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
        'tel',
        {
          initialValue: _vm.editData.tel,
          rules: [{ required: true, message: '请输入' }]
        },
      ]),expression:"[\n        'tel',\n        {\n          initialValue: editData.tel,\n          rules: [{ required: true, message: '请输入' }]\n        },\n      ]"}],attrs:{"placeholder":"请输入"}})],1),_c('a-form-item',_vm._b({attrs:{"label":"角色"}},'a-form-item',_vm.formItemInputLayout,false),[_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:([
        'role',
        {
          initialValue: _vm.editData.role,
          rules: [
            { required: true, message: '请选择或输入搜索' },
          ],
        },
      ]),expression:"[\n        'role',\n        {\n          initialValue: editData.role,\n          rules: [\n            { required: true, message: '请选择或输入搜索' },\n          ],\n        },\n      ]"}],attrs:{"placeholder":"请选择或输入搜索","allowClear":"","showSearch":""}},_vm._l((_vm.cateList),function(item){return _c('a-select-option',{key:item.role_id,attrs:{"value":item.role_id}},[_vm._v(_vm._s(item.name)+" ")])}),1)],1),(_vm.role_id*1 ===1 )?_c('a-form-item',_vm._b({attrs:{"label":"关联店铺"}},'a-form-item',_vm.formItemInputLayout,false),[_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:([
        'shop',
        {
          initialValue: _vm.editData.shop_name,
          rules: [
            { required: true, message: '请选择或输入搜索' },
          ],
        },
      ]),expression:"[\n        'shop',\n        {\n          initialValue: editData.shop_name,\n          rules: [\n            { required: true, message: '请选择或输入搜索' },\n          ],\n        },\n      ]"}],attrs:{"placeholder":"请选择或输入搜索","allowClear":"","showSearch":""}},_vm._l((_vm.shopList),function(item){return _c('a-select-option',{key:item.id,attrs:{"value":item.id}},[_vm._v(_vm._s(item.name)+" ")])}),1)],1):_vm._e(),_c('a-form-item',_vm._b({attrs:{"label":"密码","has-feedback":""}},'a-form-item',_vm.formItemInputLayout,false),[(_vm.editData.tel)?[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
          'password',
          {
            initialValue: _vm.editData.password,
          },
        ]),expression:"[\n          'password',\n          {\n            initialValue: editData.password,\n          },\n        ]"}],attrs:{"placeholder":"请输入"}})]:[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
        'password',
        {
          initialValue: _vm.editData.password,
          rules: [{ required: true, message: '请输入' }]
        },
      ]),expression:"[\n        'password',\n        {\n          initialValue: editData.password,\n          rules: [{ required: true, message: '请输入' }]\n        },\n      ]"}],attrs:{"placeholder":"请输入"}})]],2),(_vm.editData.id)?_c('a-form-item',_vm._b({attrs:{"label":"状态"}},'a-form-item',_vm.formItemInputLayout,false),[_c('a-radio-group',{directives:[{name:"decorator",rawName:"v-decorator",value:(['status', {
        initialValue: _vm.editData.status,
        rules: [{ required: true, message: '请选择' }]
      }]),expression:"['status', {\n        initialValue: editData.status,\n        rules: [{ required: true, message: '请选择' }]\n      }]"}]},[_c('a-radio',{attrs:{"value":"1"}},[_vm._v(" 正常 ")]),_c('a-radio',{attrs:{"value":"2"}},[_vm._v(" 禁用 ")])],1)],1):_vm._e(),_c('a-form-item',{attrs:{"wrapper-col":{ span: 12, offset: 4 }}},[_c('a-button',{staticClass:"submitBtn footerBtn",attrs:{"type":"primary"},on:{"click":_vm.handleSubmit}},[_vm._v(" "+_vm._s(_vm.submitText))]),_c('a-button',{staticClass:"footerBtn",on:{"click":_vm.handleReset}},[_vm._v(" 重置 ")]),_c('a-button',{staticClass:"footerBtn",on:{"click":_vm.goBackList}},[_vm._v(" 返回列表 ")])],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }