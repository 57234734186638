<!--
 * @Descripttion: *
 * @Author: wx
 * @LastEditors: *
 * @LastEditTime: 2023-03-25 17:47:58
-->

<template>
  <div>
    <a-form-item
      v-for="(key, index) in form.getFieldValue('standards_keys')"
      :key="key"
      v-bind="index === 0 ? formItemLayout : formItemLayoutWithOutLabel"
      :label="index === 0 ? '商品尺寸' : ''"
      :required="false"
    >
      <a-row type="flex" :gutter="[10, 0]">
        <a-col :span="3">
          <a-form-item v-bind="formItemLayoutStandars" label="名称">
            <a-input
              v-decorator="[
                `standarsName[${index}]`,
                {
                  initialValue:
                    (standarsList.standarsName &&
                      standarsList.standarsName[index]) ||
                    '',
                },
              ]"
            />
          </a-form-item>
        </a-col>
        <a-col :span="3">
          <a-form-item label="长" v-bind="formItemLayoutStandars">
            <a-input
              v-decorator="[
                `standarsCaliber[${index}]`,
                {
                  initialValue:
                    (standarsList.standarsCaliber &&
                      standarsList.standarsCaliber[index]) ||
                    '',
                },
              ]"
            />
          </a-form-item>
        </a-col>

        <a-col :span="3">
          <a-form-item label="宽" v-bind="formItemLayoutStandars">
            <a-input
              v-decorator="[
                `standarsWidth[${index}]`,
                {
                  initialValue:
                    (standarsList.standarsWidth &&
                      standarsList.standarsWidth[index]) ||
                    '',
                },
              ]"
            />
          </a-form-item>
        </a-col>
        <a-col :span="3">
          <a-form-item label="高/厚" v-bind="formItemLayoutStandars">
            <a-input
              v-decorator="[
                `standarsThickness[${index}]`,
                {
                  initialValue:
                    (standarsList.standarsThickness &&
                      standarsList.standarsThickness[index]) ||
                    '',
                },
              ]"
            />
          </a-form-item>
        </a-col>
        <a-col :span="3">
          <a-form-item label="直径" v-bind="formItemLayoutStandars">
            <a-input
              v-decorator="[
                `standarsDiameter[${index}]`,
                {
                  initialValue:
                    (standarsList.standarsDiameter &&
                      standarsList.standarsDiameter[index]) ||
                    '',
                },
              ]"
            />
          </a-form-item>
        </a-col>
        <a-col :span="3">
          <a-form-item label="重量" v-bind="formItemLayoutStandars">
            <a-input
              placeholder="例：2.4g"
              v-decorator="[
                `standarsWeight[${index}]`,
                {
                  initialValue:
                    (standarsList.standarsWeight &&
                      standarsList.standarsWeight[index]) ||
                    '',
                },
              ]"
            />
          </a-form-item>
        </a-col>
        <a-col flex="55px">
          <a-icon
            v-if="form.getFieldValue('standards_keys').length > 1"
            class="dynamic-delete-button"
            type="minus-circle"
            :disabled="form.getFieldValue('standards_keys').length === 1"
            @click="() => remove(key)"
          />
          <a-icon
            v-if="form.getFieldValue('standards_keys').length == key + 1"
            class="dynamic-add-button"
            type="plus-circle"
            @click="() => add()"
          />
        </a-col>
      </a-row>
    </a-form-item>
  </div>
</template>

<script>
export default {
  name: "Standars",
  props: {
    form: {
      type: Object,
      required: true,
    },
    standarsList: {
      type: Object,
      required: true,
    },
  },

  data: () => ({
    formItemLayout: {
      labelCol: {
        xs: {
          span: 24,
        },
        sm: {
          span: 2,
        },
      },
      wrapperCol: {
        xs: {
          span: 24,
        },
        sm: {
          span: 22,
        },
      },
    },
    formItemLayoutWithOutLabel: {
      wrapperCol: {
        xs: {
          span: 24,
          offset: 0,
        },
        sm: {
          span: 22,
          offset: 2,
        },
      },
    },
    formItemLayoutStandars: {
      labelCol: {
        xs: {
          span: 24,
        },
        sm: {
          span: 8,
        },
      },
      wrapperCol: {
        xs: {
          span: 24,
        },
        sm: {
          span: 16,
        },
      },
    },
  }),
  created() {
    console.log(this.standarsList,'standarsList----')
    this.form.getFieldDecorator("standards_keys", {
      initialValue: [0],
      preserve: true,
    });
  },
  methods: {
    remove(k) {
      const { form } = this;
      const standards_keys = form.getFieldValue("standards_keys");
      let standarsName = form.getFieldValue("standarsName");
      let standarsCaliber = form.getFieldValue("standarsCaliber");
      let standarsWidth = form.getFieldValue("standarsWidth");
      let standarsThickness = form.getFieldValue("standarsThickness");
      let standarsDiameter = form.getFieldValue("standarsDiameter");
      let standarsWeight = form.getFieldValue("standarsWeight");

      if (standards_keys.length === 1) {
        return;
      }
      standarsName.splice(k, 1);
      standarsCaliber.splice(k, 1);
      standarsWidth.splice(k, 1);
      standarsThickness.splice(k, 1);
      standarsDiameter.splice(k, 1);
      standarsWeight.splice(k, 1);
      form.setFieldsValue({
        standarsName: standarsName,
        standarsCaliber: standarsCaliber,
        standarsWeight: standarsWeight,
        standarsWidth: standarsWidth,
        standarsThickness: standarsThickness,
        standarsDiameter: standarsDiameter,
        standards_keys: [...Array(standarsName.length).keys()],
      });
    },

    add() {
      const { form } = this;
      const standards_keys = form.getFieldValue("standards_keys");
      const len = standards_keys.length;
      const newKey = standards_keys[len - 1] + 1;
      const nextKeys = standards_keys.concat([newKey]);

      form.setFieldsValue({
        standards_keys: nextKeys,
      });
    },
  },
};
</script>
<style lang="less" scoped>
.standardsLabel {
  margin-right: -15px;
  margin-left: 2px;
}

.dynamic-delete-button {
  margin-right: 5px;
}
</style>