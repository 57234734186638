<!--
 * @Descripttion: *
 * @Author: wjj
 * @LastEditors: *
 * @LastEditTime: 2024-10-19 20:16:54
-->
<template>
  <div class="returnable-goods">
     <div class="comon-check-form">
        <a-form style="padding: 20px" :form="form" layout="inline" :label-col="{ span: 6 }"
          :wrapper-col="{ span: 18 }">
          <a-form-item label="商品平台编码" style="width: 440px">
             <a-input 
              v-decorator="['search', {rules: [{ required: true, message: '请输入平台编码，示例:GP282H728278' }]}]" 
               placeholder="请输入平台编码，示例:GP282H728278" 
               allow-clear
               @change="onChange"
              />
          </a-form-item>

          <a-form-item  style="width: 440px">
            <a-button type="primary" @click="searchGoods"> 搜索 </a-button>
            <a-button style="margin-left: 20px;" @click="() => $router.back()">返回列表</a-button>
          </a-form-item>
        </a-form>
      </div>
      <div class="goods-info" v-if="infoData.title">
        <div class="goods-info-item">
          <span>商品销售状态：</span> <span :class="infoData.sale_status == 0? 'isGreen' : 'isRed' ">{{ saleStatusList.find(item => infoData.sale_status == item.id ).name }}</span>
        </div>
        <div class="goods-info-item">
          <span>商品名称：</span> <span>{{ infoData.title}}</span>
        </div>
        <div class="goods-info-item">
          <span>图片：</span>
          <img :src="imgUrl" alt="">
        </div>
        <div class="goods-info-item">
          <span>拿货价：</span> <span>{{ infoData.in_price}}</span>
        </div>
        <div class="goods-info-item">
          <span>商品描述：</span> <span>{{ infoData.details || '--'}}</span>
        </div>
        <div class="goods-info-item">
          <span>商品品相：</span> <span>{{ infoData.condition || '--'}}</span>
        </div>
        <!-- <div class="goods-info-item">
          <span>委托方：</span> <span>{{ infoData.from_username}}</span>
        </div> -->
        <div class="goods-info-item">
          <span>商品分类：</span> <span>{{ infoData.cate_name}}</span>
        </div>
        <div class="goods-info-item">
          <span>内部编码：</span> <span>{{ infoData.sku}}</span>
        </div>
        <div class="goods-info-item">
          <span>平台编码：</span> <span>{{ infoData.code }}</span>
        </div>
        <div class="goods-info-item  standards-values">
          <span>商品尺寸：</span> 
          <ul>
            <li v-for="(item, index) in infoData.standards_values" :key="index">
             <span class="info-label">{{ item.key }}</span> <span>{{ item.value }}</span>
            </li>
          </ul>
        </div>
        <div class="goods-info-item">
          <span>材质：</span> <span>{{ infoData.material || '--' }}</span>
        </div>
        <div class="goods-info-item">
          <span>题识：</span> <span>{{ infoData.recognition || '--' }}</span>
        </div>
        <div class="goods-info-item">
          <span>创作年代：</span> <span>{{ infoData.age || '--' }}</span>
        </div>
        <div class="goods-info-item">
          <span>作者：</span> <span>{{ infoData.author || '--' }}</span>
        </div>
        <div class="goods-info-item">
          <span>形制：</span> <span>{{ infoData.xingzhi || '--' }}</span>
        </div>
        <div class="goods-info-item">
          <span>商品库存：</span> <span>{{ infoData.stocks || '--' }}</span>
        </div>
   
        <div class="goods-info-item">
          <span>对接人：</span> <span>{{ infoData.to_username_name || '--' }}</span>
        </div>
        <div class="goods-info-item">
          <span>委托方：</span> <span>{{ infoData.from_username_name || '--' }}</span>
        </div>

        <div class="goods-info-item">
          <span>备注信息：</span>
    
          <a-textarea placeholder="请输入"   v-model="remark" style="width: 300px;" :rows="4" :max-length=150 />
        </div>
        <div class="goods-btn">
          <a-button type="primary" @click="addToReturnableList(infoData)">添加到退货单</a-button>
          
        </div>
      </div>
  </div>
</template>

<script>

   import { orderApi } from "@/api/order";

export default {
  name: 'ReturnableGood',
  components: {
   
  },
  data() {
    return {
       form: this.$form.createForm(this, { name: 'addReturnableGood' }),
        infoData: {},
        imgUrl: '',
        remark: '',
        saleStatusList: [  // 销售状态: 0未售出（标绿） 1售罄（标红） 2已退还（标红）
          {
            id: '0',
            name: '未售出'
          },
          {
            id: '1',
            name: '已售罄'
          },
          {
            id: '2',
            name: '已退还'
          },

        ], 
    }
  },
  methods: {
    searchGoods() {

    },
    
   //清除输入框时清除底部数据
    onChange(e) {
      const { value } = e.target;
      if( !value ) {
        this.infoData = {};
      }

    },

    // 搜索获取数据
    searchGoods(e) {
      e.preventDefault();
      this.form.validateFields((err, values) => {
        if (!err) {
          const params = {
            refund_id:this.$route.query.refund_id,
            search: this.form.getFieldValue('search'),
          };

          orderApi.searchrefundproduct(params)
                  .then(res => {
                    // console.log(res.data.data, 'data----');
                    if (res.data.status == 200) {
                      this.infoData = {...res.data.data};
                      this.imgUrl = JSON.parse(this.infoData.img)[0].url
                    } else {
                      this.infoData = {};
                    }
                  })
                  .catch(err => console.log(err))
            }
      });
    },

    // 添加到退货单
    addToReturnableList(data) {
       const { refund_id } = this.$route.query;
      const { pid, in_price, client_id } = data;
      const shop_id = this.$cookie.get('shop_id');
      const creator_id = this.$cookie.get('userid');
      const params = {
          refund_id,
          product_id: pid,
          purchase_price: in_price,
          supplier_id: client_id,
          remark: this.remark,
          creator_id,
          shop_id,
        }

        console.log('params--', params)
       orderApi.addrefunddetail(params)
          .then(res => {
            if( res.data.status == 200 ) {
              this.$message.success('添加成功');
                this.infoData = {};
                this.form.setFieldsValue({'search': ''});
            }else{
              console.log('错误了')
            }
            
          })
          .catch(err => console.log(err))
    }

  }
}
</script>

<style lang="less">
  .returnable-goods {
    padding: 20px;
    .supplier_name{
      padding-left: 20px;
      font-weight: 800;
    }
    .goods-info {
      margin-left: 40px;
      .goods-info-item {
        display: flex;
        line-height: 50px;
        > span:nth-child(1) {
          display: inline-block;
          width: 100px;
          margin-right: 20px;
          text-align: right;
          font-weight: bold;
        }

        img{
        width: 120px;
      }
        ul {
          padding-left: 100px;
        }
        .isRed {
          color: red;
        }
        .isGreen {
          color: rgb(64, 224, 64);
        }

        
      }
      .standards-values {
        display: block;
        line-height: 30px;
        .info-label {
          margin-right: 30px;
        }
      }
      .goods-btn {
        button {
          width: 300px;
          margin: 40px 100px;
        }
      }
    }
}
</style>