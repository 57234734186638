<!--
 * @Descripttion: *
 * @Author: wjj
 * @LastEditors: *
 * @LastEditTime: 2023-01-14 15:14:45
-->
<template>
  <div class="submenu">
    <!-- <p class="submenu-title">{{submenuTitle}}</p> -->
    <a-menu 
      :default-selected-keys="['1']"
      mode="inline" 
      :selected-keys="[current]"
      style="width: 100%;"
      @click="handleClick"
    >
      <template v-for="item in submenuList">
        <a-menu-item v-if="!item.children" :key="item.key">
          <span style="font-size: 13px;">{{ item.title }}</span>
        </a-menu-item>
        <a-menu-item-group :title="item.title "  v-else :key="item.key" style="font-size: 13px;">
          <template v-for="child in item.children">
            <a-menu-item  :key="child.key">
              <span style="font-size: 13px;">{{ child.title }}</span>
            </a-menu-item>
          </template>
      </a-menu-item-group>
      </template>
    </a-menu>
  </div>
</template>

<script>
export default {
  name: 'Sunmenu',
  props: {
    submenuTitle: {
      type: String,
      default: '设置',
    },
    submenuList: {
      type: Array,
      required: true,
    },
    current: {
      type: String,
      default: '1',
    }
  },
  data() {
    return {

    };
  },
  methods: {
    handleClick(e) {
      console.log('e.key ', e.key);
      this.$emit('initBreadcrumb', false);
      this.$emit('setBreadcrumb', e.key);
    }
  },
};
</script>

<style lang="less" scoped>
@import "./../assets/less/config.less";

  .submenu {
    width: 160px;
    .submenu-title {
      padding-top: 20px;
      padding-left: 20px;
    }

    :global(.ant-menu .ant-menu-item-selected) {
      color: @themeColor;
    }
    :global(.ant-menu-inline .ant-menu-item::after) {
      border-right: 3px solid @themeColor !important;
    }
  }
</style>