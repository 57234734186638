/*
 * @Descripttion: *
 * @Author: wjj
 * @LastEditors: *
 * @LastEditTime: 2024-10-17 15:45:43
 */

import axios from 'axios'
import Vue from 'vue'

import { message } from 'ant-design-vue';
import publicConst from "@/utils/public_const";

const request = axios.create({
  baseURL: publicConst.proxyPath,
  timeout: 3 * 1000
})
// 请求拦截器
request.interceptors.request.use(config => {
  //发请求前做的一些处理
  config.data = {
    ...config.data,
    creator_id:Vue.prototype.$cookie.get('userid'),
    shop_id: Vue.prototype.$cookie.get('shop_id')
  };
  config.headers = {
    'Content-Type': 'multipart/form-data'
  }
  return config
}, error => {
  Promise.reject(error)
})

//响应拦截器
request.interceptors.response.use(response => {
  //接收到响应数据并成功后的一些共有的处理
  if (response.data.status != 200) {
    message.error(response.data.message)
  }
  return response
}, error => {
  if (error && error.response) {
    switch (error.response.status) {
      case 400:
        error.message = '错误请求'
        break;
      case 401:
        error.message = '未授权，请重新登录'
        break;
      case 403:
        error.message = '拒绝访问'
        break;
      case 404:
        error.message = '请求错误,未找到该资源'
        window.location.href = "/NotFound"
        break;
      case 405:
        error.message = '请求方法未允许'
        break;
      case 408:
        error.message = '请求超时'
        break;
      case 500:
        error.message = '服务器端出错'
        break;
      case 501:
        error.message = '网络未实现'
        break;
      case 502:
        error.message = '网络错误'
        break;
      case 503:
        error.message = '服务不可用'
        break;
      case 504:
        error.message = '网络超时'
        break;
      case 505:
        error.message = 'http版本不支持该请求'
        break;
      default:
        error.message = `连接错误${error.response.status}`
    }
  } else {
    // 超时处理
    if (JSON.stringify(error).includes('timeout')) {
      message.error('服务器响应超时，请刷新当前页')
    }
    error.message = '连接服务器失败'
  }

  message.error(error.message)
  return Promise.resolve(error.response)
})
export default request