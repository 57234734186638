<!--
 * @Descripttion: *
 * @Author: wjj
 * @LastEditors: *
 * @LastEditTime: 2023-01-14 15:20:38
-->
<template>
  <div>
    <router-view></router-view>
  </div>
</template>
<script>
export default {
  name: 'user'
}
</script>