import request from "@/utils/request.js";
import Vue from 'vue'

// 委托方列表接口
function getclientlist(data){
  return request({
      url:  "/shop/clientlist",
      method: 'post',
      data
    })
}

// 新增委托方接口
function addclient(data){
  return request({
      url:  "/shop/addclient",
      method: 'post',
      data
    })
}


// 编辑委托方接口
function clientedit(data){
  return request({
      url:  "/shop/clientedit",
      method: 'post',
      data
    })
}
// 删除委托方接口
function clientdelete(data){
  return request({
      url:  "/shop/clientdelete",
      method: 'post',
      data
    })
}

// 获取角色列表
function userrolelist(data){
  return request({
      url:  "/user/rolelist",
      method: 'post',
      data
    })
}

function editshop(data){
    return request({
        url:  "/shop/editshop",
        method: 'post',
        data
      })
  }
  function addshop(data){
    return request({
        url:  "/shop/addshop",
        method: 'post',
        data
      })
  }
  function listshop(data){
    return request({
        url:  "/shop/listshop",
        method: 'post',
        data
      })
  }

  // 删除店铺
  function deleteshop(data){
    return request({
        url:  "/shop/deleteshop",
        method: 'post',
        data
      })
  }

  

  function listprint(data){
    return request({
        url:  "/prints/listprint",
        method: 'post',
        data
      })
  }
  function printsendapi(data){
    return request({
        url:  "/prints/printsendapi",
        method: 'post',
        data
      })
  }
  function deleteprint(data){
    return request({
        url:  "/prints/deleteprint",
        method: 'post',
        data
      })
  }
  function addprint(data){
    return request({
        url:  "/prints/addprint",
        method: 'post',
        data
      })
  }
  function editprint(data){
    return request({
        url:  "/prints/editprint",
        method: 'post',
        data
      })
  }
  function insertuser(data){
    return request({
        url:  "/user/insertuser",
        method: 'post',
        data
      })
  }
  function edituserinfo(data){
    return request({
        url:  "/user/edituserinfo",
        method: 'post',
        data
      })
  }
  function delclient(data){
    return request({
        url:  "/user/delclient",
        method: 'post',
        data
      })
  }
  function editclientname(data){
    return request({
        url:  "/user/editclientname",
        method: 'post',
        data
      })
  }
  function addreceiverclient(data){
    return request({
        url:  "/user/addreceiverclient",
        method: 'post',
        data
      })
  }
  function userinfolist(data){
    return request({
        url:  "/user/userinfolist",
        method: 'post',
        data
      })
  }
  function deleteuser(data){
    return request({
        url:  "/user/deleteuser",
        method: 'post',
        data
      })
  }

  // 登录
  function login(data){
    return request({
        url:  "/user/login",
        method: 'post',
        data
      })
  }

  // 修改密码
  function changepwd(data){
    return request({
        url:  "/user/changepwd",
        method: 'post',
        data
      })
  }

  

  export const userApi = {
    deleteuser,
    userinfolist,
    addreceiverclient,
    editclientname,
    delclient,
    insertuser,
    edituserinfo,
    editprint,
    addprint,
    deleteprint,
    printsendapi,
    listprint,
    editshop,
    listshop,
    addshop,
    deleteshop,
    getclientlist,
    addclient,
    clientedit,
    clientdelete,
    userrolelist,
    login,
    changepwd

  }