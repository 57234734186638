
<template>
    <div class="comon-list purchase-list">
      
      <div class="comon-list purchase-list">
         <div class="supplier_name">委托方： {{ supplier_name }}</div>
         <div class="comon-check-form purchase-check-form">
            <a-form class="searchArea" :form="form" layout="inline" :label-col="{ span: 5 }"
              :wrapper-col="{ span: 18 }">
              <a-form-item label="商品信息" style="width: 480px">
                <a-input 
                  v-decorator="['name']" 
                  placeholder="请输入商品名称、平台编码CODE、内部编码SKU" 
                  allow-clear
                  />
                  
              </a-form-item>

              <a-form-item label="结算状态" style="width: 240px"
                :label-col="{ span: 7 }"
                 :wrapper-col="{ span: 15 }"
                >
                  <a-select
                    allowClear
                    v-decorator="['is_settle']" 
                    placeholder="请选择"
                    option-filter-prop="children"
                  >
                    <a-select-option v-for="(item, index) in invoicesStatusList" :key="index" :value="item.id">{{ item.value }}</a-select-option>
                  </a-select>
              </a-form-item>

                <a-form-item label="商品状态" style="width: 240px"
                  :label-col="{ span: 7 }"
                 :wrapper-col="{ span: 15 }"
                >
                  <a-select
                    allowClear
                    v-decorator="['on_status']" 
                    placeholder="请选择"
                    option-filter-prop="children"
                  >
                    <a-select-option v-for="(item, index) in goodsStatusList" :key="index" :value="item.id">{{ item.value }}</a-select-option>
                  </a-select>
              </a-form-item>

              <a-form-item  style="width: 440px; margin-top: 20px;">
                <a-button type="primary" @click="searchData"> 搜索 </a-button>
                <a-button  style="margin-left: 20px;" class="resetBtn" @click="resetSearch"> 重置 </a-button>
                <a-button style="margin-left: 20px;" @click="() => $router.back()">返回列表</a-button>
              </a-form-item>
            </a-form>
         </div>
      </div>
      <div style="height: 500px;  margin-top: 30px">
         <a-table 
          :columns="columns" 
          :scroll={x:1300,y:500} 
          :data-source="dataSource" 
          :pagination="pagination" 
          :loading="loading"
          @change="onChangTable"
          >
          <template slot="operation" slot-scope="text, record" > 
            <a  v-if="record.is_settle == 0" href="javascript:;" class="operate" @click="onEdit(record, 'settlement')">  添加</a>
            <span v-else>无操作</span>
          </template>
        </a-table>
      </div>

      <a-modal :title="editTitle" :visible="moduleVisible" okText="提交" cancelText="取消" @ok="handleOk"
        @cancel="moduleVisible = false">
        <a-form style="width:450px" class="settlement-form" :form="editForm" :label-col="{ span: 6 }"
          :wrapper-col="{ span: 18 }">
            <a-form-item label="商品名称" >
              <a-input 
                disabled
                v-decorator="['title', { initialValue: activeItem.title }]" 
                placeholder="请输入" 
              />
            </a-form-item>
   
            <a-form-item label="内部编码">
              <a-input 
                disabled
                v-decorator="['sku', { initialValue: activeItem.sku }]" 
                placeholder="请输入" 
              />
            </a-form-item>
            <a-form-item label="结算金额">
              <a-input 
                type="number"
                v-decorator="['sellte_price', { initialValue: activeItem.in_price }]" 
                placeholder="请输入" 
              />
            </a-form-item>

             <a-form-item label="备注" >
              <a-textarea 
                v-decorator="['remark', { initialValue: activeItem.remark }]"
                placeholder="请输入"    
                style="width: 320px;" 
                :rows="4" :max-length=150 
              />
            </a-form-item>

          </a-form>
        </a-modal>

    </div>
</template>
  
  <script>
  import { goodsApi } from "@/api/good";
  import { h } from 'vue';
  import {  message } from "ant-design-vue";
  
 export default {
    name: "InvoicesGoods",
    data() {
      return {
          moduleVisible: false, // 是否打开编辑框
          editTitle: '商品到结算单',
          editForm: this.$form.createForm(this, { name: "validate_edit"}),
          form: this.$form.createForm(this, { name: 'addInvoicesGoodsForm' }),
          activeItem: {},
          supplier_name: '',
          dataSource: [],
          loading: false,
          pagination: {
              total: 0,
              current: 1,
              pageSize: 50
          },
          // 结算状态：0未结算 1结算中 2已结算
          invoicesStatusList: [ 
            {
              key: 'invoice_0',
              id: '0',
              value: '未结算'
            },
            {
              key: 'invoice_1',
              id: '1',
              value: '结算中'
            },
            {
              key: 'invoice_2',
              id: '2',
              value: '已结算'
            }
          ],
          // 商品状态: 0未售出 、1已售罄、2已归还 
          goodsStatusList: [ 
            {
              key: 'goods_0',
              id: '0',
              value: '未售出'
            },
            {
              key: 'goods_1',
              id: '1',
              value: '已售罄'
            },
            {
              key: 'goods_2',
              id: '2',
              value: '已归还'
            }
          ],
          columns: [{
            title: "内部编码",
            width: '140px',
            fixed: 'left',
            dataIndex: "sku",
            key: 'sku',
            scopedSlots: {
              customRender: "sku",
            }
          },
          {
            title: "商品名称：",
            dataIndex: "title",
            key: 'title',
            width: '150px',
            scopedSlots: {
              customRender: "title",
            },
          },
          {
              title: "商品图片",
              dataIndex: "img",
              key: 'img',
              width: '100px',
              scopedSlots: {
                  customRender: "img",
              },
              customRender: (value, record) => {
                  if (value && value.includes('[')) {
                  let obj = JSON.parse(value);
                  const array = [];

                  obj.map((img, index) => {
                      if (img && index === 0) {
                      array.push(
                          h(
                          "img", {
                          style: {
                              width: "70px",
                              height: "70px",
                              marginLeft: "5px",
                          },
                          attrs: {
                              src: img.url,
                          },
                          on: {
                              click: () => this.picPreview(img.url)
                          }
                          },
                          () => item
                          )
                      );

                      }

                  });
                  return h("div", array);

                  } else {
                  return '--'
                  }
              },
          },
          {
              title: "代发价",
              dataIndex: "in_price",
              key: 'in_price',
              width: '120px',
              scopedSlots: {
                  customRender: "in_price",
              },
          },
          {
                title: "售价",
                dataIndex: "sale_price",
                key: 'sellte_price',
                width: '120px',
                scopedSlots: {
                    customRender: "sale_price",
                },
            },
            {
                title: "商品状态",
                dataIndex: "on_status",
                key: 'on_status',
                width: '80px',
                scopedSlots: {
                customRender: "on_status",
                },
                customRender: (value, record) => {
                // 1已上架、2已下架 、3代销中、4已售出
                if (value == 1) {
                    return '已上架'
                }
                if (value == 2) {
                    return '已下架'
                }
                if (value == 3) {
                    return '代销中'
                }
                if (value == 4) {
                    return '已售出'
                }
                return '--'
                }
            },
          {
            title: "结算状态",
            dataIndex: "is_settle",
            width: '80px',
            scopedSlots: {
              customRender: "is_settle",
            },
            customRender: (value, record) => {
              // 结算状态：0未结算 1结算中 2已结算
              if (value == 0) {
                return '未结算'
              }
              if (value == 1) {
                return '结算中'
              }
              if (value == 2) {
                return '已结算'
              }
  
              return '--'
            }
          },
          {
            title: "操作",
            dataIndex: "operation",
            key: 'operation',
            width: '140px',
            fixed: 'right',
            scopedSlots: {
              customRender: "operation",
            } 
          },
      ],
            
          
      }
    },
    mounted() {
      const { supplier_name } = this.$route.query;
      this.supplier_name = supplier_name;
      this.searchData();
    },
    methods: {
      // 搜索获取数据
      searchData() {
        const { supplier_id, shop_id } = this.$route.query;
        this.form.validateFields((err, values) => {
          if (!err) {
            const params = {
                ...values,
                shop_id,
                supplier_id,
                page: this.pagination.current,
                pageSize: this.pagination.pageSize,
              };
              console.log('params', params)
            goodsApi.listcansettlement(params)
                .then((res) => {
                      console.log(res, "resres");
                      const list = res.data.data.list || res.data.data.data
                      if (res.data.status == 200 ) {
    
                          this.dataSource = []
                          this.pagination.total = +res.data.data.total;
                          this.dataSource = list.map((good, index) => { 
                                  return { ...good, sorts: index, key: good.pid } 
                              })
                      } else {
                          message.warning('暂无更多数据信息！')
                        
                      }
                  })
                  .finally((res) => {
                      this.loading = false;
                  });
            }
        });
      },

      // 重置
      resetSearch() {
        this.form.resetFields();
        this.searchData();
      },

      // 操作
      onEdit(itemData, type) {
        if(type === 'settlement') {
          this.moduleVisible = true;
          this.activeItem = { ...itemData };
        } 
      },
      
      // 提交结算
      handleOk() {
        const { pid, creator_id } = this.activeItem;
        const { settlement_id } = this.$route.query;
        this.editForm.validateFields((err, values) => {
          const params = {
            ...values,
            settlement_id, // 结算单id
            creator_id, // 创建人id
            pid, // 商品id
          };

          goodsApi.addsettlementdetail(params)
            .then(res => {
              if(res.data.status == 200) {
                this.moduleVisible = false;
                 message.success('商品提交结算成功');
                 this.searchData();
              }
            })

        })
      },
    //翻页
      onChangTable(page) {
          this.pagination.current = page.current;
          this.pagination.pageSize = page.pageSize;
          this.searchData()
      },
  }
};
  </script>
  
  <style lang="less" scoped>
   @import "./../../assets/less/reset.less";
    @import "./../../assets/less/config.less";
    .supplier_name{
      font-weight: 800;
      margin-bottom: 20px;
    }
    .operate{
      padding-right: 5px;
    }
  
  .settlement-form {
    padding: 10px 20px !important;
  }
   
  </style>