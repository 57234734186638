<template>
    <div class="userManage">
      <router-view></router-view>
    </div>
  </template>
  
  
  <script>
  export default {
    name: "userManage",
    data() {
      return {
        
      };
    },
  
  };
  </script>