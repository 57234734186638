<template>
  <div class="publish">
    <a-form id="validate-other" :form="form">
      <a-form-item label="用户名称" v-bind="formItemInputLayout">
        <a-input placeholder="请输入" v-decorator="[
          'username',
          {
            initialValue: editData.username,
            rules: [{ required: true, message: '请输入' }]
          },
        ]" />
      </a-form-item>
      <a-form-item label="手机号码" has-feedback v-bind="formItemInputLayout">
        <a-input placeholder="请输入" v-decorator="[
          'tel',
          {
            initialValue: editData.tel,
            rules: [{ required: true, message: '请输入' }]
          },
        ]" />
      </a-form-item>
      <a-form-item label="角色" v-bind="formItemInputLayout">
        <a-select v-decorator="[
          'role',
          {
            initialValue: editData.role,
            rules: [
              { required: true, message: '请选择或输入搜索' },
            ],
          },
        ]" placeholder="请选择或输入搜索" allowClear showSearch>

          <a-select-option v-for="item in cateList" :key="item.role_id" :value="item.role_id">{{ item.name }} </a-select-option>

        </a-select>
      </a-form-item>
      <a-form-item  v-if="role_id*1 ===1 " label="关联店铺" v-bind="formItemInputLayout">
        <a-select v-decorator="[
          'shop',
          {
            initialValue: editData.shop_name,
            rules: [
              { required: true, message: '请选择或输入搜索' },
            ],
          },
        ]" placeholder="请选择或输入搜索" allowClear showSearch>

          <a-select-option v-for="item in shopList" :key="item.id" :value="item.id">{{ item.name }} </a-select-option>

        </a-select>
      </a-form-item>
      <a-form-item label="密码" has-feedback v-bind="formItemInputLayout">
        <template v-if="editData.tel">
          <a-input placeholder="请输入" v-decorator="[
            'password',
            {
              initialValue: editData.password,
            },
          ]" />
          </template>
        <template v-else>
          <a-input placeholder="请输入" v-decorator="[
          'password',
          {
            initialValue: editData.password,
            rules: [{ required: true, message: '请输入' }]
          },
        ]" />
        </template>
      </a-form-item>
      <a-form-item v-if="editData.id" label="状态" v-bind="formItemInputLayout">

        <a-radio-group v-decorator="['status', {
          initialValue: editData.status,
          rules: [{ required: true, message: '请选择' }]
        }]">
          <a-radio value="1">
            正常
          </a-radio>
          <a-radio value="2">
            禁用
          </a-radio>
        </a-radio-group>

      </a-form-item>

      <a-form-item :wrapper-col="{ span: 12, offset: 4 }">

        <a-button type="primary" @click="handleSubmit" class="submitBtn footerBtn"> {{ submitText }}</a-button>
        <a-button @click="handleReset" class="footerBtn"> 重置 </a-button>
        <a-button @click="goBackList" class="footerBtn"> 返回列表 </a-button>

      </a-form-item>
    </a-form>
  </div>
</template>
  
<script>
import { userApi } from '@/api/user';
import { message } from 'ant-design-vue';


export default {
  name: "publish",
  props: {
  },
  data() {
    return {
      formItemLayout: {
        labelCol: { span: 3 },
        wrapperCol: { span: 14 },
      },
      formItemInputLayout: {
        labelCol: { span: 3 },
        wrapperCol: { span: 12 },
      },
      role_id: this.$cookie.get('role'),// 用户角色
      submitText: '新建用户',
      editData: {},
      cateList: [],
      shopList: [],
    }
  },
  components: {
  },
  beforeCreate() {
    this.form = this.$form.createForm(this, { name: "validate_other" });


  },
  created() {


  },
  mounted() {
    this.editData = this.$route.query.data || {}
    if (this.$route.query.data) {
      this.submitText = '保存编辑'
    }

    this.getRolelist();
    this.getShoplist();

  },
  methods: {
    // 获取角色列表
    getRolelist() {
      const creator_id= this.$cookie.get('userid');
      userApi.userrolelist({creator_id})
        .then(res => {
         this.cateList = res.data.data;
        })
        .catch(err => {
          console.log(err)
        })
    },

     // 获取关联店铺列表
    getShoplist() {
      const creator_id= this.$cookie.get('userid');
      let params = {
            page: 1,
            pageSize: 50,
        };
      userApi.listshop(params)
        .then(res => {
         this.shopList = res.data.data.data;
        })
        .catch(err => {
          console.log(err)
        })
    },




    //返回
    goBackList() {
      this.$router.push({ path: '/userManage/userManageList' })
    },

    // 重置
    handleReset(e) {
      this.form.resetFields()
    },

    // 提交
    handleSubmit(e) {
      e.preventDefault();
      this.form.validateFields((err, values) => {
        if (!err) {
          console.log("Received values of form: ", values);
          let data = {
            ...values,
          }
          console.log('data---', data)
          let api = "insertuser"
          if(this.editData.id){//编辑
            data.uid = this.editData.id
            api = "edituserinfo"

          }//编辑&新建
            userApi[api](data)
            .then((res) => {
              console.log(res, "resres");
              if (res.data.status == 200) {
                message.success('成功')
                this.goBackList()

              } else {
                message.error('失败')


              }
            })
          
          
          
        } else {
          console.log(err)
        }
      });
    },
  },
};
</script>
<style lang="less" scoped>
#components-form-demo-validate-other .dropbox {
  height: 180px;
  line-height: 1.5;
}

.footerBtn {
  width: 160px;
  height: 52px;
  margin-top: 30px;
  margin-left: 34px;
}

.submitBtn {
  margin-left: -44px;
}

.publish {
  padding: 0 30px;
  width: 1200px;

  #desc {
    resize: none;
    width: 600px;
    height: 60px;
    line-height: 20px;
    padding: 5px 5px;
    overflow-y: scroll;
  }

  :global(.ant-form .ant-form-item) {
    margin-bottom: 10px;
  }
}
</style>
  