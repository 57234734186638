
<template>
    <div class="comon-list purchase-list">
      <div class="comon-publish ">
        <a-button type="primary" @click="$router.push({ path: '/userManage/newUser' })">
          新建用户
        </a-button>
      </div>
      
      <div style="height: 500px;  margin-top: 30px">
        <a-table 
        :columns="columns" 
        :scroll={x:700,y:500} 
        :data-source="data" 
        :pagination="pagination" 
        :loading="loading"
        @change="onChangTable" 
        >
        <template slot="shop_name" >
          <!-- <span >
            {{shop_name}}
             <a-tag v-if="shop_name" :color="data.shop_type == 2 ? 'red' : 'green' "> {{ data.shop_type == 2?'虚拟': '真实' }}</a-tag>
          </span> -->

        </template>
          <template slot="operation" slot-scope="text, record">
            <a-popconfirm v-if="data.length" title=" 确定删除此用户吗？
            " ok-text="确定" cancel-text="取消" @confirm="() => onEditStatus(record,'delet')">
            <a href="javascript:;" class="operate">
             删除</a>
          </a-popconfirm>
          <a href="javascript:;"  @click="() => onEditStatus(record,'edit')" class="operate">
             编辑</a>
          </template>
        </a-table>
      </div>
  
    </div>
  </template>
  
  
  
  <script>
    import {
      h
    } from "vue";
  
    import publicConst from "@/utils/public_const";
    import axios from "axios";
    import {
      message
    } from "ant-design-vue";
import { userApi } from "@/api/user";
  
    export default {
      name: "PurchaseList",
      data() {
        return {
          data: [],
          columns:[{
            title: "编号",
            width: '70px',
            fixed: 'left',
            dataIndex: "id",
            scopedSlots: {
              customRender: "id",
            },
  
          },
          {
            title: "用户名",
            dataIndex: "username",
            width: '150px',
            scopedSlots: {
              customRender: "username",
            },
          },
          {
            title: "手机号",
            dataIndex: "tel",
            width: '90px',
            scopedSlots: {
              customRender: "tel",
            },
          },
          {
            title: "角色",
            dataIndex: "role_name",
            width: '120px',
            scopedSlots: {
              customRender: "role_name",
            },
          },
          {
            title: "店铺名称", // 2是虚拟,1是真实
            dataIndex: "shop_name",
            width: '120px',
            scopedSlots: {
             customRender: "shop_name",
            },
            customRender: (value, record) => {
              console.log('record', record)
              if (value && record.shop_type*1 === 1) {
                 return (
                  <span>
                    {value}
                    <a-tag color="green"> 真实</a-tag>
                  </span>
                );
              } else if (value && record.shop_type*1 === 2) {
                return (
                  <span>
                    {value} 
                    <a-tag color="red"> 虚拟</a-tag>
                  </span>
                );
              }
             
             }
          },
          {
            title: "状态",
            dataIndex: "status",
            width: '200px',
            scopedSlots: {
              customRender: "status",
            },
            customRender: (value,record)=>{
              if(value == 2){
                return '禁用'
              }
              if(value == 1){
                return '正常'
              }
              if(value == 3){
                return '删除'
              }
              return '--'
            }
          },
          {
            title: "创建时间",
            dataIndex: "create_date",
            width: '200px',
            scopedSlots: {
              customRender: "create_date",
            },
          },
          {
            title: "操作",
            dataIndex: "operation",
            width: '150px',
            fixed: 'right',
            scopedSlots: {
              customRender: "operation",
            },
          },
        ],
          loading: false,
          pagination: {
            total: 0,
            current: 1,
            pageSize:50
          },
  
  
        };
      },
      
      beforeCreate() {
        if(this.$cookie.get('username')){
         
        }else{
          message.warning('请登录')
          this.$router.push({path:'/login'})
        }
      },
      mounted() {
        this.searchPurchase();
        message.config({
          top: `100px`,
          duration: 8,
        });
        
      },
      computed: {
          
      },
      methods: {
        //获取表格数据
        getTable(data) {
          userApi.userinfolist(data)
            .then((res) => {
              // console.log(res, "resres");
              if (res.data.status == 200 && res.data.data.data.length > 0) {
                this.data = []
                this.pagination.total = +res.data.data.total;
                
                res.data.data.data.map(good=>{
                   this.data.push({...good,key:good.id})

                   
                })
              } else {
                this.data = [];
                this.pagination.total = 0;
              }

            })
            .finally((res) => {
              
            });
        },
        //翻页
        onChangTable(page) {
          this.pagination.current = page.current;
          this.searchPurchase()
        },
        //搜索
        searchPurchase() {
            let params = {
              page: this.pagination.current,
              pageSize:this.pagination.pageSize,
            creator_id: this.$cookie.get('userid'),
            shop_id:this.$cookie.get('userid')

            };
            this.getTable(params);
        },
        //改变状态
        onEditStatus(record,type) {
          switch (type){
            case 'delet':
            let data = {
              uid:record.id,
            creator_id: this.$cookie.get('userid'),
            shop_id:this.$cookie.get('userid')

            };
           userApi.deleteuser(data)
              .then((res) => {
                // console.log(res, "resres");
                if (res.data.status == 200 ) {
                  message.success(`用户删除成功`)
                  
                } else {
                  message.error(`用户删除失败`)
                }
              })
              .finally((res) => {
                this.searchPurchase()
                
              });
              break;
            case 'edit':
              this.$router.push({ path: '/userManage/newUser',query:{data:{...record}} })
              break;
            default:
              break;    
          }
          
        
        },
        
  
        handleOk(){
          
        },
        
  
      },
    };
  </script>
  
  <style lang="less" scoped>
   @import "./../../assets/less/reset.less";
  @import "./../../assets/less/config.less";
  .operate{
    padding-right: 5px;
  }
  
  
   
  </style>