<template>
    <div class="purchase">
      <router-view></router-view>
    </div>
  </template>
  
  
  <script>
  export default {
    name: "purchase",
    data() {
      return {
        
      };
    },
  
  };
  </script>
  <style lang="less" scoped>
  .purchase {
   height: 100%;
     }
     </style>