var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"mart"},[_c('a-form',_vm._b({attrs:{"id":"mart-form","form":_vm.form}},'a-form',_vm.formItemLayout,false),[_c('a-form-item',[_c('h2',[_vm._v("设置直播计划信息")])]),_c('a-form-item',_vm._b({attrs:{"label":"直播计划名称"}},'a-form-item',_vm.formItemInputLayout,false),[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
        'name',
        {
          rules: [{ required: true, message: '请输入' }]
        },
      ]),expression:"[\n        'name',\n        {\n          rules: [{ required: true, message: '请输入' }]\n        },\n      ]"}],attrs:{"maxLength":30,"placeholder":"请输入"}},[_c('a-tooltip',{attrs:{"slot":"suffix","title":"计划名称不能超过30个字符"},slot:"suffix"},[_c('a-icon',{staticStyle:{"color":"rgba(0,0,0,.45)"},attrs:{"type":"info-circle"}})],1)],1)],1),_c('a-form-item',{attrs:{"label":"直播间名称","has-feedback":""}},[_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:([
        'place_id',

        {
          initialValue: '',
          rules: [
            { required: true, message: '请选择或输入搜索' },
          ],
        },
      ]),expression:"[\n        'place_id',\n\n        {\n          initialValue: '',\n          rules: [\n            { required: true, message: '请选择或输入搜索' },\n          ],\n        },\n      ]"}],attrs:{"placeholder":"请选择或输入搜索","allowClear":"","showSearch":""}},_vm._l((_vm.liveRoomList),function(item){return _c('a-select-option',{key:item.live_room_id,attrs:{"value":item.live_room_id}},[_vm._v(_vm._s(item.live_room_name)+" ")])}),1)],1),_c('a-form-item',{attrs:{"label":"预展时间","has-feedback":""}},[_c('a-range-picker',{directives:[{name:"decorator",rawName:"v-decorator",value:([
        'rangeTime', {
          rules: [{ type: 'array', required: true, message: '请选择时间!' }]
        }]),expression:"[\n        'rangeTime', {\n          rules: [{ type: 'array', required: true, message: '请选择时间!' }]\n        }]"}],attrs:{"show-time":"","format":"YYYY-MM-DD HH:mm","placeholder":['开始时间', '结束时间'],"locale":_vm.locale}})],1),_c('a-form-item',_vm._b({attrs:{"label":"主播名称"}},'a-form-item',_vm.formItemInputLayout,false),[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
        'anchor',
        {
          rules: [{ required: true, message: '请输入' }]
        },
      ]),expression:"[\n        'anchor',\n        {\n          rules: [{ required: true, message: '请输入' }]\n        },\n      ]"}],attrs:{"placeholder":"请输入"}})],1),_c('a-form-item',{attrs:{"wrapper-col":{ span: 12, offset: 4 }}},[_c('a-button',{staticClass:"submitBtn footerBtn",attrs:{"type":"primary"},on:{"click":_vm.handleSubmit}},[_vm._v(" 保存计划")]),_c('a-button',{staticClass:"footerBtn",on:{"click":_vm.handleReset}},[_vm._v(" 重置 ")]),_c('a-button',{staticClass:"footerBtn",on:{"click":_vm.goBackList}},[_vm._v(" 返回列表 ")])],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }