/*
 * @Descripttion: *
 * @Author: wjj
 * @LastEditors: *
 * @LastEditTime: 2024-10-19 20:30:55
 */
import Vue from 'vue'
import VueRouter from 'vue-router'
import Login from '@/views/Login'
import Layout from '@/views/Layout'
import Goods from '@/views/Goods'
import GoodsList from '@/views/Goods/GoodsList'
import GoodsType from '@/views/Goods/GoodsType'
import Publish from '@/views/Goods/Publish'
import OperDiar from '@/views/Goods/OperDiar'
import Tool from '@/views/Goods/GoodsTools'
import Retail from '@/views/Retail'
import RetailGoods from '@/views/Retail/RetailGoods'
import MartList from '@/views/Retail/MartList'
import AddNewMart from '@/views/Retail/AddNewMart'
import MartDetail from '@/views/Retail/MartDetail'
import Purchase from '@/views/Purchase'
import PurchaseList from '@/views/Purchase/PurchaseList'
import PurchaseGoodsList from '@/views/Purchase/PurchaseGoodsList'
import ReturnableList from '@/views/Purchase/ReturnableList'
import ReturnableGoodsList from '@/views/Purchase/ReturnableGoodsList'
import PurchaseGoods from '@/views/Purchase/PurchaseGoods'
import ReturnableGood from '@/views/Purchase/ReturnableGood'
import NewReturn from '@/views/Purchase/NewReturn'
import SupplyOrder from '@/views/SupplyOrder'
import SupplyOrderList from '@/views/SupplyOrder/SupplyOrderList'
import SupplyOrderGoodsList from '@/views/SupplyOrder/SupplyOrderGoodsList'
import SupplyReturnableList from '@/views/SupplyOrder/SupplyReturnableList'
import SupplyReturnableGoodsList from '@/views/SupplyOrder/SupplyReturnableGoodsList'
import LiveRoom from '@/views/LiveRoom'
import NewLiveRoom from '@/views/LiveRoom/NewLiveRoom'
import LiveRoomList from '@/views/LiveRoom/LiveRoomList'
import UserManage from '@/views/UserManage'
import NewUser from '@/views/UserManage/NewUser'
import UserManageList from '@/views/UserManage/UserManageList'
import ShopList from '@/views/UserManage/ShopList'
import ClientManageList from '@/views/UserManage/ClientManageList'
// import ReceiverManageList from '@/views/UserManage/ReceiverManageList'
import GoodsSold from '@/views/Invoices/GoodsSold'
import InvoicesList from '@/views/Invoices/InvoicesList'
import InvoicesDetal from '@/views/Invoices/InvoicesDetal'
import Invoices from '@/views/Invoices'
import InvoicesGoods from '@/views/Invoices/InvoicesGoods'
import PrintList from '@/views/Prints/PrintList'
import PrintDetal from '@/views/Prints/PrintDetal'



import User from '@/views/User'
import Userinfo from '@/views/User/Userinfo'

Vue.use(VueRouter)

const routes = [
  {
    path: '/login',
    name: 'login',
    component: Login
  },
  {
    path: '/',
    component: Layout,
    children: [
      {
        path: '/goods',
        name: 'goods',
        component: Goods,
        meta: {
          title: '商品中心',
          home: '商品',
          module: '商品',
        },
        children: [
          {
            path: 'tool',
            name: 'tool',
            component: Tool,
            meta: {
              title: '商品工具',
              home: '商品中心',
              module: '商品中心',
            }
           },
          {
            path: 'publish',
            name: 'publish',
            component: Publish,
            meta: {
              title: '发布商品',
              home: '商品中心',
              module: '商品中心',
            }
           },
           {
            path: 'operDiar',
            name: 'operDiar',
            component: OperDiar,
            meta: {
              title: '商品操作日记',
              home: '商品中心',
              module: '商品中心',
            }
           },
          {
            path: 'list',
            name: 'list',
            component: GoodsList,
            meta: {
              title: '商品库',
              module: '商品中心',
              home: '商品中心',
            }
          },
          {
            path: 'type',
            name: 'type',
            component: GoodsType,
            meta: {
              title: '商品分类',
              module: '商品中心',
              home: '商品中心',
             }
           }
         ]
      },
      {
        path: '/retail',
        name: 'retail',
        component: Retail,
        children: [
         {
          path: 'retailGoods',
          name: 'retailGoods',
          component: RetailGoods,
          meta: {
            title: '网拍商品库',
            home: '网拍',
           }
         },
         {
          path: 'martList',
          name: 'martList',
          component: MartList,
          meta: {
            title: '拍场列表',
            home: '网拍',
           }
         },
         {
          path: 'addNewMart',
          name: 'addNewMart',
          component: AddNewMart,
          meta: {
            title: '新建拍场',
            home: '网拍',
           }
         },
         {
          path: 'martDetail',
          name: 'martDetail',
          component: MartDetail,
          meta: {
            title: '拍场详情',
            home: '网拍',
           }
         }
        ]
      },
      {
        path: '/user',
        name: 'user',
        component: User,
        children: [
         {
          path: 'userinfo',
          name: 'userinfo',
          component: Userinfo,
          meta: {
            title: '个人信息',
            home: '个人',
           }
         }
        ]
      },
      {
        path: '/purchase',
        name: 'purchase',
        component: Purchase,
        meta: {
          title: '采购单管理',
          home: '采购单',
          module: '采购单',
        },
        children: [
          {
            path: 'goodsSold',
            name: 'goodsSold',
            component: GoodsSold,
            meta: {
              title: '结算单商品池',
              home: '结算单管理',
              module: '结算单管理',
            }
           },
          {
            path: 'invoicesList',
            name: 'invoicesList',
            component: InvoicesList,
            meta: {
              title: '结算单列表',
              home: '结算单管理',
              module: '结算单管理',
            }
           },
          {
            path: 'invoicesDetal',
            name: 'invoicesDetal',
            component: InvoicesDetal,
            meta: {
              title: '结算单详情',
              home: '结算单管理',
              module: '结算单管理',
            }
           },
           {
            path: 'invoicesGoods',
            name: 'invoicesGoods',
            component: InvoicesGoods,
            meta: {
              title: '添加结算商品',
              home: '结算单管理',
              module: '结算单管理',
            }
           },
          {
            path: 'purchaseGoodsList',
            name: 'purchaseGoodsList',
            component: PurchaseGoodsList,
            meta: {
              title: '采购单商品列表',
              home: '采购单管理',
              module: '采购单管理',
            }
           },
           {
            path: 'PurchaseGoods',
            name: 'PurchaseGoods',
            component: PurchaseGoods,
            meta: {
              title: '添加采购商品页面',
              home: '采购单管理',
              module: '采购单管理',
            }
           },
          {
            path: 'purchaseList',
            name: 'purchaseList',
            component: PurchaseList,
            meta: {
              title: '采购单列表',
              home: '采购单管理',
              module: '采购单管理',
            }
           },
          {
            path: 'returnableList',
            name: 'returnableList',
            component: ReturnableList,
            meta: {
              title: '退货单列表',
              home: '采购单管理',
              module: '采购单管理',
            }
           },
           {
            path: 'newReturn',
            name: 'newReturn',
            component: NewReturn,
            meta: {
              title: '新建采购退货单',
              home: '采购单管理',
              module: '采购单管理',
            }
           },
          {
            path: 'returnableGoodsList',
            name: 'returnableGoodsList',
            component: ReturnableGoodsList,
            meta: {
              title: '采购退货单商品列表',
              home: '采购单管理',
              module: '采购单管理',
            }
           },
           {
            path: 'returnableGood',
            name: 'returnableGood',
            component: ReturnableGood,
            meta: {
              title: '添加退货商品页面',
              home: '采购单管理',
              module: '采购单管理',
            }
           },
         ]
      },
      /*{
        path: '/supplyOrder',
        name: 'supplyOrder',
        component: SupplyOrder,
        meta: {
          title: '供货单管理',
          home: '供货单',
          module: '供货单',
        },
        children: [
          
          {
            path: 'supplyreturnableGoodsList',
            name: 'supplyreturnableGoodsList',
            component: SupplyReturnableGoodsList,
            meta: {
              title: '供货退货单商品列表',
              home: '供货单管理',
              module: '供货单管理',
            }
           },
          {
            path: 'supplyOrderGoodsList',
            name: 'supplyOrderGoodsList',
            component: SupplyOrderGoodsList,
            meta: {
              title: '供货单商品列表',
              home: '供货单管理',
              module: '供货单管理',
            }
           },
          {
            path: 'supplyOrderList',
            name: 'supplyOrderList',
            component: SupplyOrderList,
            meta: {
              title: '供货单管理',
              home: '供货单管理',
              module: '供货单管理',
            }
           },
          {
            path: 'supplyreturnableList',
            name: 'supplyreturnableList',
            component: SupplyReturnableList,
            meta: {
              title: '退货单列表',
              home: '供货单管理',
              module: '供货单管理',
            }
           }
         ]
      },*/
      {
        path: '/liveRoom',
        name: 'liveRoom',
        component: LiveRoom,
        meta: {
          title: '直播间管理',
          home: '直播间',
          module: '直播间',
        },
        children: [
          {
            path: 'liveRoomList',
            name: 'liveRoomList',
            component: LiveRoomList,
            meta: {
              title: '直播间管理列表',
              home: '直播间管理',
              module: '直播间管理',
            }
           },
          {
            path: 'newLiveRoom',
            name: 'newLiveRoom',
            component: NewLiveRoom,
            meta: {
              title: '新建直播间',
              home: '直播间管理',
              module: '直播间管理',
            }
           },
         ]
      },{
        path: '/userManage',
        name: 'userManage',
        component: UserManage,
        meta: {
          title: '用户管理',
          home: '用户',
          module: '用户',
        },
        children: [
          {
            path: 'userManageList',
            name: 'userManageList',
            component: UserManageList,
            meta: {
              title: '用户管理列表',
              home: '用户管理',
              module: '用户管理',
            }
           },
           {
            path: 'shopList',
            name: 'shopList',
            component: ShopList,
            meta: {
              title: '店铺列表',
              home: '用户管理',
              module: '用户管理',
            }
           },
           {
            path: 'printList',
            name: 'printList',
            component: PrintList,
            meta: {
              title: '打印机列表',
              home: '用户管理',
              module: '用户管理',
            }
           },
           {
            path: 'printDetal',
            name: 'printDetal',
            component: PrintDetal,
            meta: {
              title: '打印机详情',
              home: '用户管理',
              module: '用户管理',
            }
           },
          {
            path: 'newUser',
            name: 'newUser',
            component: NewUser,
            meta: {
              title: '新建用户',
              home: '用户管理',
              module: '用户管理',
            }
           },
           {
              path: 'clientManageList',
              name: 'clientManageList',
              component: ClientManageList,
              meta: {
                title: '委托方列表',
                home: '委托方管理',
                module: '委托方管理',
              }
             },
          //  {
          //   path: 'receiverManageList',
          //   name: 'receiverManageList',
          //   component: ReceiverManageList,
          //   meta: {
          //     title: '对接人管理列表',
          //     home: '对接人管理',
          //     module: '对接人管理',
          //   }
          //  },
         ]
      }
    ]
  },
]

const router = new VueRouter({
  mode: 'hash',
  base:process.env.BASE_URL,
  routes
})

export default router
