<template>
    <div class="publish">
      <a-form
        id="validate-other"
        :form="form"
        v-bind="formItemLayout"
        
      >
      <a-form-item label="退货单名称" v-bind="formItemInputLayout">
          <a-input
            placeholder="请输入"
            v-decorator="[
              'name',
              { 
                rules: [{ required: true, message: '请输入' }] 
              },
            ]"
          />
        </a-form-item>
        <a-form-item label="委托方名称" has-feedback v-bind="formItemInputLayout">
          <a-select
            v-decorator="[
              'supplier_id',
              {
                rules: [
                  { required: true, message: '请选择或输入搜索' },
                ],
              },
            ]"
            placeholder="请选择或输入搜索"
            allowClear showSearch
          >
            
             <a-select-option  v-for="item in supplierList" :key="item.id" :value="item.id" >{{item.supplier_name}} </a-select-option> 
           
          </a-select>
        </a-form-item>
        <a-form-item label="备注信息" v-bind="formItemInputLayout">
          <a-textarea
            v-decorator="[
              'remark',
            ]"
            id="desc"
            placeholder="请输入"
          ></a-textarea>
        </a-form-item>
       
        <a-form-item :wrapper-col="{ span: 12, offset: 3 }">
          
          <a-button type="primary" @click="handleSubmit"  class="submitBtn footerBtn"> {{submitText}}</a-button>
          <a-button   @click="handleReset"  class="footerBtn"> 重置 </a-button> 
          <a-button  @click="goBackList" class="footerBtn" > 返回列表 </a-button>
          
        </a-form-item>
      </a-form>
    </div>
  </template>
  
  <script>
  import { message } from 'ant-design-vue';
import { goodsApi } from "@/api/good";
import { orderApi } from '@/api/order'
  
  
  export default {
    name: "publish",
    props:{
    },
    data: () => ({
      formItemLayout: {
        labelCol: { span: 2 },
        wrapperCol: { span: 14 },
      },
      formItemInputLayout: {
        labelCol: { span: 2 },
        wrapperCol: { span: 12 },
      },
      submitText:'提交退货单',
      supplierList:[],
      
    }),
    components: {
    },
    beforeCreate() {
      this.form = this.$form.createForm(this, { name: "validate_other" });
    },
    created(){
      
    },
    mounted(){
      this.getsupplierList()
    },
    methods: {
      //委托方列表
     async getsupplierList(){
      let data = {
      }
       goodsApi.supplierlist(data).then(res=>{
          this.supplierList = res.data.data
        })
        
      },
  
      //返回
      goBackList(){
        this.$router.push({path:'/purchase/returnableList',query: {date:new Date().getTime()}})
      },
  
      // 重置
      handleReset(e){
        this.form.resetFields()
  
      },
  
      handleSubmit(e) {
        e.preventDefault();
        this.form.validateFields((err, values) => {
          if (!err) {
            console.log("Received values of form: ", values);
            let data =  {
              ...values,

            }
            orderApi.addrefund(data)
              .then((res) => {
                console.log(res, "resres");
                if (res.data.status == 200 ) {
                  message.success('新建退货单成功')
                  this.goBackList()
                  
                } else {
                  message.error(res.data.message)
                }
              })
            .finally((res) => {
              
            });
            
          }else{
            console.log(err)
          }
        });
      },
    },
  };
  </script>
  <style lang="less" scoped>
  #components-form-demo-validate-other .dropbox {
    height: 180px;
    line-height: 1.5;
  }
  .footerBtn{
      width: 160px;
      height: 52px;
      margin-top: 30px;
      margin-left: 34px;
  }
  .submitBtn{
      margin-left: -44px;
  }
  
  .publish {
    padding: 0 30px;
    width: 1200px;
    #desc {
      resize: none;
      width: 600px;
      height: 60px;
      line-height: 20px;
      padding: 5px 5px;
      overflow-y: scroll;
    }
    :global(.ant-form .ant-form-item) {
      margin-bottom: 10px;
    }
  }
  </style>
  