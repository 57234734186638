var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"publish"},[_c('a-form',_vm._b({attrs:{"id":"validate-other","form":_vm.form}},'a-form',_vm.formItemLayout,false),[_c('a-form-item',[_c('h2',[_vm._v(_vm._s(_vm.titleText))])]),_c('a-form-item',[_c('h3',[_vm._v("基本信息")])]),_c('a-form-item',_vm._b({attrs:{"label":"商品描述"}},'a-form-item',_vm.formItemInputLayout,false),[_c('a-textarea',{directives:[{name:"decorator",rawName:"v-decorator",value:([
          'details',
          {initialValue:_vm.editData.details},
        ]),expression:"[\n          'details',\n          {initialValue:editData.details},\n        ]"}],attrs:{"id":"desc","placeholder":"非必填，如果没有则为商品名称，商品尺寸，（重量），备注组合而成"}})],1),_c('a-form-item',{attrs:{"label":"商品图片"}},[_c('Upload',{ref:"upload"})],1),_c('a-form-item',_vm._b({attrs:{"label":"商品名称"}},'a-form-item',_vm.formItemInputLayout,false),[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
          'title',
          { 
            initialValue:_vm.editData.title,
            rules: [{ required: true, message: '请输入' }] 
          },
        ]),expression:"[\n          'title',\n          { \n            initialValue:editData.title,\n            rules: [{ required: true, message: '请输入' }] \n          },\n        ]"}],attrs:{"placeholder":"必填，例如：清 寿山石罗汉摆件"}})],1),_c('a-form-item',_vm._b({attrs:{"label":"商品品相"}},'a-form-item',_vm.formItemInputLayout,false),[_c('a-textarea',{directives:[{name:"decorator",rawName:"v-decorator",value:([
          'condition',
          {
            initialValue:_vm.editData.condition,

          }
        ]),expression:"[\n          'condition',\n          {\n            initialValue:editData.condition,\n\n          }\n        ]"}],attrs:{"placeholder":"非必填，例如：有冲线等，如没有则为全品"}})],1),_c('a-form-item',_vm._b({attrs:{"label":"商品分类"}},'a-form-item',_vm.formItemInputLayout,false),[_c('a-cascader',{directives:[{name:"decorator",rawName:"v-decorator",value:([
            'cateArr',
            {
              initialValue:_vm.editData.cateArr,
              rules: [
                { required: true, message: '请选择或输入搜索' },
              ],
            },
          ]),expression:"[\n            'cateArr',\n            {\n              initialValue:editData.cateArr,\n              rules: [\n                { required: true, message: '请选择或输入搜索' },\n              ],\n            },\n          ]"}],attrs:{"placeholder":"请选择或输入搜索","allowClear":"","field-names":{ label: 'name', value: 'code', children: 'items' },"options":_vm.cateList}})],1),_c('a-form-item',_vm._b({attrs:{"label":"平台编码"}},'a-form-item',_vm.formItemInputLayout,false),[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
          'code',
          {
            initialValue:_vm.editData.code || '--'
          }
        ]),expression:"[\n          'code',\n          {\n            initialValue:editData.code || '--'\n          }\n        ]"}],attrs:{"disabled":"","placeholder":"平台编码"}})],1),_c('a-form-item',_vm._b({attrs:{"label":"内部编码"}},'a-form-item',_vm.formItemInputLayout,false),[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
          'sku',
          {
            initialValue:_vm.editData.sku
          }
        ]),expression:"[\n          'sku',\n          {\n            initialValue:editData.sku\n          }\n        ]"}],attrs:{"placeholder":"用于商家内部管理所使用的自定义简易编码"}})],1),_c('Standars',{attrs:{"form":_vm.form,"standarsList":_vm.standarsList}}),_c('a-form-item',_vm._b({attrs:{"label":"材质"}},'a-form-item',_vm.formItemInputLayout,false),[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
          'material',
          { 
            initialValue:_vm.editData.material,
          },
        ]),expression:"[\n          'material',\n          { \n            initialValue:editData.material,\n          },\n        ]"}],attrs:{"placeholder":"非必填，例如：铜"}})],1),_c('a-form-item',_vm._b({attrs:{"label":"题识"}},'a-form-item',_vm.formItemInputLayout,false),[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
          'recognition',
          { 
            initialValue:_vm.editData.recognition,
          },
        ]),expression:"[\n          'recognition',\n          { \n            initialValue:editData.recognition,\n          },\n        ]"}],attrs:{"placeholder":"非必填，例如："}})],1),_c('a-form-item',_vm._b({attrs:{"label":"创作年代"}},'a-form-item',_vm.formItemInputLayout,false),[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
          'age',
          { 
            initialValue:_vm.editData.age,
          },
        ]),expression:"[\n          'age',\n          { \n            initialValue:editData.age,\n          },\n        ]"}],attrs:{"placeholder":"非必填，例如：清代"}})],1),_c('a-form-item',_vm._b({attrs:{"label":"作者"}},'a-form-item',_vm.formItemInputLayout,false),[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
          'author',
          { 
            initialValue:_vm.editData.author,
          },
        ]),expression:"[\n          'author',\n          { \n            initialValue:editData.author,\n          },\n        ]"}]})],1),_c('a-form-item',_vm._b({attrs:{"label":"形制"}},'a-form-item',_vm.formItemInputLayout,false),[_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:([
          'xingzhi',
          {
            initialValue:_vm.editData.xingzhi,
          },
        ]),expression:"[\n          'xingzhi',\n          {\n            initialValue:editData.xingzhi,\n          },\n        ]"}],attrs:{"allowClear":"","showSearch":""}},_vm._l((_vm.xingzhiList),function(item){return _c('a-select-option',{key:item.id,attrs:{"value":item.name}},[_vm._v(_vm._s(item.name)+" ")])}),1)],1),_c('a-form-item',{attrs:{"label":"实物细图"}},[_c('Upload',{ref:"uploadDetail"})],1),_c('a-form-item',_vm._b({attrs:{"label":"商品备注"}},'a-form-item',_vm.formItemInputLayout,false),[_c('a-textarea',{directives:[{name:"decorator",rawName:"v-decorator",value:([
          'remark',
          {
            initialValue: _vm.editData.remark,
          }
        ]),expression:"[\n          'remark',\n          {\n            initialValue: editData.remark,\n          }\n        ]"}]})],1),_c('a-form-item',_vm._b({attrs:{"label":"商品库存"}},'a-form-item',_vm.formItemInputLayout,false),[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
          'stocks',
          {
            initialValue: 1,
          }
        ]),expression:"[\n          'stocks',\n          {\n            initialValue: 1,\n          }\n        ]"}],attrs:{"disabled":""}})],1),_c('a-form-item',_vm._b({attrs:{"label":"货架"}},'a-form-item',_vm.formItemInputLayout,false),[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
          'shelves',
          {
            initialValue: _vm.editData.shelves,
          }
        ]),expression:"[\n          'shelves',\n          {\n            initialValue: editData.shelves,\n          }\n        ]"}],attrs:{"placeholder":"10-9"}})],1),_c('a-form-item',_vm._b({attrs:{"label":"销售状态"}},'a-form-item',_vm.formItemInputLayout,false),[_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:([
          'sale_status',
          {
            initialValue:_vm.editData.sale_status,
          },
        ]),expression:"[\n          'sale_status',\n          {\n            initialValue:editData.sale_status,\n          },\n        ]"}],attrs:{"allowClear":"","showSearch":""}},_vm._l((_vm.stateList),function(item){return _c('a-select-option',{key:item.id,attrs:{"value":item.id}},[_vm._v(_vm._s(item.name)+" ")])}),1)],1),_c('a-form-item',_vm._b({attrs:{"label":"来货时间"}},'a-form-item',_vm.formItemInputLayout,false),[_c('a-date-picker',{directives:[{name:"decorator",rawName:"v-decorator",value:([
            'com_date',
            {
              initialValue: _vm.editData.com_date? _vm.moment(_vm.editData.com_date, _vm.dateFormat) : '' // editData
            }
        ]),expression:"[\n            'com_date',\n            {\n              initialValue: editData.com_date? moment(editData.com_date, dateFormat) : '' // editData\n            }\n        ]"}],staticStyle:{"width":"100%"},attrs:{"format":_vm.dateFormat,"locale":_vm.locale},on:{"change":_vm.onChangeDate}})],1),_c('a-form-item',[_c('h3',[_vm._v("价格信息")])]),_c('a-form-item',_vm._b({attrs:{"label":"代发价"}},'a-form-item',_vm.formItemInputLayout,false),[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
          'mid_price',
          { initialValue:_vm.editData.mid_price,
            rules: [{ required: true, message: '请输入' }] },
        ]),expression:"[\n          'mid_price',\n          { initialValue:editData.mid_price,\n            rules: [{ required: true, message: '请输入' }] },\n        ]"}],attrs:{"placeholder":"必填，串货价格"}})],1),_c('a-form-item',_vm._b({attrs:{"label":"拿货价"}},'a-form-item',_vm.formItemInputLayout,false),[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
          'in_price',
          {
            initialValue:_vm.editData.in_price
          }
        ]),expression:"[\n          'in_price',\n          {\n            initialValue:editData.in_price\n          }\n        ]"}],attrs:{"placeholder":"仅自己可见，请放心输入"}})],1),_c('a-form-item',_vm._b({attrs:{"label":"售价"}},'a-form-item',_vm.formItemInputLayout,false),[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
          'sale_price',
          {
            initialValue:_vm.editData.sale_price
          }
        ]),expression:"[\n          'sale_price',\n          {\n            initialValue:editData.sale_price\n          }\n        ]"}],attrs:{"placeholder":"请输入"},on:{"change":_vm.changSalePrice}})],1),_c('a-form-item',_vm._b({attrs:{"label":"零售价"}},'a-form-item',_vm.formItemInputLayout,false),[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
          'retail_price',
          {
            initialValue:_vm.editData.retail_price
          }
        ]),expression:"[\n          'retail_price',\n          {\n            initialValue:editData.retail_price\n          }\n        ]"}],attrs:{"placeholder":"请输入"}})],1),_c('a-form-item',[_c('h3',[_vm._v("委托方信息")])]),_c('a-form-item',_vm._b({attrs:{"label":"对接人"}},'a-form-item',_vm.formItemInputLayout,false),[_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:([
        'to_username',
        {
            initialValue:_vm.editData.to_username,
            rules: [
              { required: true, message: '请选择或输入搜索' },
            ],
          },
        ]),expression:"[\n        'to_username',\n        {\n            initialValue:editData.to_username,\n            rules: [\n              { required: true, message: '请选择或输入搜索' },\n            ],\n          },\n        ]"}],attrs:{"placeholder":"请选择或输入搜索","disabled":""}},_vm._l((_vm.toUsernameList),function(item){return _c('a-select-option',{key:item.id,attrs:{"value":item.name}},[_vm._v(_vm._s(item.name)+" ")])}),1)],1),_c('a-form-item',_vm._b({attrs:{"label":"委托方"}},'a-form-item',_vm.formItemInputLayout,false),[_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:([
          'from_username',
          {
            initialValue:_vm.editData.from_username,
            rules: [
              { required: true, message: '请选择或输入搜索' },
            ],
          },
        ]),expression:"[\n          'from_username',\n          {\n            initialValue:editData.from_username,\n            rules: [\n              { required: true, message: '请选择或输入搜索' },\n            ],\n          },\n        ]"}],attrs:{"placeholder":"请选择或输入搜索","allowClear":"","showSearch":""},on:{"change":_vm.changefromUsername}},_vm._l((_vm.fromUsernameList),function(item){return _c('a-select-option',{key:item.id,attrs:{"value":item.name}},[_vm._v(_vm._s(item.name)+" ")])}),1)],1),_c('a-form-item',[_c('h3',[_vm._v("评分信息")])]),_c('a-form-item',_vm._b({attrs:{"label":"排品逻辑"}},'a-form-item',_vm.formItemInputLayout,false),[_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:([
          'new_type',
          {
            initialValue:_vm.editData.new_type || '0',
          },
        ]),expression:"[\n          'new_type',\n          {\n            initialValue:editData.new_type || '0',\n          },\n        ]"}],attrs:{"placeholder":"请选择","disabled":""}},_vm._l((_vm.typeList),function(item){return _c('a-select-option',{key:item.value,attrs:{"value":item.value}},[_vm._v(_vm._s(item.name)+" ")])}),1)],1),_c('a-form-item',_vm._b({attrs:{"label":"赔付等级"}},'a-form-item',_vm.formItemInputLayout,false),[_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:([
          'compen_level',
          {
            initialValue:_vm.editData.compen_level || '0',
          },
        ]),expression:"[\n          'compen_level',\n          {\n            initialValue:editData.compen_level || '0',\n          },\n        ]"}],attrs:{"placeholder":"请选择","disabled":""}},_vm._l((_vm.compenLevelList),function(item){return _c('a-select-option',{key:item.value,attrs:{"value":item.value}},[_vm._v(_vm._s(item.name)+" ")])}),1)],1),_c('a-form-item',_vm._b({attrs:{"label":"品相等级"}},'a-form-item',_vm.formItemInputLayout,false),[_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:([
          'app_level',
          {
            initialValue:_vm.editData.app_level || '0',
          },
        ]),expression:"[\n          'app_level',\n          {\n            initialValue:editData.app_level || '0',\n          },\n        ]"}],attrs:{"placeholder":"请选择","allowClear":""}},_vm._l((_vm.appLevelList),function(item){return _c('a-select-option',{key:item.value,attrs:{"value":item.value}},[_vm._v(_vm._s(item.name)+" ")])}),1)],1),_c('a-form-item',_vm._b({attrs:{"label":"珍稀程度"}},'a-form-item',_vm.formItemInputLayout,false),[_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:([
          'rare_level',
          {
            initialValue:_vm.editData.rare_level || '0',
          },
        ]),expression:"[\n          'rare_level',\n          {\n            initialValue:editData.rare_level || '0',\n          },\n        ]"}],attrs:{"placeholder":"请选择","allowClear":""}},_vm._l((_vm.rareLevelList),function(item){return _c('a-select-option',{key:item.value,attrs:{"value":item.value}},[_vm._v(_vm._s(item.name)+" ")])}),1)],1),_c('a-form-item',_vm._b({attrs:{"label":"完残程度"}},'a-form-item',_vm.formItemInputLayout,false),[_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:([
          'com_level',
          {
            initialValue:_vm.editData.com_level || '0',
          },
        ]),expression:"[\n          'com_level',\n          {\n            initialValue:editData.com_level || '0',\n          },\n        ]"}],attrs:{"placeholder":"请选择","allowClear":""}},_vm._l((_vm.comLevelList),function(item){return _c('a-select-option',{key:item.value,attrs:{"value":item.value}},[_vm._v(_vm._s(item.name)+" ")])}),1)],1),_c('a-form-item',{attrs:{"wrapper-col":{ span: 12, offset: 3 }}},[(_vm.publishType*1 !== 2)?_c('a-button',{staticClass:"submitBtn footerBtn",attrs:{"type":"primary"},on:{"click":_vm.handleSubmit}},[_vm._v(" "+_vm._s(_vm.submitText))]):_vm._e(),(!_vm.pid)?_c('a-button',{staticClass:"footerBtn",on:{"click":_vm.handleReset}},[_vm._v(" 重置 ")]):_vm._e(),_c('a-button',{staticClass:"footerBtn",on:{"click":_vm.goBackList}},[_vm._v(" 返回列表 ")])],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }