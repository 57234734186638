var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"login"},[_c('div',{staticClass:"login-bg"}),_c('div',{staticClass:"warp"},[_vm._m(0),_c('div',{staticClass:"login-right"},[_c('div',{staticClass:"form-box"},[_vm._m(1),_c('a-form',{staticClass:"login-form",attrs:{"id":"components-form-demo-normal-login","form":_vm.form},on:{"submit":_vm.loginSubmit}},[_c('a-form-item',[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                'username',
                { rules: [{ required: true, message: '请输入账号!' }] },
              ]),expression:"[\n                'username',\n                { rules: [{ required: true, message: '请输入账号!' }] },\n              ]"}],attrs:{"placeholder":"账号"}},[_c('a-icon',{staticStyle:{"color":"rgba(0,0,0,.25)"},attrs:{"slot":"prefix","type":"user"},slot:"prefix"})],1)],1),_c('a-form-item',[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                'password',
                { rules: [{ required: true, message: '请输入密码!' }] },
              ]),expression:"[\n                'password',\n                { rules: [{ required: true, message: '请输入密码!' }] },\n              ]"}],attrs:{"type":"password","placeholder":"密码"}},[_c('a-icon',{staticStyle:{"color":"rgba(0,0,0,.25)"},attrs:{"slot":"prefix","type":"lock"},slot:"prefix"})],1)],1),_c('a-form-item',[_c('a-checkbox',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                'remember',
                {
                  valuePropName: 'checked',
                  initialValue: true,
                },
              ]),expression:"[\n                'remember',\n                {\n                  valuePropName: 'checked',\n                  initialValue: true,\n                },\n              ]"}]},[_vm._v(" 记住账号 ")]),_c('a',{staticClass:"login-form-forgot",attrs:{"href":"javascript:;"}},[_vm._v(" 忘记密码 ")]),_c('a-button',{staticClass:"login-form-button",attrs:{"type":"primary","html-type":"submit"}},[_vm._v(" 登录 ")])],1)],1)],1)])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"login-left"},[_c('div',{staticClass:"login-left-box"},[_c('h2',[_vm._v("星熠全链")]),_c('p',[_c('img',{attrs:{"src":require("../../assets/imgs/logo.jpg"),"alt":""}})])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('h3',[_c('span',{staticClass:"checked"},[_vm._v("账号登录")])])
}]

export { render, staticRenderFns }