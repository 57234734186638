import Vue from 'vue'
import Vuex from 'vuex'
// 导入用户信息模块
import moduleUser from '@/store/user.js'
Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    userData:{}
  },
  getters: {
  },
  mutations: {
    // setUserData(state,userData){
    //   state.userData = userData
    // }
  },
  actions: {
  },
  modules: {
    // 挂载用户信息模块
    m_user:moduleUser
  }
})
