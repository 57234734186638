
<template>
  <div class="comon-list martList">
    <div class="comon-publish addMart">
      <a-button type="primary" @click="$router.push({ path: '/retail/addNewMart' })">
        新建直播计划
      </a-button>
    </div>
    <div style="height: 500px;  margin-top: 30px">
      <a-table 
        :columns="columns" 
        :scroll={x:700,y:500} 
        :data-source="data" 
        :pagination="pagination" 
        :loading="loading"
        @change="onChangTable" 
      >
        <template slot="operation" slot-scope="text, record">
          <a href="javascript:;" class="operate" @click="() => onEdit(record)">进入</a>
          <a-popconfirm v-if="data.length" title="确定取消此拍场吗？" ok-text="确定" cancel-text="取消" 
            @confirm="() => onCancel(record.id)">
            <a href="javascript:;" class="operate">取消</a>
          </a-popconfirm>
          <!-- <a-popconfirm v-if="data.length" title="确定下载此拍场数据吗？" ok-text="确定" cancel-text="取消"
            @confirm="() => download(record)">
            <a href="javascript:;" class="operate" >下载</a>
          </a-popconfirm> -->
        </template>
      </a-table>
    </div>

    <!-- <a-modal class="preview" :visible="previewVisible" :footer="null" @cancel="handleCancel">
      <img alt="example" style="width: 100%" :src="previewImage" />
    </a-modal> -->
  </div>
</template>



<script>
  import {
    h
  } from "vue";

  import {orderApi} from "@/api/order"
  import {
    message
  } from "ant-design-vue";

  export default {
    name: "martList",
    data() {
      return {
        data: [],
        columns:[
        {
        title: "序号",
        dataIndex: "index",
        width: '70px',
        customRender: (text, record, index) => `${index + 1}`,

      },
            {
            title: "直播计划名称",
            dataIndex: "name",
            width: '150px',
            scopedSlots: {
                customRender: "name",
            },
            },
            {
            title: "直播间名称",
            dataIndex: "place",
            width: '150px',
            scopedSlots: {
                customRender: "place",
            },
            },
            {
            title: "主播名称",
            dataIndex: "anchor",
            width: '150px',
            scopedSlots: {
                customRender: "anchor",
            },
            },
            {
            title: "选取商品数量",
            dataIndex: "nums",
            width: '90px',
            scopedSlots: {
              customRender: "nums",
            },
            },
            {
            title: "状态",
            dataIndex: "status",
            width: '90px',
            scopedSlots: {
              customRender: "status",
                
            },
            customRender: (value,record)=>{
                    let data = {}
                    let text =''
                    if(value == 1){
                        data.style ={color:'blue'}
                        text ='进行中'
  
                    }else if(value == 2){
                        data.style ={color:'green'}
                        text ='已完成'

                    }else if(value == 3){
                        data.style ={color:'Tomato'}
                        text ='已取消'

                    }else if(value == 4){
                        data.style ={color:'DeepPink'}
                        text ='未开始'

                    }
                    return h('div',data,text)
                },
            },
             {
            title: "开播时间",
            dataIndex: "s_time",
            width: '150px',
            scopedSlots: {
                customRender: "s_time",
            },
            },
            {
            title: "结束时间",
            dataIndex: "e_time",
            width: '150px',
            scopedSlots: {
                customRender: "e_time",
            },
            },
            {
            title: "创建时间",
            dataIndex: "create_time",
            width: '150px',
            scopedSlots: {
                customRender: "create_time",
            },
            },
            {
            title: "操作",
            dataIndex: "operation",
            width: '150px',
            fixed: 'right',
            scopedSlots: {
                customRender: "operation",
            },
            },
        ],
        loading: false,
        pagination: {
          total: 0,
          current: 1,
          pageSize:50
        },
      };
    },
    
    beforeCreate() {
    },
    mounted() {
        this.searchMart()
    },
    methods: {
        //搜索
      searchMart() {
          let params = {
            page: this.pagination.current,
            pageSize:this.pagination.pageSize,
            creator_id: this.$cookie.get('userid'),
            shop_id:this.$cookie.get('userid')
            
          };
          this.getTable(params);
      },
         //获取表格数据
      getTable(data) {
        this.loading = true;
       orderApi.filminglist(data)
          .then((res) => {
            console.log(res, "resres");
            if (res.data.status == 200 && res.data.data.list.length > 0) {
              this.data = []
              this.pagination.total = +res.data.data.total;
              this.data = res.data.data.list
            } else {
              this.data = [];
              this.pagination.total = 0;
            }
          })
          .finally((res) => {
            this.loading = false;
          });
      },
      //翻页
      onChangTable(page) {
        this.pagination.current = page.current;
        this.pagination.pageSize = page.pageSize;
        this.searchMart()
      },
      //进入某一个拍场
        onEdit(record){
            this.$router.push({ path: '/retail/martDetail', query: {
            id: record.id,
            status:record.status
          },})
        },
        //取消某一个拍场
        onCancel(id){
            let data ={
                id,
                status:3
            }
            orderApi.setfilmingstatus(data).then(res=>{
                 if (res.data.status == 200){
                    message.success('取消拍场成功')
                 }else{
                    message.error(res.data.message)
                 }

            }).finally(()=>{
                this.searchMart()
            })

        },
        //下载某一个拍场数据
        download(record){
          let data ={
            fid:record.id

          }
         orderApi.downloadfilm(data).then(res=>{
              if(res.status == 200){
                console.log(res,'res')
                
                const blob = new Blob([res.data], {type: 'application/vnd.ms-excel'});
                const link = document.createElement('a');
                link.href = window.URL.createObjectURL(blob);
                // 这里也可以自己从headers中获取文件名.
                link.download ='拍场'+ record.id+'.xlsx';
                link.click();
                message.success('下载拍场成功')
              }else{
                message.error('下载失败')

              }
            }).finally(()=>{
                this.searchMart()
            })


        }
    },
  };
</script>

<style lang="less" scoped>
  @import "./../../../assets/less/reset.less";
  @import "./../../../assets/less/config.less";
  .martList{
    padding: 20px;
    overflow: hidden;
    .operate {
      padding-right: 5px;
      &.red{
        color: red;
      }
      &.grey{
        color: #555;

      }
    }
  }
</style>