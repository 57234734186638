import request from "@/utils/request.js";
import Vue from 'vue'

// 获取单个商品信息
function getproductcode(data) {
  return request({
    url:  "/product/getproductcode",
    method: 'POST',
    data
  })
}


 function getproductlist(data) {
    return request({
      url:  "/product/getproductlist",
      method: 'POST',
      data
    })
  }

   function listprint(){
    return request({
        url:  "/prints/listprint",
        method: 'get'
      })
  }
   function getcatelistnew(){
    return request({
        url:  "/product/getcatelistnew",
        method: 'get'
      })
  }
  function getcatelist(){
    return request({
        url:  "/product/getcatelist",
        method: 'get'
      })
  }
  function xingzhilist(){
    return request({
        url:  "/product/xingzhilist",
        method: 'get'
      })
  }
   function gettofromlist(){
    return request({
        url:  "/product/gettofromlist?uid=" + Vue.prototype.$cookie.get('userid'),
        method: 'get'
      })
  }
   function delproduct(data){
    return request({
        url:  "/product/delproduct",
        method: 'post',
        data
      })
  }
   function getproduct(data){
    return request({
        url:  "/product/getproduct",
        method: 'post',
        data
      })
  }
   function editproduct(data){
    return request({
        url:  "/product/editproduct",
        method: 'post',
        data
      })
  }
  // function addproduct(data){
  //   return request({
  //       url:  "/product/editproduct",
  //       method: 'post',
  //       data
  //     })
  // }
  function addproduct(data){
    return request({
        url:  "/product/addproduct",
        method: 'post',
        data
      })
  }
   function printapi(data){
    return request({
        url:  "/prints/printapi",
        method: 'post',
        data
      })
  }
  function exportproductexcel(data){
    return request({
        url:  "/sit/exportproductexcel",
        method: 'post',
        data
      })
  }
   function productexcel(data){
    return request({
        url:  "/site/productexcel",
        method: 'post',
        responseType: 'blob',
        data
      })
  }
   function downloadimg(data){
    return request({
        url:  "/site/downloadimg",
        method: 'post',
        responseType: 'blob',
        data
      })
  }
   function sendproductchannel(data){
    return request({
        url:  "/channel/sendproductchannel",
        method: 'post',
        data
      })
  }
   function oprateproductmulti(data){
    return request({
        url:  "/product/oprateproductmulti",
        method: 'post',
        data
      })
  }
  function downloadfile(data){
    return request({
        url:  "/site/downloadfile",
        method: 'post',
        responseType: 'blob',
        data
      })
  }
  function uploadloglist(data){
    return request({
        url:  "/site/uploadloglist",
        method: 'post',
        data
      })
  }
  function multiuploadproduct(data){
    return request({
        url:  "/site/multiuploadproduct",
        method: 'post',
        data
      })
  }
  function getproductlog(data){
    return request({
        url:  "/product/getproductlog",
        method: 'post',
        data
      })
  }

  function purchaseproductlist(data){
    return request({
        url:  "/settlement/purchaseproductlist",
        method: 'post',
        data
      })
  }
  function addsettlementdetails(data){
    return request({
        url:  "/settlementdetail/addsettlementdetails",
        method: 'post',
        data
      })
  }
  function listsettlementdetail(data){
    return request({
        url:  "/settlementdetail/listsettlementdetail",
        method: 'post',
        data
      })
  }
  function editsettlementdetail(data){
    return request({
        url:  "/settlementdetail/editsettlementdetail",
        method: 'post',
        data
      })
  }
  function deletesettlementdetail(data){
    return request({
        url:  "/settlementdetail/deletesettlementdetail",
        method: 'post',
        data
      })
  }
  function supplierlist(data){
    return request({
        url:  "/refund/supplierlist",
        method: 'post',
        data
      })
  }
  function listsettlement(data){
    return request({
        url:  "/settlement/listsettlement",
        method: 'post',
        data
      })
  }
  function editsettlement(data){
    return request({
        url:  "/settlement/editsettlement",
        method: 'post',
        data
      })
  }
  function deletesettlement(data){
    return request({
        url:  "/settlement/deletesettlement",
        method: 'post',
        data
      })
  }
  function addsettlement(data){
    return request({
        url:  "/settlement/addsettlement",
        method: 'post',
        data
      })
  }
   // 结算单添加商品 -获取可结算的商品列表 
   function listcansettlement(data){
    return request({
        url:  "/product/listcansettlement",
        method: 'post',
        data
      })
  }

  // 添加商品到结算单
  function addsettlementdetail(data){
    return request({
        url:  "/settlementdetail/addsettlementdetail",
        method: 'post',
        data
      })
  }

  function editshop(data){
    return request({
        url:  "/shop/editshop",
        method: 'post',
        data
      })
  }

  export const goodsApi = {
    editshop,
    addsettlement,
    deletesettlement,
    editsettlement,
    listsettlement,
    supplierlist,
    deletesettlementdetail,
    editsettlementdetail,
    listsettlementdetail,
    addsettlementdetails,
    listcansettlement,
    addsettlementdetail,
    purchaseproductlist,
    addproduct,
    xingzhilist,
    getproductlog,
    multiuploadproduct,
    uploadloglist,
    downloadfile,
    getproductlist,
    listprint,
    getcatelist,
    getcatelistnew,
    gettofromlist,
    delproduct,
    getproduct,
    editproduct,
    printapi,
    productexcel,
    downloadimg,
    sendproductchannel,
    oprateproductmulti,
    exportproductexcel,
    getproductcode
  }