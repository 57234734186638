<template>
  <div class="retail">
    <router-view></router-view>
  </div>
</template>


<script>
export default {
  name: "retail",
  data() {
    return {
      
    };
  },

};
</script>