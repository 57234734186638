<template>
    <div class="comon-list purchase-list">
        <div class="comon-publish ">
            <a-button type="primary" @click="() => onEdit('', 'add')">
                新建店铺
            </a-button>
        </div>

        <div style="height: 500px;  margin-top: 30px">
            <a-table :columns="columns" :scroll={x:700,y:500} :data-source="data" :pagination="pagination"
                :loading="loading" @change="onChangTable">
                <template slot="operation" slot-scope="text, record">
                    <a-popconfirm
                        v-if="data.length" 
                        title=" 确定删除此用户吗？
                        " ok-text="确定" 
                        cancel-text="取消" 
                        @confirm="() => onEdit(record,'delete')"
                    >
                        <a href="javascript:;" class="operate grey">  删除</a>
                    </a-popconfirm>
                        <a href="javascript:;" @click="() => onEdit(record, 'edit')" class="operate ">
                        编辑</a>
                </template>
            </a-table>
        </div>
        <a-modal :title="editTitle" :visible="moduleVisible" okText="确定" cancelText="取消" @ok="handleOk"
            @cancel="moduleVisible = false">
            <a-form style="padding: 20px" class="searchArea" :form="editform" :label-col="{ span: 6 }"
                :wrapper-col="{ span: 16 }">
                <a-form-item label="名称" style="width: 400px">
                    <a-input v-decorator="['name', { initialValue: activeItem.name }]" placeholder="请输入" />
                </a-form-item>
                <a-form-item label="简称" style="width: 400px">
                    <a-input v-decorator="['short_name', { initialValue: activeItem.short_name }]"
                        placeholder="请输入" />
                </a-form-item>
                <a-form-item label="LOGO" style="width: 400px">
                    <Upload  ref="upload" :multiple="false"/>
                </a-form-item>
                <a-form-item v-if="editTitle == '添加'" label="负责人姓名" style="width: 400px">
                    <a-input v-decorator="['username', { initialValue: activeItem.username }]" placeholder="请输入" />
                </a-form-item>
                <a-form-item v-if="editTitle == '添加'" label="负责人手机号" style="width: 400px">
                    <a-input v-decorator="['tel', { initialValue: activeItem.tel }]" placeholder="请输入" />
                </a-form-item>
                <a-form-item v-if="editTitle == '编辑'" label="店铺状态" style="width: 400px">
                    <a-select allowClear v-decorator="['status', { initialValue: activeItem.status }]" placeholder="请选择">
                        <a-select-option v-for=" item  in  stateList " :key="item.id" :value="item.id">{{ item.name
                    }}</a-select-option>
                    </a-select>
                </a-form-item>
            </a-form>
        </a-modal>
        <a-modal class="preview" :visible="previewVisible" :footer="null" @cancel="handleCancel">
        <img alt="example" style="width: 100%" :src="previewImage" />
      </a-modal>

    </div>
</template>
  
  
  
<script>
import {
    h
} from "vue";
import Upload from "@/components/UploadImg.vue";

import publicConst from "@/utils/public_const";
import axios from "axios";
import { userApi } from '@/api/user';

import {
    message
} from "ant-design-vue";

export default {
    name: "PurchaseList",
    components: {
    Upload
  },
    data() {
        return {
            previewImage: '',
            moduleVisible: false,
            editTitle: '添加',
            stateList: [
                {
                    id: '0',
                    name: '正常'
                },
                {
                    id: '1',
                    name: '关闭'
                },
            ],
            activeItem: {},
            data: [],
            columns: [{
                title: "序号",
                width: '70px',
                dataIndex: "index",
                customRender: (text, record, index) => `${index + 1}`,

            },
            {
                title: "名称",
                dataIndex: "name",
                width: '150px',
                scopedSlots: {
                    customRender: "name",
                },
            },
            {
                title: "简称",
                dataIndex: "short_name",
                width: '90px',
                scopedSlots: {
                    customRender: "short_name",
                },
            },
            {
                title: "LOGO",
                dataIndex: "logo",
                width: '120px',
                scopedSlots: {
                    customRender: "logo",
                },
                customRender: (value, record) => {
                    if (value && value.includes('{')) {
                        return 'aa'
                        // let obj = JSON.parse(value);
                        // const array = [];
                        // array.push(
                        //         h(
                        //         "img", {
                        //         style: {
                        //             width: "70px",
                        //             height: "70px",
                        //             marginLeft: "5px",
                        //         },
                        //         attrs: {
                        //             src: obj.url,
                        //         },
                        //         on: {
                        //             click: () => this.picPreview(obj.url)
                        //         }
                        //         },
                        //         () => item
                        //         )
                        //     );
                        // return h("div", array);

                    } else {
                    return '--'
                    }
                },
            },
            {
                title: "负责人",
                dataIndex: "owner_name",
                width: '150px',
                scopedSlots: {
                    customRender: "owner_ame",
                },
            },
            {
                title: "店铺状态",
                dataIndex: "status",
                width: '200px',
                scopedSlots: {
                    customRender: "status",
                },
                customRender: (value, record) => {
                    if (value == 1) {
                        return '关闭'
                    }
                    if (value == 0) {
                        return '正常'
                    }
                    return '--'
                }
            },
            {
                title: "创建时间",
                dataIndex: "create_date",
                width: '200px',
                scopedSlots: {
                    customRender: "create_date",
                },
            },
            {
                title: "店铺ID",
                dataIndex: "id",
                width: '200px',
                scopedSlots: {
                    customRender: "id",
                },
            },

            {
                title: "操作",
                dataIndex: "operation",
                width: '150px',
                fixed: 'right',
                scopedSlots: {
                    customRender: "operation",
                },
            },
            ],
            loading: false,
            pagination: {
                total: 0,
                current: 1,
                pageSize: 50
            },
        previewVisible: false,



        };
    },

    beforeCreate() {
        if (this.$cookie.get('username')) {
            this.editform = this.$form.createForm(this, {
                name: "validate_edit",
            });
        } else {
            message.warning('请登录')
            this.$router.push({ path: '/login' })
        }
    },
    mounted() {
        this.searchData();
        message.config({
            top: `100px`,
            duration: 8,
        });

    },
    computed: {

    },
    methods: {
            //图片预览
      picPreview(src) {
        if (src) {
          this.previewImage = src
          this.previewVisible = true;
        }
      },
       //关闭预览
       handleCancel() {
        this.previewImage = ''
        this.previewVisible = false;
      },
        //获取表格数据
        getTable(data) {
           userApi.listshop(data)
                .then((res) => {
                    console.log(res, "resres");
                    if (res.data.status == 200 && res.data.data.data.length > 0) {
                        this.data = []
                        this.pagination.total = +res.data.data.total;

                        res.data.data.data.map(good => {
                            this.data.push({ ...good, key: good.id })

                        })
                    } else {
                        this.data = [];
                        this.pagination.total = 0;
                    }
                })
                .finally((res) => {

                });
        },
        //翻页
        onChangTable(page) {
            this.pagination.current = page.current;
            this.searchData()
        },
        //搜索
        searchData() {
            let params = {
                page: this.pagination.current,
                pageSize: this.pagination.pageSize,
                creator_id: this.$cookie.get('userid'),
                shop_id:this.$cookie.get('userid')

            };
            this.getTable(params);
        },
        //编辑
        onEdit(record, type) {
            switch (type) {
                case 'add':
                    this.moduleVisible = true
                    this.editTitle = '添加'
                    this.activeItem = {}

                    break;
                case 'edit':
                    this.moduleVisible = true
                    this.editTitle = '编辑'
                    this.activeItem = {...record};

                    if( !record.logo ) return;
                    this.$nextTick(() => {
                         this.$refs.upload.fileList = [].concat(JSON.parse(record.logo));
                    })
                    

                    break;
                    case 'delete': // 删除
                    let params = {
                        real_shop_id:record.id
                    }
                    userApi.deleteshop(params)
                    .then((res) => {
                        // console.log(res, "resres");
                        if (res.data.status == 200) {
                            message.success('店铺删除成功')
                        } else {
                            message.error(res.data.message)
                        }
                    })
                    .finally((res) => {
                        this.searchData()

                    });
                    break;
                default:
                    break;
            }
        },
        handleOk() {
            this.editform.validateFields((err, values) => {
                let params = {
                    ...values,
                    logo:JSON.stringify(this.$refs.upload.fileList[0])
                }
                let url = ''
                switch (this.editTitle) {
                    case '添加':
                        url = 'addshop'
                        break;
                    case '编辑':
                        params.real_shop_id = this.activeItem.id
                        url = 'editshop'
                        break;
                    default:
                        break;
                }

               userApi[url](params)
                    .then((res) => {
                        console.log(res, "resres");
                        if (res.data.status == 200) {
                            message.success('操作店铺成功')
                        } else {
                            message.error(res.data.message)
                        }
                    })
                    .finally((res) => {
                        this.moduleVisible = false
                        this.searchData()

                    });
            })
        },
    },
};
</script>
  
<style lang="less" scoped>
@import "./../../assets/less/reset.less";
@import "./../../assets/less/config.less";

.operate {
    padding-right: 5px;
}
</style>