var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"publish"},[_c('a-form',{attrs:{"id":"validate-other","form":_vm.form}},[_c('a-form-item',_vm._b({attrs:{"label":"直播间名称"}},'a-form-item',_vm.formItemInputLayout,false),[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
          'live_room_name',
          { 
            initialValue:_vm.editData.live_room_name,
            rules: [{ required: true, message: '请输入' }] 
          },
        ]),expression:"[\n          'live_room_name',\n          { \n            initialValue:editData.live_room_name,\n            rules: [{ required: true, message: '请输入' }] \n          },\n        ]"}],attrs:{"placeholder":"请输入"}})],1),_c('a-form-item',_vm._b({attrs:{"label":"简称"}},'a-form-item',_vm.formItemInputLayout,false),[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
          'live_room_name_short',
          { 
            initialValue:_vm.editData.live_room_name_short,
            rules: [{ required: true, message: '请输入' }] 
          },
        ]),expression:"[\n          'live_room_name_short',\n          { \n            initialValue:editData.live_room_name_short,\n            rules: [{ required: true, message: '请输入' }] \n          },\n        ]"}],attrs:{"placeholder":"请输入"}})],1),_c('a-form-item',_vm._b({attrs:{"label":"直播间负责人","has-feedback":""}},'a-form-item',_vm.formItemInputLayout,false),[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
          'live_room_leader',
          { 
            initialValue:_vm.editData.live_room_leader,
            rules: [{ required: true, message: '请输入' }] 
          },
        ]),expression:"[\n          'live_room_leader',\n          { \n            initialValue:editData.live_room_leader,\n            rules: [{ required: true, message: '请输入' }] \n          },\n        ]"}],attrs:{"placeholder":"请输入"}})],1),_c('a-form-item',_vm._b({attrs:{"label":"直播间简介"}},'a-form-item',_vm.formItemInputLayout,false),[_c('a-textarea',{directives:[{name:"decorator",rawName:"v-decorator",value:([
          'live_room_description',
          {initialValue:_vm.editData.live_room_description},
        ]),expression:"[\n          'live_room_description',\n          {initialValue:editData.live_room_description},\n        ]"}],attrs:{"id":"desc","placeholder":"请输入"}})],1),_c('a-form-item',{attrs:{"wrapper-col":{ span: 12, offset: 4 }}},[_c('a-button',{staticClass:"submitBtn footerBtn",attrs:{"type":"primary"},on:{"click":_vm.handleSubmit}},[_vm._v(" "+_vm._s(_vm.submitText))]),_c('a-button',{staticClass:"footerBtn",on:{"click":_vm.handleReset}},[_vm._v(" 重置 ")]),_c('a-button',{staticClass:"footerBtn",on:{"click":_vm.goBackList}},[_vm._v(" 返回列表 ")])],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }