<!--
 * @Descripttion: *
 * @Author: wjj
 * @LastEditors: *
 * @LastEditTime: 2024-10-16 18:00:34
-->
<template>
  <div class="login">
    <div class="login-bg"></div>
    <div class="warp">
      <div class="login-left">
        <div class="login-left-box">
          <h2>星熠全链</h2>
          <p><img src="../../assets/imgs/logo.jpg" alt="" ></p>
        </div>
      </div>
      <div class="login-right">
         <div class="form-box">
          <h3>
            <span class="checked">账号登录</span>
          </h3>
          <a-form
            id="components-form-demo-normal-login"
            :form="form"
            class="login-form"
            @submit="loginSubmit"
          >
            <a-form-item>
              <a-input
                v-decorator="[
                  'username',
                  { rules: [{ required: true, message: '请输入账号!' }] },
                ]"
                placeholder="账号"
              >
                <a-icon slot="prefix" type="user" style="color: rgba(0,0,0,.25)" />
              </a-input>
            </a-form-item>
            <a-form-item>
              <a-input
                v-decorator="[
                  'password',
                  { rules: [{ required: true, message: '请输入密码!' }] },
                ]"
                type="password"
                placeholder="密码"
              >
                <a-icon slot="prefix" type="lock" style="color: rgba(0,0,0,.25)" />
              </a-input>
            </a-form-item>
            <a-form-item>
              <a-checkbox
                v-decorator="[
                  'remember',
                  {
                    valuePropName: 'checked',
                    initialValue: true,
                  },
                ]"
              >
                记住账号
              </a-checkbox>
              <a class="login-form-forgot" href="javascript:;">
                忘记密码
              </a>
              <a-button type="primary" html-type="submit" class="login-form-button">
                登录
              </a-button>
            </a-form-item>
          </a-form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import { Notify, Icon, Button } from 'vant';
// import publicConst  from '@/utils/public_const'
// import axios from "axios";
import { userApi } from '@/api/user'
import { message } from 'ant-design-vue';


export default {
  name: 'Login',
  data() {
    return {
     form: this.$form.createForm(this, { name: 'normal_login' }),
      show: false
    }
  },
  components: {
    // [Notify.Component.name]: Notify.Component,
    // [Icon.name]: Icon,
    // [Button.name]: Button
  },
  methods: {
    loginSubmit(){
      this.form.validateFields((err, values) => {
        if (err) {
          return;
        }
     
     console.log(values)
        const params = {
          ...values
        }
        userApi.login(params)
          .then(res => {
              if(res.data.status == 200 && res.data.data){
                this.saveUserInfo(res.data.data);
                this.$cookie.set('username', values.username, { expires: 7});
                this.$cookie.set('usernameStr', res.data.data.username, { expires: 7});
                // this.$cookie.set('password', password, { expires: 7});
                this.$cookie.set('userid', res.data.data.id, { expires: 7}); //用户id
                this.$cookie.set('shop_id', res.data.data.shop_id, { expires: 7}); //店铺id
                this.$cookie.set('role', res.data.data.role, { expires: 7}); //角色
                this.$cookie.set('role_name', res.data.data.role_name, { expires: 7}); //店铺名称
                const logo_url = JSON.parse(res.data.data.shop_info.logo).url; // logo 
                this.$cookie.set('logo_url', logo_url, { expires: 7}); 
                this.$cookie.set('shop_name', res.data.data.shop_info.name, { expires: 7}); 
                this.$cookie.set('creator_id', res.data.data.shop_info.creator_id, { expires: 7}); 

                
                localStorage.setItem('defaultOpenKeys', ['0']);
                localStorage.setItem('defaultSelectedKeys', ['3-1-1']);

                this.$router.push({path:'/goods/list'});
                
              }else{
                console.log(res,'res')
                message.error(res.data.message)
              }
          })
        // axios.post( publicConst.proxyPath +  '/user/login',params,{
        //     headers:{'Content-Type': 'multipart/form-data'}
        // }).then((res)=>{
        //   if(res.data.status == 200 && res.data.data){
        //     this.saveUserInfo(res.data.data);
        //     this.$cookie.set('username', username, { expires: 7});
        //     this.$cookie.set('usernameStr', res.data.data.username, { expires: 7});
        //     this.$cookie.set('password', password, { expires: 7});
        //     this.$cookie.set('userid', res.data.data.id, { expires: 7}); //用户id
        //     this.$cookie.set('shop_id', res.data.data.shop_id, { expires: 7}); //店铺id
        //     this.$cookie.set('role', res.data.data.role, { expires: 7}); //角色
        //     this.$cookie.set('role_name', res.data.data.role_name, { expires: 7}); //店铺名称
        //     const logo_url = JSON.parse(res.data.data.shop_info.logo).url; // logo 
        //     this.$cookie.set('logo_url', logo_url, { expires: 7}); 
        //     this.$cookie.set('shop_name', res.data.data.shop_info.name, { expires: 7}); 
        //     this.$cookie.set('creator_id', res.data.data.shop_info.creator_id, { expires: 7}); 

        //     this.$router.push({path:'/goods/list'});
        //     localStorage.setItem('defaultOpenKeys', ['0']);
        //   localStorage.setItem('defaultSelectedKeys', ['3-1-1']);
            
        //   }else{
        //     console.log(res,'res')
        //     message.error(res.data.message)
        //   }
        // });
      })
    },

    saveUserInfo(userInfo){


    },
  }
}
</script>

<style lang="less" scoped>
@import "./../../assets/less/config.less";

.login {
  width: 100%;
  height: 100%;
  display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}
.login {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
      position: fixed;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background: url("./../../assets/imgs/login_bg.jpg") 0 0  no-repeat;
      // background-repeat: no-repeat;
      background-size: cover;
      // background-size: 1920px 1080px;
      // background-position: 50%;
      z-index: 1;
    // .login-bg{
    //   position: fixed;
    //   top: 0;
    //   left: 0;
    //   right: 0;
    //   bottom: 0;
    //   background: url("./../../assets/imgs/login_bg.jpg");
    //   background-repeat: no-repeat;
    //   background-size: 1920px 1080px;
    //   background-position: 50%;
    //   z-index: 1;
    // }


  .warp {
    position: absolute;
    width: 960px;
    height: 460px;
    top: 50%;
    left: 50%;
    margin-top: -230px;
    margin-left: -450px;
    z-index: 2;
    display: flex;
    overflow: hidden;

  }
  .login-left{
    width: 32%;
    background: #fff;
    height: 100%;

    .login-left-box {
      margin: 15% 0;
      padding: 0 30px;
      // background: red;
      height: 80%;
      border-right: 1px solid #999999;
      text-align: center;
      img {
        width: 220px;
        margin-top: 10px;
      }
    }
    h2 {
      font-family: MicrosoftYaHei, MicrosoftYaHei;
      font-weight: bold;
      font-size: 24px;
      color: #111111;
      line-height: 36px;
      text-align: center;
      font-style: normal;
    }
  }
  .login-right {
    width: 50%;
    background: #fff;
    height: 100%;

    .form-box {
      width: 360px;
      height: 80%;
      margin: 10% auto;

      h3 {
        font-family: MicrosoftYaHei, MicrosoftYaHei;
        font-weight: bold;
        font-size: 24px;
        text-align: center;
        .checked {
          color: @themeColor;
        }
        .sep-line {
          margin: 0 32px;
        }
      }

    }
    

    .login-form {
        max-width: 100%;
        .login-form-forgot {
          float: right;
        }
        .login-form-button {
          width: 100%;
        }
        
      }
  }
}
</style>
