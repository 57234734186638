<!--
 * @Descripttion: *
 * @Author: wjj
 * @LastEditors: *
 * @LastEditTime: 2024-10-19 21:04:24
-->
<template>
  <div class="clearfix">
    
    <a-upload
      name="file"
      :list-type="type"
      :file-list="fileList"
      @preview="handlePreview"
      @change="handleChange"
      :before-upload="beforeUpload"
      :customRequest ="uploadMethod"
      :multiple="multiple"

    >
      <!-- <div v-if="fileList.length < 8"> -->
        <a-icon type="plus" />
        <div class="ant-upload-text"> {{btnName }}</div>
      <!-- </div> -->
    </a-upload>
    <a-modal class="preview" :visible="previewVisible" :footer="null" @cancel="handleCancel">
      <img alt="example" style="width: 100%" :src="previewImage" />
    </a-modal>
  </div>
</template>
<script>
function getBase64(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });
}

import publicConst from "@/utils/public_const";
import { message } from "ant-design-vue";
import axios from "axios";

export default {
  name: "Upload",
  props: {
    multiple: {
      type: Boolean,
      default:true
    }
  },
  data() {
    return {
      uploadUrl: publicConst.proxyPath + "/site/uploadoss",
      previewVisible: false,
      previewImage: "",
      fileList: [],
      urlList:{},
      type:'picture-card',
      // multiple: true,
      btnName: '上传图片',
      uploadHeaders: {
       'Content-Type':'multipart/form-data;'
      },
    };
  },
  methods: {
    beforeUpload(file) {
      const isJpgOrPng =
        file.type === "image/jpeg" ||
        file.type === "image/png" ||
        file.type === "image/jpg" ||
        file.type === "image/gif"
      if (!isJpgOrPng) {
        message.error("图片格式只能为：png,jpg,jpeg,gif");
      }
      const isLt2M = file.size / 1024 / 1024 < 20;
      if (!isLt2M) {
        message.error("图片大小不能超过20M");
      }
      return isJpgOrPng && isLt2M;
    },
    //关闭预览
    handleCancel() {
      this.previewVisible = false;
    },
    //预览
    async handlePreview(file) {
      if (!file.url && !file.preview) {
        file.preview = await getBase64(file.originFileObj);
      }
      this.previewImage =
         file.url || file.preview;
      this.previewVisible = true;
    },

     handleChange({ fileList }) {
      this.fileList = fileList
      let list = []
      list = this.fileList.map(file=>{
        if(this.urlList[file.uid]){
          return {
            name:file.name,
            uid:file.uid,
            url:this.urlList[file.uid]
          }
        }else{
          return file
        }
      });
      this.fileList = JSON.parse(JSON.stringify(list))
      console.log(this.urlList,this.fileList,'this.urlList')
    },

      // 上传
      uploadMethod(info){
          const formData = new FormData();
          formData.append('file', info.file);
          axios.post(this.uploadUrl,formData,{headers:info.headers})
          .then(res => {
              console.log(res);
              if(res.data.status == 200){
                // console.log(this.fileList,info,'file----')
                this.urlList[info.file.uid] = res.data.data
                info.onSuccess();
              }
          }).catch(err =>{
             info.onError() //上传失败
          })
      },
    
    
  },
};
</script>
<style>
/* you can make up upload button and sample style by using stylesheets */
.ant-upload-select-picture-card i {
  font-size: 32px;
  color: #999;
}

.ant-upload-select-picture-card .ant-upload-text {
  margin-top: 8px;
  color: #666;
}
</style>
