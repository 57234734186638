<template>
    <div class="publish">
      <a-form
        id="validate-other"
        :form="form"
        
      >
      <a-form-item label="直播间名称" v-bind="formItemInputLayout">
          <a-input
            placeholder="请输入"
            v-decorator="[
              'live_room_name',
              { 
                initialValue:editData.live_room_name,
                rules: [{ required: true, message: '请输入' }] 
              },
            ]"
          />
        </a-form-item>
        <a-form-item label="简称" v-bind="formItemInputLayout">
          <a-input
            placeholder="请输入"
            v-decorator="[
              'live_room_name_short',
              { 
                initialValue:editData.live_room_name_short,
                rules: [{ required: true, message: '请输入' }] 
              },
            ]"
          />
        </a-form-item>
        <a-form-item label="直播间负责人" has-feedback v-bind="formItemInputLayout">
          <a-input
            placeholder="请输入"
            v-decorator="[
              'live_room_leader',
              { 
                initialValue:editData.live_room_leader,
                rules: [{ required: true, message: '请输入' }] 
              },
            ]"
          />
        </a-form-item>
        <a-form-item label="直播间简介" v-bind="formItemInputLayout">
          <a-textarea
            v-decorator="[
              'live_room_description',
              {initialValue:editData.live_room_description},
            ]"
            id="desc"
            placeholder="请输入"
          ></a-textarea>
        </a-form-item>
       
        <a-form-item :wrapper-col="{ span: 12, offset: 4 }">
          
          <a-button type="primary" @click="handleSubmit"  class="submitBtn footerBtn"> {{submitText}}</a-button>
          <a-button   @click="handleReset"  class="footerBtn"> 重置 </a-button> 
          <a-button  @click="goBackList" class="footerBtn" > 返回列表 </a-button>
          
        </a-form-item>
      </a-form>
    </div>
  </template>
  
  <script>
import { message } from 'ant-design-vue';
import { orderApi } from '@/api/order';
  
  
  export default {
    name: "publish",
    props:{
    },
    data: () => ({
      formItemLayout: {
        labelCol: { span: 3 },
        wrapperCol: { span: 14 },
      },
      formItemInputLayout: {
        labelCol: { span: 3 },
        wrapperCol: { span: 12 },
      },
      submitText:'新建直播间',
      pid:'',
      editData:{},
      cateList:[],
    }),
    components: {
    },
    beforeCreate() {
      this.form = this.$form.createForm(this, { name: "validate_other" });
    },
    created(){
      
    },
    mounted(){
    },
    methods: {
  
      //返回
      goBackList(){
        this.$router.push({path:'/liveRoom/liveRoomList',query: {date:new Date().getTime()}})
      },
  
      // 重置
      handleReset(e){
          this.form.resetFields()
      },
  
      handleSubmit(e) {
        e.preventDefault();
        this.form.validateFields((err, values) => {
          if (!err) {
            console.log("Received values of form: ", values);
            let data =  {
              ...values

            }
           orderApi.addlive(data)
        .then((res) => {
          console.log(res, "resres");
          if (res.data.status == 200 ) {
            message.success('新建直播间成功')
            this.goBackList()
           
          } else {
            message.error('新建直播间失败')

            
          }
        })
        .finally((res) => {
          
        });
            

          
          }else{
            console.log(err)
          }
        });
      },
    },
  };
  </script>
  <style lang="less" scoped>
  #components-form-demo-validate-other .dropbox {
    height: 180px;
    line-height: 1.5;
  }
  .footerBtn{
      width: 160px;
      height: 52px;
      margin-top: 30px;
      margin-left: 34px;
  }
  .submitBtn{
      margin-left: -44px;
  }
  
  .publish {
    padding: 0 30px;
    width: 1200px;
    #desc {
      resize: none;
      width: 600px;
      height: 60px;
      line-height: 20px;
      padding: 5px 5px;
      overflow-y: scroll;
    }
    :global(.ant-form .ant-form-item) {
      margin-bottom: 10px;
    }
  }
  </style>
  