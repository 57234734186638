
<template>
    <div class="comon-list purchase-list">
      
      <div class="comon-check-form purchase-check-form">
        <a-form style="padding: 20px" class="searchArea" :form="form" layout="inline" :label-col="{ span: 8 }"
          :wrapper-col="{ span: 16 }">
          <a-form-item label="采购单名称" style="width: 235px">
            <a-input v-decorator="['name']" placeholder="请输入" />
          </a-form-item>

          <a-form-item label="供货方" style="width: 235px">
            <a-select  v-decorator="['supplier_id']"
              placeholder="请选择">
              <a-select-option v-for="item in supplierList" :key="item.id" :value="item.id">{{ item.name
              }}</a-select-option>
            </a-select>
          </a-form-item>

          <a-form-item>
            <a-button type="primary" @click="searchPurchase"> 搜索 </a-button>
          </a-form-item>
          <a-form-item>
            <a-button class="resetBtn" @click="resetSearch"> 重置 </a-button>
          </a-form-item>
        </a-form>
      </div>
      <div style="height: 500px;  margin-top: 30px">
        <a-table 
        :columns="columns" 
        :scroll={x:1300,y:500} 
        :data-source="data" 
        :pagination="pagination" 
        :loading="loading"
        @change="onChangTable" 
        >
          <template slot="operation" slot-scope="text, record">
            <a href="javascript:;" @click="() => onDetail(record)" class="operate" >查看</a>
             <a-popconfirm v-if="data.length" title=" 确定删除此该采购单吗？
              " ok-text="确定" cancel-text="取消" @confirm="() => delPurchase(record)">
              <a  v-if="record.status*1 !== 1" href="javascript:;" class="operate">
              删除</a>
            </a-popconfirm>
            <a v-if="record.status*1 !== 1" href="javascript:;"  @click="onEditStatus(record)" class="operate">
              提交</a>
          </template>
        </a-table>
      </div>
  
    </div>
  </template>
  
  
  
  <script>
   import {orderApi} from "@/api/order";
   import { userApi } from "@/api/user"

    import {
      message
    } from "ant-design-vue";
  
    export default {
      name: "PurchaseList",
      data() {
        return {
          supplierList: [], // 供货方列表数据
          data: [],
          form: this.$form.createForm(this, { name: 'searchPurchaseForm' }),
          searchSupplierForm: this.$form.createForm(this, { name: 'searchSupplierForm' }),
          columns:[{
            title: "编号",
            width: '70px',
            fixed: 'left',
            dataIndex: "purchase_id",
            key: 'purchase_id',
            scopedSlots: {
              customRender: "purchase_id",
            },
            
  
          },
          {
            title: "采购单名称",
            dataIndex: "name",
            key: 'name',
            width: '150px',
            scopedSlots: {
              customRender: "name",
            },
          },
          {
            title: "供货方",
            dataIndex: "supplier_name",
            width: '90px',
            key: 'supplier_name',
            scopedSlots: {
              customRender: "supplier_name",
            },
          },
          {
            title: "数量",
            dataIndex: "product_quantity",
            key: 'product_quantity',
            width: '120px',
            scopedSlots: {
              customRender: "product_quantity",
            },
          },
        
          {
            title: "总金额",
            dataIndex: "total_amount",
            key: 'total_amount',
            width: '150px',
            scopedSlots: {
              customRender: "total_amount",
            },
          },
          {
            title: "创建人",
            dataIndex: "creator_name",
            key: 'creator_name',
            width: '150px',
            scopedSlots: {
              customRender: "creator_name",
            },
          },
           {
            title: "创建时间",
            dataIndex: "created_at",
            key: 'created_at',
            width: '200px',
            scopedSlots: {
              customRender: "created_at",
            },
          },
          {
            title: "状态",
            dataIndex: "status",
            key: 'status',
            width: '150px',
            scopedSlots: {
              customRender: "status",
            },
            customRender: (value,record)=>{
              if(value == 1){
                return '已提交'
              }
              if(value == 0){
                return <span style="color: green;">未提交</span>
              }
              if(value == 2){
                return '异常'
              }
              return '--'
            }
          },
  
          {
            title: "操作",
            dataIndex: "operation",
            key: 'operation',
            width: '150px',
            fixed: 'right',
            scopedSlots: {
              customRender: "operation",
            },
          },
        ],
          loading: false,
          pagination: {
            total: 0,
            current: 1,
            pageSize:50
          },
  
          purchaseModal:false,
  
        };
      },
      
      beforeCreate() {
        if(this.$cookie.get('username')){
          this.form = this.$form.createForm(this, {
            name: "validate_other",
          });
        }else{
          message.warning('请登录')
          this.$router.push({path:'/login'})
        }
      },
      mounted() {
        this.searchPurchase();
        this.getclientlist();

        message.config({
          top: `100px`,
          duration: 8,
        });
        
      },
      computed: {
          
      },
      methods: {
        // 搜索
        searchPurchase() {
          this.form.validateFields((err, values) => {
            // console.log('values', values)
            let params = {
              page: this.pagination.current,
              creator_id:this.$cookie.get('userid'),
              shop_id:this.$cookie.get('userid'),
              ...values,
            };
            this.getTable(params);
          });
        },

        //重置采搜索条件
        resetSearch () {
          this.form.resetFields();
          this.pagination.current = 1
          this.searchPurchase();
        },

        // 获取供货方列表
        getclientlist() {
          const params = {
              name: '',
              shop_id: this.$cookie.get('shop_id'),
              page: this.pagination.current,
              pageSize:this.pagination.pageSize,

            };
            userApi.getclientlist(params)
            .then((res) => {
              if (res.data.status == 200 && res.data.data.list.length > 0) {
                res.data.data.list.map(good=>{
                   this.supplierList.push({...good})
                   
                })
              }
            })
        },

        //获取表格数据
        getTable(data) {
          this.loading = true;
          orderApi.listpurchase(data)
            .then((res) => {
              // console.log(res, "resres");
              if (res.data.status == 200 && res.data.data.data.length > 0) {
                this.data = []
                this.pagination.total = +res.data.data.total;
                
                res.data.data.data.map(good=>{
                   this.data.push({...good,key:good.purchase_id});
                   
                })
              } else {
                this.data = []
                this.pagination.total = 0;
              }
              
            })
            .finally(() => {
              this.loading = false;
            })
        },
        //翻页
        onChangTable(page) {
          this.pagination.current = page.current;
          this.searchPurchase()
        },

        //查看
        onDetail(item) {
          const { purchase_id, status, creator_id, supplier_shop_id, supplier_name } = item;
          this.$router.push({
            path: "/purchase/purchaseGoodsList",
            query: {
              purchase_status: status,
              purchase_id,
              creator_id,
              supplier_shop_id,
              supplier_name
            },
          });
        },

        // 提交
        onEditStatus(record) {
          const params = {
            purchase_id: record.purchase_id,
            status: 1,
          }
           orderApi.editpurchase(params)
                    .then(res => {
                      this.searchPurchase();
                    })
                    .catch( err => {
                      console.log(err);
                    })
        },

        // 删除
        delPurchase(record) {
          const purchase_id = record.purchase_id;
           orderApi.deletepurchase({purchase_id})
                    .then(res => {
                      this.searchPurchase();
                    })
                    .catch( err => {
                      console.log(err);
                    })
        },
        
        handleOk(){
          
        },
        handleCancel(){
          this.purchaseModal = false
        },
  
      },
    };
  </script>
  
  <style lang="less" scoped>
   @import "./../../assets/less/reset.less";
  @import "./../../assets/less/config.less";
  .operate{
    padding-right: 5px;
  }
  
   
  </style>