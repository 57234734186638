<!--
 * @Descripttion: *
 * @Author: wjj
 * @LastEditors: *
 * @LastEditTime: 2023-01-13 23:18:59
-->
<template>
  <div class="goodslist">
    <router-view></router-view>
  </div>
</template>


<script>
export default {
  name: "goodslist",
  data() {
    return {
      
    };
  },

};
</script>

