
<template>
  <div class="comon-list purchase-list">
    <div class="comon-publish ">
      <a-button type="primary" @click="$router.push({ path: '/liveRoom/newLiveRoom' })">
        新建直播间
      </a-button>
    </div>

    <div style="height: 500px;  margin-top: 30px">
      <a-table :columns="columns" :scroll={x:700,y:500} :data-source="data" :pagination="pagination" :loading="loading"
        @change="onChangTable">
        <template slot="operation" slot-scope="text, record">
          <a-popconfirm v-if="data.length" :title="record.status == 1 ? '确定关闭此直播间吗？' : '确定开启此直播间吗'
            " ok-text="确定" cancel-text="取消" @confirm="() => onEditStatus(record)">
            <a href="javascript:;" :class="record.status == 1 ? 'operate' : 'operate'">{{
  record.status == 1 ? "关闭" : "开启"
}}</a>
          </a-popconfirm>
        </template>
      </a-table>
    </div>

  </div>
</template>
  
  
  
<script>
import { orderApi } from "@/api/order";
import {
  message
} from "ant-design-vue";

export default {
  name: "PurchaseList",
  data() {
    return {
      data: [],
      columns: [{
        title: "直播间编号",
        width: '120px',
        // fixed: 'left',
        dataIndex: "live_room_id",
        scopedSlots: {
          customRender: "live_room_id",
        },

      },
      {
        title: "直播间名称",
        dataIndex: "live_room_name",
        width: '220px',
        scopedSlots: {
          customRender: "live_room_name",
        },
      },
      {
        title: "简称",
        dataIndex: "live_room_name_short",
        width: '220px',
        scopedSlots: {
          customRender: "live_room_name_short",
        },
      },
      {
        title: "负责人",
        dataIndex: "live_room_leader",
        width: '150px',
        scopedSlots: {
          customRender: "live_room_leader",
        },
      },
      {
        title: "状态",
        dataIndex: "status",
        width: '100px',
        scopedSlots: {
          customRender: "status",
        },
        customRender: (value, record) => {
          if (value == 2) {
            return '关闭'
          }
          if (value == 1) {
            return '正常'
          }
          return '--'
        }
      },
      {
        title: "创建时间",
        dataIndex: "created_at",
        width: '200px',
        scopedSlots: {
          customRender: "created_at",
        },
      },

      {
        title: "操作",
        dataIndex: "operation",
        width: '100px',
        fixed: 'right',
        scopedSlots: {
          customRender: "operation",
        },
      },
      ],
      loading: false,
      pagination: {
        total: 0,
        current: 1,
        pageSize: 50
      },

      purchaseModal: false,

    };
  },

  beforeCreate() {
    if (this.$cookie.get('username')) {
      this.form = this.$form.createForm(this, {
        name: "validate_other",
      });
    } else {
      message.warning('请登录')
      this.$router.push({ path: '/login' })
    }
  },
  mounted() {
    this.searchPurchase();
    message.config({
      top: `100px`,
      duration: 8,
    });

  },
  computed: {

  },
  methods: {
    //重置搜索条件
    resetSearch() {
      this.form.resetFields();
      this.pagination.current = 1
      this.searchPurchase();
    },
    //获取表格数据
    getTable(data) {
      orderApi.listlive(data)
        .then((res) => {
          console.log(res, "resres");
          if (res.data.status == 200 && res.data.data.data.length > 0) {
            this.data = []
            this.pagination.total = +res.data.data.total;

            res.data.data.data.map(good => {
              this.data.push({ ...good, key: good.id })

            })
          } else {
            this.data = [];
            this.pagination.total = 0;
          }
        })
        .finally((res) => {

        });
    },
    //翻页
    onChangTable(page) {
      this.pagination.current = page.current;
      this.searchPurchase()
    },
    //搜索
    searchPurchase() {
      this.form.validateFields((err, values) => {
        let params = {
          page: this.pagination.current,
          ...values
        };
        this.getTable(params);
      });
    },
    //改变状态
    onEditStatus(record) {
      let data = {
        live_room_id: record.live_room_id,
        status: record.status == 1 ? 2 : 1

      };
      orderApi.editlive(data)
        .then((res) => {
          console.log(res, "resres");
          if (res.data.status == 200) {
            message.success(`直播间${record.status == 1 ? '关闭' : '开启'}成功`)


          } else {
            message.error(`直播间${record.status == 1 ? '关闭' : '开启'}失败`)


          }
        })
        .finally((res) => {
          this.searchPurchase()

        });

    },


    handleOk() {

    },
    handleCancel() {
      this.purchaseModal = false
    },

  },
};
</script>
  
<style lang="less" scoped>
@import "./../../assets/less/reset.less";
@import "./../../assets/less/config.less";

.operate {
  padding-right: 5px;
}
</style>